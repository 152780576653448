$block: '.card-form';

#{$block} {
  width: 100%;
  font-weight: 400;

  &__group {
    display: flex;
    margin-bottom: 15px;

    &-mobile {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &__sub-group-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 14px;

    @media (max-width: $sm-width) {
      width: 100%;
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "r1c1 r1c2" "r2c1 .";
    }

    &-mobile {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
    }

    #r1c1 {
      @media (max-width: $sm-width) {
        grid-area: r1c1;
      }
    }

    #r1c2 {
      @media (max-width: $sm-width) {
        grid-area: r1c2;
      }
    }

    #r2c1 {
      @media (max-width: $sm-width) {
        grid-area: r2c1;
        width: calc(200% + 10px);
      }
    }
  }

  &__sub-group {
    display: flex;
    margin-bottom: 15px;
    &-mobile {
      display: flex;
      margin-bottom: 0;
    }
  }

  &__buttons {
    display: flex;

    & .button {
      width: 178px;
      display: flex;
      justify-content: center;

      &:first-child {
        margin-right: 10px;
      }

      &:disabled {
        color: #ffffff;
      }
    }
  }

  &__field {
    margin-right: 12px;



    & .form__label {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $blueDeep;
    }

    &.mobile .form__label {
      font-size: 12px;
      line-height: 14px;
    }

    &--cardNumber {
      & .form__input--withIcon {
        padding: 11px 38px 11px 16px;
      }

      & .form__input-icon {
        left: unset !important;
        right: 16px;
      }
    }

    &--flex {
      flex: 1;
    }

    &--expiration {
      width: 98px;
    }

    &--cvv {
      width: 66px;
    }

    &--postal {
      width: 116px;
      &.mobile {
        width: 96px;
      }
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  &__field--noMargin {
    margin: 0;
  }

  &__spinner {
    min-height: 206px;
  }


}
