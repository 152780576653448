$block: '.Toastify';

#{$block} {
  &__toast {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 54px !important;
    padding: 13px 24px !important;
    border-radius: 3px !important;
    transition: 0.3s background-color;

    &-body {
      font-family: Circular Std;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      word-break: break-word;
      color: white;

      margin-right: 20px !important;
    }

    & div[type] div {
      display: flex;

      & path {
        stroke: white;
      }
    }

    &--success {
      background: $blue !important;

      &:hover {
        background: $blueLight !important;
      }
    }

    &--error {
      background: $red !important;

      &:hover {
        background: #ff6b6b !important;
      }
    }

    &--warning {
      background: $caution !important;
    }
  }

  &__progress-bar {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
  }
}
