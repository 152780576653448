$block: '.documentPreview';

#{$block} {
  &__wrapper {
    width: 100%;
  }

  &__document {
    &-wrapper {
      background: #f1f4f6;
      padding: 60px 0 80px;

      &.mobile {
        padding: 0;
      }
    }

    &-container {
      width: 100%;
      height: 1160px;
      margin-right: 20px;
      position: relative;
      overflow: auto;
      background: #edf2f5;

      &.mobile {
        min-width: initial;
        max-width: initial;
        height: initial;
        margin-right: 0;
      }
    }

    &-inner {
      width: 100%;
      max-width: 970px;
      background: #ffffff;
      box-shadow: 0px 4px 34px rgba(17, 84, 122, 0.16);
      border-radius: 12px;
      overflow: hidden;
      margin: 0 auto;

      &.mobile {
        border-radius: 0;
      }
    }
    &-header {
      background: #edf2f5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 83px;
      padding: 0 40px;

      &.mobile {
        justify-content: flex-end;
        height: initial;
        padding: 5px;
      }

      &-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: $blueDeep;
      }
      &-scale {
        margin-right: auto;
      }
      &-right {
        display: flex;
        align-items: center;
      }
      &-button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 18px;
        height: 42px;
        background: transparent;
        border: 1px solid #5e869d;
        box-sizing: border-box;
        border-radius: 3px;
        color: #5e869d;
        transition: all 0.2s ease;

        &.mobile {
          height: 25px;
        }

        &:first-child {
          margin-right: 12px;
        }

        &:hover {
          color: #596a78;
          transition: all 0.2s ease;
          border-color: #596a78;
        }

        &:disabled {
          cursor: not-allowed;
        }

        &--stroke {
          & svg path {
            stroke: #5e869d;
          }

          &:hover {
            & svg path {
              stroke: #596a78;
            }
          }
        }

        &--fill {
          & svg path {
            fill: #5e869d;
          }

          &:hover {
            & svg path {
              fill: #596a78;
            }
          }
        }

        &-wrapper {
          display: flex;
          margin-right: 25px;
          padding-right: 25px;
          border-right: 1px solid #d2dde3;

          &.mobile {
            margin-right: 0;
            padding-right: 0;
            border-right: none;
          }
        }

        &-icon {
          margin: 0 6px;

          & > div {
            display: flex;
          }
        }
      }
    }
  }

  &__spinner {
    height: 1160px;
  }

  &__activity {
    &-wrapper {
      background: #fff;
      padding: 80px 0 60px;

      &.mobile {
        padding: 60px 8px;
      }
    }
    &-inner {
      width: 100%;
      max-width: 970px;
      background: #ffffff;
      margin: 0 auto;

      &.mobile {
        max-width: 352px;
      }
    }
    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #1b3955;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-table {
      &-wrapper {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border: 1px solid #d7e3e7;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.16);
        border-radius: 3px;
        min-height: 200px;

        &.mobile {
          max-width: 352px;
          margin: auto;
        }
      }
      &-empty {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        &-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: $grayText;
        }
      }
      &-header {
        border-bottom: 1px solid #d7e3e7;
        padding: 20px 60px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: $blueDeep;

        &.mobile {
          padding: 15px 20px;
        }
      }
      &-row {
        align-items: center;
        display: flex;
      }
      &-item {
        padding: 30px 60px;

        &.mobile {
          flex-direction: column;
          padding: 30px 20px;
          align-items: initial;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #d7e3e7;
        }
      }
      &-col {
        &--title {
          &.mobile {
            display: flex;
            margin-bottom: 25px;
          }
        }
        &--icon {
          width: 80px;
          &.mobile {
            width: initial;
            margin: auto 0;
            margin-right: 18px;

            img {
              width: 48px;
            }
          }
        }
        &--date {
          width: 190px;
        }
        &--actions {
          flex: 1;
        }
      }
      &-info {
        &-type {
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.14em;
          text-transform: uppercase;
          color: $grayDark;
          margin-bottom: 5px;

          &.mobile {
            line-height: 12px;
            margin-bottom: 8px;
          }
        }
        &-date {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: $blueDeep;

          &.mobile {
            line-height: 16px;
            margin-bottom: 8px;
          }
        }
        &-time {
          font-size: 16px;
          line-height: 22px;
          color: $grayText;

          &.mobile {
            font-size: 14px;
            line-height: 14px;
          }
        }
      }
      &-actions {
        &-item {
          display: flex;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: 8px;
          }
          &:before {
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: #a5b3bd;
            margin-right: 10px;
          }
        }
        &-label {
          font-size: 16px;
          line-height: 22px;
          color: $blueDeep;

          &.mobile {
            word-break: break-all;
          }
        }
        &-value {
          font-size: 16px;
          line-height: 22px;
          color: $grayText;
        }
      }
    }
  }
}
