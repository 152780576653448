$block: '.integrations';

#{$block} {
  &__wrapper {
    width: 100%;
    max-width: 970px;
    margin-bottom: 45px;
  }
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1b3955;
    margin-bottom: 7px;
  }
  &__subTitle {
    line-height: 22px;
    color: $grayText;
    margin-bottom: 30px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;

    &.mobile {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }
  &__item {
    background: #ffffff;
    border: 1px solid #cee0f0;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(14, 100, 148, 0.09);
    border-radius: 9px;
    margin-bottom: 16px;
    padding: 40px 20px 30px;
    max-width: 224px;
    &:not(:last-child) {
      margin-right: 16px;
    }

    &.mobile {
      max-width: initial;
      &:not(:last-child) {
        margin-right: 0;
      }
    }

    &-button {
      & .button {
        width: 100%;
        justify-content: center;
      }
    }
    &-icon {
      &-wrapper {
        margin-bottom: 20px;
        height: 48px;
        display: flex;
        align-items: center;
      }

      &.mobile {
        margin: 0 auto;
      }
    }
    &-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: $blueDeep;
      margin-bottom: 7px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      &.mobile {
         text-align: center;
      }
    }
    &-desc {
      line-height: 22px;
      color: $grayText;
      margin-bottom: 18px;

      &.mobile {
        text-align: center;
      }
    }
  }
}
