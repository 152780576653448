$block: '.dropDownMenu';

#{$block} {

  &__list {
    position: fixed;
    top: 44px;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100vw;
    background: #ffffff;
    padding: 0 20px;
    color: $caution;

    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__sublist {
    margin: 12px 0 34px;
    padding-left: 60px;
  }

  &__item {
    display: flex;
    margin: 6px 0;
    justify-content: space-between;
    padding-right: 30px;
    font-size: 20px;
    line-height: 22px;

    &.subitem {
      margin: 12px 0;
      justify-content: initial;
    }

    &.active {
      color: $blue;
      svg {
        path {
          stroke: $blue;
        }
      }
    }

    &.logout {
      color: $red;
      path {
        stroke: none;
        fill: $red;
      }
    }

    &-title {
      display: flex;
    }

    &-icon {
      height: max-content;
      margin: auto 0;
      margin-right: 17px;
      svg {
        height: 18px;
        width: 27px;
      }

      &.arrow {
        transform: rotate(0deg);
        transition: all 0.2s ease;

        &.active {
          transition: all 0.2s ease;
          transform: rotate(90deg);
          path {
            stroke: $blue;
          }
        }

        svg {
          height: 15px;
          width: 6px;
          path {
            stroke: $caution;
          }
        }
      }
    }

    &.header__month-wrapper {
      font-size: 14px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 0 30px 17px;
    width: 100%;
    background: #fff;
    border-top: 1px solid $grayLight;
    transition: all 0.2s ease;

    &.open {
      bottom: 182px;
      transition: all 0.2s ease;
    }

    &-sublist {
      position: absolute;
      top: 60px;
      padding-left: 60px;
      background: #fff;
    }

    &-user {
      display: flex;
      font-size: 16px;

      .avatar {
        width: 50px;
        height: 50px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      .avatar-initials {
        width: 50px;
        height: 50px;
      }

      p {
        margin: auto;
        max-width: calc(100vw * 0.37);
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000;
      }
    }

    &-icon {
      &.arrow {
        height: max-content;
        margin: auto 0;
        margin-left: 18px;
        transform: rotate(0deg);
        transition: all 0.2s ease;

        &.active {
          transition: all 0.2s ease;
          transform: rotate(90deg);
        }

        svg {
          height: 15px;
          width: 6px;
          path {
            stroke: $caution;
          }
        }
      }
    }
  }

  &__help {
    margin: auto 0;
    margin-right: 80px;

    svg {
      width: 21px;
      height: 21px;
    }
  }

  &__alert {
    position: relative;
    display: inline-block;
    cursor: help;
    transform: translateY(2px);
    z-index: 5;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}