$block: '.settings-layout';

#{$block} {
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1b3955;
    margin-bottom: 7px;
  }
  &__subTitle {
    line-height: 22px;
    color: $grayText;
    margin-bottom: 40px;
  }
  &__nav {
    margin-bottom: 35px;
  }
}
