$block: '.signers';

#{$block} {
  &__item {
    position: relative;
    display: flex;
    margin-bottom: 15px;

    &.vertical {
      width: 100%;
      flex-direction: column;
    }

    &-role {
      font-size: 16px;
      font-weight: 450;
      display: flex;
      align-items: center;
      word-break: break-all;
      padding-bottom: 8px;
      word-break: keep-all;
    }

    &-inner {
      & .form__field {
        flex: 1;
        margin-bottom: 0;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
      & .form__field .form__input {
        width: 100%;
      }

      &.vertical {
        & .form__field {
          width: 100%;
          margin-right: 0px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }

    &-fields {
      display: flex;
    }

    & input:disabled {
      background: #f5f7f9;
    }

    &-order {
      right: 100%;
      top: 14px;
      position: absolute;
      display: flex;
      align-items: center;
      margin-right: 5px;

      &-icon {
        margin-right: 5px;
        &--dragged {
          & path[stroke] {
            stroke: $blue;
          }

          & path[fill] {
            fill: $blue;
          }
        }

        & div {
          display: flex;
        }
      }

      &-position {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        color: $grayDark;
      }
    }

    &--removable {
      width: 93%;

      &.mobile {
        width: 90%;
      }
    }

    &--me {
      padding-bottom: 20px;
      margin-bottom: 35px;
      border-bottom: 1px solid #d4dbe7;
    }
  }

  &__remove {
    margin: 11px 5px 0 10px;

    &:hover {
      cursor: pointer;

      & svg path {
        stroke: $grayDarker;
      }
    }

    & > div {
      display: flex;
    }
  }
}
