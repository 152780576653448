
$block: '.appsumo';

#{$block} {
  width: 100%;
  max-width: 930px;

  &__container {
    display: flex;
    padding: 90px 0;
    flex-direction: column;
    align-items: center;
    border: 1px solid #cee0f0;
    box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.16);
    border-radius: 3px;
  }

  &__logo {
    position: relative;
    margin-right: 8px;
    &-plus div {
      height: 24px;
    }
  }

  &__logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__header {
    width: 350px;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 21px;
  }

  &__text {
    font-size: 14px;
    width: 470px;
    color: #7D8D98;
    line-height: 22px;
    text-align: center;
    margin-bottom: 22px;
  }
}