
$block: '.appsumo-pay-modal';

#{$block} {

  &__container {
    display: flex;
    justify-content: center;
  }
  
  &__text {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #627790;
  }

  &__title {
   width: 355px;
   margin-bottom: 15px;
  }

  &__description {
   width: 250px;
   margin-bottom: 40px;
  }

  &__bold {
    font-weight: 500;
  }
}