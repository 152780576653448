$block: '.settingsNav';

#{$block} {
  &__list {
    display: flex;
    border-bottom: 1px solid #e4e9f1;
    justify-content: center;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    color: $grayText;
    transition: all 0.2s ease;
    border-bottom: 2px solid transparent;
    margin-bottom: -1px;
    padding-bottom: 20px;
    width: 100%;
    max-width: 150px;
    &.mobile {
      width: max-content;
      padding: 0 10px 10px;
      font-size: 12px;
    }
    &:hover {
      transition: all 0.2s ease;
      color: $blue;
      border-color: $blue;
    }
    &--active {
      color: $blue;
      border-color: $blue;
    }
    &--stroke {
      & svg path {
        stroke: $grayText;
      }
      &:hover {
        & svg path {
          stroke: $blue;
        }
      }
      &#{$block}#{__item--active} {
        & svg path {
          stroke: $blue;
        }
      }
    }
    &--fill {
      & svg path {
        fill: $grayText;
      }
      &:hover {
        & svg path {
          fill: $blue;
        }
      }
      &#{$block}#{__item--active} {
        & svg path {
          fill: $blue;
        }
      }
    }

    &-icon {
      margin-right: 10px;
      & > div {
        display: flex;
      }
    }
  }
}
