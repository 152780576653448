$xxs-width: 424.98px;
$xs-width: 575.98px;
$sm-width: 767.98px;
$md-width: 991.98px;
$lg-width: 1199px;
$xl-width: 1300px;

html {
  height: 100%;
}

body {
  font-family: $font-Circular;
  margin: 0;
  height: 100%;
  color: $blueDeep;
  font-size: 14px;
}

#root {
  height: 100%;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

textarea:focus,
input:focus {
  outline: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  padding: 0;

  &:focus,
  &:active {
    outline: none;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

div:focus {
  outline: none;
}

.ReactModal__Body--open {
  overflow: hidden;
}

* {
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    border-radius: 40px;
  }

  &::-webkit-scrollbar-track {
    background: #edf2f5;
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9bb4c2;
    border-radius: 40px;

    &:hover {
      background: $caution;
    }
  }
}
