$block: '.lifeTimeDeal';

#{$block} {
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: $lg-width) {
    justify-content: normal;
  }

  &__wrapper {
    margin: auto;
    padding: 40px;

    &.mobile {
      padding: 20px;
    }
  }

  &__content {
    width: 100%;
    display: flex;

    &.mobile {
      display: initial;
    }

    @media (max-width: $lg-width) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-left {
      flex: 1;
      margin-right: 80px;

      &.mobile {
        flex: initial;
        margin-right: initial;
        margin-bottom: 50px;
      }

      @media (max-width: $lg-width) {
        margin-right: initial;
      }

      .label {
        width: 590px;
        margin-bottom: 40px;

        &.mobile {
          width: 100%
        }

        &-title {
          font-weight: 900;
          font-size: 38px;
          line-height: 44px;
          margin-bottom: 14px;
          color: #0B2034;

          &.selector-header {
            width: 540px;
          }

          span {
            text-transform: capitalize;
          }
        }

        &-description {
          font-weight: normal;
          font-size: 14px;
          line-height: 27px;
          color: #7d8d98;
        }
      }

      .credentials {
        width: 696px;
        padding: 53px 70px 50px 70px;
        border: 1px solid #CEE0F0;
        border-radius: 3px;
        box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.16);
        box-sizing: border-box;

        &.mobile {
          width: initial;
          padding: initial;
          border: initial;
          border-radius: initial;
          box-shadow: initial;
        }

        @media (max-width: $lg-width) {
          width: 590px;
        }

        &.business {
          padding-top: 20px;
        }

        &__label {
          padding-bottom: 10px;
          margin-bottom: 42px;
          font-size: 18px;
          font-weight: 500;
          line-height: 23px;
          border-bottom: 1px solid #DFE7F0;
        }

        &-personal {
          margin-bottom: 65px;

          &__items {
            display: flex;
            flex-wrap: wrap;
          }

          &-item {
            width: 265px;
            
            &:first-child {
              margin-right: 24px;
            }

            &.mobile {
              width: 100%;

              &:first-child {
                margin-right: initial;
              }
            }
          }
        }
      }
    }

    &-right {
      flex: 1;
      max-width: 440px;

      &.mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: initial;
      }

      &.selector {
        max-width: 725px;
        cursor: pointer;

        &.mobile {
          display: flex;
          max-width: initial;
          justify-content: center;
        }
      }

      .plan-badge {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 50px 40px;
        width: 440px;
        max-width: 440px;
        margin-bottom: 34px;
        border: 1px solid #BAC9DB;
        box-sizing: border-box;
        border-radius: 32px;
        color: #526172;

        &.mobile {
          max-width: initial;
          width: 100%;
        }

        &__wrapper {
          width: 100%;

          &.select-item {
            display: flex;
            justify-content: space-between;

            &.mobile {
              flex-direction: column;
            }
          }
        }

        &.select {
          width: 725px;
          max-width: 725px;
          padding: 20px 30px 30px 30px;
          height: 205px;

          &.mobile {
            max-width: initial;
            width: 100%;
          }
        }

        &.inactive {
          border: 2px solid #BAC9DB;
          height: 205px;

          &.mobile {
            height: 260px;
          }

          &:hover {
            border: 2px solid rgba(#00A3FA, 0.5);
          }
        }

        &.active {
          justify-content: left;
          border: 2px solid #00A3FA;
          background-color: rgba(0, 163, 250, 0.02);
          box-shadow:
          0px 4px 3.5px rgba(#4DA2E2, 0.02),
          0px 8px 7px rgba(#4DA2E2, 0.025),
          0px 16px 14px rgba(#4DA2E2, 0.033),
          0px 32px 28px rgba(#4DA2E2, 0.045);

          &.mobile {
            justify-content: center;
            height: 570px;
          }
        }

        &__best-value {
          display: flex;
          position: absolute;
          font-weight: 500;
          top: -21px;
          left: 30px;
          background-color: $blue;
          color: white;
          width: 277px;
          height: 42px;
          border-radius: 50px;
          text-align: center;
          align-items: center;
          justify-content: center;

          &.mobile {
            width: 200px;
            left: initial;
          }
        }

        &.popular {
          justify-content: left;
          border: 2px solid #00A3FA;

          &.mobile {
            justify-content: center;
          }

          &:hover {
            border: 2px solid rgba(#00A3FA, 0.5);
          }
        }

        &.business {
          justify-content: left;
          border: 2px solid #00A3FA;
          box-shadow:
          0px 17.7209px 14.1767px rgba(77, 162, 226, 0.0328297),
          0px 8.6871px 6.94968px rgba(77, 162, 226, 0.0258431),
          0px 3.43489px 2.74791px rgba(77, 162, 226, 0.0195849);
        }

        &__suffix {
          position: absolute;
          top: -18px;
          width: 158px;
          padding: 7px;
          background: #00A3FA;
          color: white;
          border-radius: 50px;
          text-align: center;
        }

        &__label {
          text-align: left;
          margin-bottom: 27px;

          &.mobile {
            padding-bottom: 15px;
            border-bottom: 1px solid #CEE0F0;
          }
        }

        &__tier-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &__name {
          font-size: 36px;
          line-height: 60px;
          text-transform: capitalize;

          &.mobile {
            font-size: 24px;
          }
        }

        &__price-wrapper {
          display: flex;
          flex-direction: row;
          align-items: end;
          justify-content: space-between;
          padding-bottom: 20px;
          border-bottom: 1px solid #DFE7F0;

          @media (max-width: $sm-width) {
            flex-direction: column;
            align-items: baseline;
          }
        }

        &__price {
          font-size: 58px;
          line-height: 60px;
          font-weight: 500;
          color: #00A3FA;

          span {
            font-size: 18px;
            line-height: 22px;
            font-weight: normal;
            color: #526172;
          }

          &-suffix {
            font-size: 22px;
            line-height: 28px;
          }

          &__tier-billet {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #00A3FA;;
            background-color: rgba(#00A3FA, 0.05);
            width: 80px;
            height: 40px;
            border-radius: 46px;
            font-size: 16px;
            font-weight: 500;

            @media (max-width: $sm-width) {
              width: 100%
            }

            &.mobile {
              width: initial;
              margin-right: 5px;
              background-color: transparent;
              font-size: 14px;
            }
          }
        }

        &__included-label {
          font-size: 18px;
          line-height: 20px;
          font-weight: 500;
          margin-bottom: 20px;
        }

        &__options {

          &-item {
            margin-bottom: 20px;
            font-size: 18px;
            display: flex;
            justify-content: space-between;

            &.mobile {
              font-size: 14px;
              margin-bottom: 10px;

              span {
                font-size: 14px;
                margin-left: 10px;
              }
            }

            .option_check {
              display: inline-block;
              text-align: center;
              align-content: center;
              width: 20px;
              height: 20px;
              min-width: 20px;
              min-height: 20px;
              border-radius: 100%;
              border: 1px solid #00A3FA;

              path {
                stroke: #00A3FA;
              }

              &.business {
                background: #00A3FA;
                color: white;
                

                path {
                  stroke: white; 
                }
              }
            }

            .option_value.business {
              color: #00A3FA;
            }

            span {
              font-size: 16px;
              margin-left: 23px;
            }
          }
        }
      }

      .eco-badge {
        display: flex;
        justify-content: center;
        width: 440px;
        height: 115px;
        background: #F2FAF8;
        border-radius: 8px;
        margin-bottom: 35px;

        &.mobile {
          width: 280px;
        }

        &__content {
          margin: auto;
          display: flex;
        }

        &__math {
          font-size: 18px;
          font-weight: bold;
          line-height: 20px;
          color: #1B3955;
          margin-bottom: 7px;

          &.mobile {
            font-size: 15px;
          }
        }

        &__promise {
          font-size: 16px;
          line-height: 20px;
          color: #919FAF;

          &.mobile {
            font-size: 13px;
          }
        }
      }

      .lifeTimeDeal__slider {
        width: 440px;

        &.mobile {
          width: 280px;

          .slider__item {
            width: 280px;
          }
        }

        .slick-dots {
          bottom: 0;
        }

        .slider__item {
          padding: 0;
          border: none;
          box-shadow: none;
        }

        .slider .slick-dots li button:before {
          color: #DFE7F0;
          opacity: 1;
        }

        .slider .slick-dots li.slick-active button:before {
          color: #00A3FA;
        }
      }
    }
  }

  &__footer {
    width: 277px;

    .lifeTimeDeal__button {
      width: 100%;
      min-height: 40px;
      height: 40px;
      margin-bottom: 10px;

      &.mobile {
        width: 100%;
      }

      p {
        margin: auto;
      }
    }

    &-policy {
      width: 312px;
      font-size: 14px;
      line-height: 18px;
      color: #BAC9DB;

      &.mobile {
        width: 100%;
      }
    }

    &-link {
      span {
        text-decoration: underline;
        color: #8796A8;
      }
    }
  }

  &__divisor {
    margin-bottom: 50px;
    height: 1px;
    box-sizing: border-box;
    border-bottom: 1px solid #DFE7F0;
  }

  &__or-divisor {
    margin: 15px 0;
  }

  &__fields {
    &-wrapper {
      margin-bottom: 20px;
    }

    &-tier-wrapper {
      margin: 40px 40px 0px;

      &.mobile {
        margin: initial;
        margin-top: 40px;
      }
    }
  }

  &__common {
    &-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      background-color: #F3FBFF;

      &__content {
        display: flex;
        min-width: 310px;
        width: 528px;
        margin: auto;
        margin-top: 60px;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      grid-row-gap: 10px;
      padding: 40px;
      align-items: center;
      background-color: white;
      border: 1px solid #CEE0F0;
      border-radius: 4px;
      box-shadow: 0px 2px 6px rgba(#0E6494, 0.09);

      &.mobile {
        padding: 20px;
        border: initial;
        border-radius: initial;
        box-shadow: initial;
      }

      &__title {
        font-size: 23px;
        font-weight: 500;
        line-height: 26px;
        color: $blueDeep;
      }

      &__subtitle {
        line-height: 22px;
        color: #7D8D98;
      }
    }

    &-button {
      &__checkout {
        &-wrapper {
          border-top: 1px solid #DFE7F0;
          padding-top: 20px;
        }

        &-container {
          width: 100%;
          margin-top: 20px;
        }

        &-policy {
          display: flex;
          width: 100%;
          justify-content: center;
          text-align: center;
        }
      }

      &__go-to-checkout {
        width: 100%;
        
        &.mobile {
          margin-bottom: 10px;
        }
      }
    }

    &-tierInfo {
      &__container {
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;

        &.mobile {
          display: flex;
          flex-direction: column;
        }
      }

      &__currentTier {
        &-wrapper {
          margin-bottom: initial;
          border-radius: 6px 0px 0px 0px;
          border: 1px solid #D7E3E7;

          &.mobile {
            border-radius: 6px 6px 0px 0px;
          }
        }
      }

      &__newTier {
        &-wrapper {
          margin-bottom: initial;
          border-radius: 0px 6px 0px 0px;
          border: 1px solid #D7E3E7;

          &.mobile {
            border-radius: 0px;
          }
        }

        &-billet {
          color: #20B853;
          background-color: rgba(#20B853, 0.1);
        }
      }

      &__total {
        &.left {
          width: 100%;
          background-color: #F5FAFC;
          border: 1px solid #D7E3E7;
          border-radius: 0px 0px 0px 6px;

          &-content {
            display: flex;
            justify-content: space-between;
            padding: 15px 31px;
          }
        }

        &.right {
          width: 100%;
          background-color: #F5FAFC;
          border: 1px solid #D7E3E7;
          border-radius: 0px 0px 6px 0px;

          &.mobile {
            border-radius: 0px 0px 6px 6px;
          }

          &-content {
            display: flex;
            justify-content: space-between;
            padding: 15px 31px;
          }
        }
      }
    }
  }

  &__primeclub-badge {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 35px;

      & a {
        color: $blue;
      }

      & span {
        font-size: 16px;
        line-height: 27px;
        color: #0B2034;
      }
    }

    &-icon {
      margin-right: 10px;
    }
  }
}