$block: '.termsModal';

#{$block} {
  &-responsive {
    @media (max-width: $sm-width) {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
    }

    .confirmModal {
      @media (max-width: $sm-width) {
        margin: auto 0;
      }
    }
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 10px;
  }

  &__subtitle {
    margin-bottom: 35px;
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    word-break: break-word;
    font-size: 16px;
    line-height: 27px;
    color: $caution;
  }

  &__buttons-block {
    display: flex;
    justify-content: flex-start;
    margin-left: 15px;
  }

  &__button-cancel {
    color: $caution;
    cursor: pointer;
  }

  &__description {
    padding-bottom: 28px;
    border-bottom: 1px solid #d7e3e7;
  }

  &--bold {
    font-weight: bold;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 421px;
  }

  &__label {
    font-weight: bold;
    color: $blueDeep;
    margin-bottom: 14px;
  }

  &__recipientsWrapper {
    margin-bottom: 34px;
  }

  &__copy {
    display: flex;
    position: relative;
    margin-bottom: 32px;
    padding: 0;
    cursor: pointer;
    z-index: 1;

    &:hover {
      #{$block}#{__copyInner} {
        background: #e2f5ff;

        & #{$block}#{__copyText} {
          color: $blueDark;
        }

        & #{$block}#{__copyIcon} {
          & svg path {
            stroke: $blueDark;
          }
        }
      }
    }

    & .form__input {
      width: 100%;
      z-index: -1;
      padding-right: 115px;
      text-overflow: ellipsis;
    }
  }

  &__copyInner {
    position: absolute;
    display: flex;
    align-items: center;
    right: 12px;
    padding: 7px 18px;
    align-self: center;
    background: #ebf8ff;
    border-radius: 30px;
  }

  &__copyIcon {
    margin-right: 5px;

    & div {
      display: flex;

      & svg path {
        stroke: $blue;
      }
    }
  }

  &__copyText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: $blue;
  }
}
