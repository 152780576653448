$block: '.react-toggle';

#{$block} {
  &--checked {
    #{$block}#{-thumb} {
      left: 32px !important;
    }
  }

  &-track {
    height: 30px !important;
    width: 60px !important;

    background-color: $blue !important;
  }

  &-thumb {
    width: 26.5px !important;
    height: 26.5px !important;
    top: 1.77px !important;
    background: #ffffff !important;
    border: 0.5px solid rgba(228, 233, 241, 0.5) !important;
    box-sizing: border-box !important;
    box-shadow: 2px 1px 3px rgba(0, 112, 171, 0.2) !important;
  }
}
