$block: '.teammates';

#{$block} {
  &__label {
    margin-bottom: 10px;
  }

  &__list {
    &-item {
      &:not(:last-child) {
        padding-bottom: 10px;
        border-bottom: 1px dashed #d7e3e7;
        margin-bottom: 30px;
      }

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }

  &__credentials {
    margin-bottom: 20px;

    &-wrapper {
      padding-right: 35px;

      &.mobile {
        width: auto;
      }
    }
    display: flex;

    &.mobile {
      display: block;
    }
  }

  &__add {
    &-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      background: #ffffff;
      height: 42px;
      border: 1px dashed #d7e3e7;
      box-sizing: border-box;
      border-radius: 3px;
      color: $grayDark;

      &:hover {
        cursor: pointer;
        border-color: $grayDark;
        color: $grayDark;

        & svg path {
          stroke: $grayDarker;
        }
      }
    }

    &-icon {
      margin-left: 12px;

      & > div {
        display: flex;
      }
    }
  }

  &__email {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 25px;
    }

    &-inner {
      display: flex;
      flex-direction: row;
    }

    &-error {
      position: absolute;

      .form__error {
        margin-top: 3px;
      }
    }

    & .form__input,
    .form__error-wrapper {
      width: 100%;
    }
  }

  &__name {
    &-error {
      position: absolute;

      .form__error {
        margin-top: 3px;
      }
    }

    &-wrapper {
      position: relative;
      margin-right: 12.5px;
      width: 100%;
      flex: 3;

      &.mobile {
        margin-bottom: 25px;
      }
    }
  }

  &__role-wrapper {
    flex: 1;
  }

  &__remove {
    margin: auto;
    margin-left: 15px;

    &:hover {
      cursor: pointer;

      & svg path {
        stroke: $grayDarker;
      }
    }

    & > div {
      display: flex;
    }
  }
}
