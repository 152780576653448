$block: '.changePermissionsModal';

#{$block} {
  &__wrapper {
    padding: 10px;

    form {
      max-height: 380px;
      overflow-y: auto;
    }
  }
  &__header {
    margin-bottom: 18px;
    text-align: left;
  }

  &__actions {
    display: flex;
    margin-top: 20px;
  }

  &__cancel {
    color: #7d8d98;
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
  }

  &__info {
    text-align: center;
    padding: 30px 10px;
  }

  &__list {
    &--item {
      display: flex;
      padding: 12px 0 12px 22px;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #cee0f0;
      &-innerWrapper {
        display: flex;
        align-items: center;

        .uiCheckbox {
          margin-right: 8px;
        }
      }

      &:first-child {
        border-top: 1px solid #cee0f0;
      }
    }
  }

  &__member {
    &-name {
      max-width: 220px;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
  }
}
