$block: '.empty-table';

#{$block} {
  width: 100%;
  height: 358px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cee0f0;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.16);
  border-radius: 3px;
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    width: 80px;
    height: 80px;
    border-radius: 25px;
    background: #f3f7f9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    & svg {
      width: 40px;
      height: 50px;
    }

    &--team {
      background: #edf9ff;

      & svg {
        width: 50px;
        height: 50px;
      }
    }

    &--document {
      & path {
        fill: #c1d1e3;
      }
    }
  }

  &__header {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #1b3955;
    margin-bottom: 8px;
  }

  &__api-description {
    width: 400px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $grayText;
    margin-bottom: 21px;
  }
  & .button {
    width: 196px;
    display: flex;
    justify-content: center;
  }
}
