$block: '.company';

#{$block} {
  &__uploader {
    margin-bottom: 15px;

    &-description {
      margin-bottom: 24px;
    }

    &_uploaded {
      &-container {
        border: 1px dashed #E1E1E1;
        box-sizing: border-box;
        border-radius: 3px;
        width: 100%;
        padding: 2em 25%;
      }

      &-icon {
        background: #EDF9FF;
        border-radius: 15.12px;
        width: 24px;
        height: 24px;
        padding: 12px;
        margin: 0 auto .5em;
      }

      &-text {
        margin: 0 auto 0.5em;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
      }

      &-links {
        margin: 0 auto;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-link, &-link:visited {
          color: #00A3FA;
          text-decoration: underline;
        }
      }

      &-modal {
        padding: 20px !important;
        display: block !important;
        max-height: 100vh;

        &-body {
          padding: 0;
          display: block;
          width: 100%;
          max-width: 600px;
          -webkit-text-size-adjust: none;

          &-logo-a {
            height: 50px;
            margin: 0 auto 26px;
            width: 280px;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
          }

          &-container {
            border: 1px solid #CEE0F0;
            box-shadow: 0 2px 6px rgba(14, 100, 148, 0.09);
            border-radius: 9px;
          }

          &-avatar {
            width: 78px;
            height: 78px;
            border-radius: 39px;

            margin: 0 auto 1em;
            display: block;
          }

          &-initials {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 78.38px;
            width: 78.38px;
            border-radius: 50%;
            box-shadow: 0 4.75px 14.25px rgba(5, 37, 94, 0.17);
            margin: auto auto 8px;
            background-color: #00A3FA;
            font-size: 36px;
            text-align: center;
            vertical-align: middle;
            color: white;
          }

          &-title-container {
            padding: 41px 60px 0;
          }

          &-document-title {
            text-align: left;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 25px;
            color: rgb(45, 60, 74);
            margin-top: 35px;
          }

          &-document-message {
            &-header {
              color: rgb(125, 141, 152);
              text-align: left;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
              margin-top: 20px;
              margin-bottom: 18px;
            }

            &-text {
              color: rgb(125, 141, 152);
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              white-space: pre-line;
              word-break: break-word;
            }
          }

          &-view-document-link {
            display: block;
            margin: 24px 0 36px;
            text-align: left;
            color: #00A3FA;
          }

          &-warning {
            &-container {
              margin: 40px auto 40px;
              padding: 14px 27px 22px;
              display: flex;
              background-color: rgb(255, 251, 243);
              border-radius: 6px;
              flex-direction: row;
            }

            &-icon {
              margin: 6px 7px 0 0;
              width: 16.34px;
              height: 14px;
              background-image: url("https://signaturely-assets.s3-us-west-2.amazonaws.com/icons/warning3x.png");
              background-size: contain;
              display: block;
            }

            &-text {
              flex: 1 1;

              color: rgb(231, 155, 42);
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }
          }

          &-header {
            font-size: 19px;
            text-align: center;
            margin-bottom: 0;
            font-weight: bold;
          }

          &-button-container {
            margin-top: 26px;
            border-bottom: 1px solid rgb(206, 224, 240);
            padding-bottom: 41px;
          }

          &-view-button {
            display: block;
            text-decoration: none;
            width: 185px;
            background: rgb(0, 163, 250);
            border-radius: 110px;
            padding: 12px 42px;
            margin: 0 auto;
            box-sizing: border-box;
            min-width: 182px;
            text-align: center;
            color: #ffffff;
            font-size: 14px;
          }

          &-powered-container {
            background:#F0F6FA;
            border-radius: 0 0 9px 9px;
            padding: 20px 60px;
            text-align: center;
          }

          &-powered-span {
            color: #7D8D98;
            font-weight: 400;
            font-size: 14px;
            font-family: arial;
            line-height: 22px;
          }
        }
      }
    }
  }

  &__redirection {
    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-billet {
      margin-left: 6px;
    }

    &--disabled {
      color: #a5b3bd;
    }

    &-enable .uiCheckbox__label {
      color: #a5b3bd;
    }

    &-subtitle {
      margin-bottom: 5px;
    }
  }

  &__header {
    &-billet {
      margin-bottom: 0px;
      margin-right: 8px;
    }
  }

  &__billet {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #fead11;
    box-shadow: 0px 2px 4px rgba(67, 34, 4, 0.13);
    border-radius: 2px;
    padding: 3px 12px;
    cursor: pointer;

    &-icon {
      margin-right: 5px;
      & svg path {
        stroke: white;
      }
    }

    &-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #ffffff;
    }

    &-container {
      min-height: 28px;
      display: flex;
      align-items: baseline;
    }
  }

  &__branding {
    position: relative;

    &--blur {
      &::after {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        z-index: 1;
        background: rgba(255, 255, 255, 0.78);
      }
    }
  }

  &__upgrade {
    top: 39px;
    width: 100%;
    box-shadow: 0px 5px 16px rgba(53, 135, 182, 0.34);
    border-radius: 4px;
    height: 212px;
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    padding: 44px 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    &-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: $caution;
      margin-bottom: 19px;
    }

    &-title {
      font-weight: bold;
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: rgb(6, 7, 7);
      margin-bottom: 5px;
    }

    &-wrapper {
      position: relative;
      margin-bottom: 25px;
    }
  }

  &__title {
    margin-right: 12px;

    &-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__checkbox-group {
    display: flex;
  }
}
