$block: '.main-layout';

#{$block} {
  &--relative {
    position: relative;
  }
  &__content {
    display: flex;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding-top: 50px;
    min-height: calc(100vh - 165px);
    @media (max-width: $lg-width) {
      max-width: calc(100vw - 40px);
    }
    &.mobile {
      padding-top: 20px;
    }
  }
  &__sidebar {
    width: 100%;
    max-width: 158px;
    margin-right: 45px;
  }
  &__container {
    width: 100%;
  }
  &__withOutSidebar {
    width: 100%;
    min-height: calc(100vh - 115px);
    display: flex;
  }

  &__header {
    padding: 20px !important;
  }
}
