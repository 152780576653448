$block: '.pastDue';

#{$block} {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px;
  background: $grayLight;
  border-radius: 7px;
  color: $grayDarker;

  &__message {
    max-width: 230px;
    margin: auto 0;
  }

  &__button {
    margin: auto 0;
  }
}