$block: '.radio-button';

#{$block} {
  width: 12px;
  height: 12px;
  background: #eeeeee;
  border: 1px solid $gray;
  box-sizing: border-box;
  border-radius: 100%;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  &__wrapper {
    display: flex;
    align-items: baseline;

    &:hover {
      cursor: pointer;
      transition: all 0.2s ease;

      #{$block} {
        border-color: $blue;
      }

      #{$block}#{__label} {
        color: $blue;
        cursor: pointer;
      }
    }

    &--disabledChecked {
      &:hover {
        cursor: not-allowed;
  
        #{$block} {
          border-color: $grayDark;
        }
  
        #{$block}#{__label} {
          color: $grayDark;
          cursor: not-allowed;
        }
      }
    }
  }


  &__label {
    color: $blueDeep;
    line-height: 21px;
  }

  &--checked {
    &:after {
      content: '';
      display: flex;
      width: 8px;
      height: 8px;
      background-color: $blue;
      border-radius: 100%;
      margin: 1px;
    }
  }

  &--unchecked {
    &:after {
      content: '';
      display: flex;
      width: 8px;
      height: 8px;
      margin: 1px;
    }
  }

  &--disabledChecked {
    &:after {
      background: $grayDark;
    }
  }
}
