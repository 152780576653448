$block: '.trialSuccessModal';

#{$block} {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 450px;
    min-height: 270px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    padding: 50px 20px;
    text-align: center;
    align-items: center;
  }

  &__header {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: $blueDeep;
    margin-bottom: 40px;
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    color: $grayText;
    margin-bottom: 15px;

    b {
      color: $blueDeep;
    }
  }

  &__button {
    &--download {
      width: 170px;
      margin: 20px 0px;
    }

    &--cancel {
      cursor: pointer;
      color: #b5bdc6;
    }
  }
}