$block: '.confirmCodeModal';

#{$block} {
  width: 620px;

  @media (max-width: $sm-width) {
    width: 320px;
    padding: 20px 16px;
  }

  &__wrapper {
    padding: 38px 24px;
  }
  
  &__title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
  }

  &__field {
    margin: 24px 0px;
    display: flex;
    justify-content: space-between;

    button {
      margin-left: 24px;
    }
  }

  &__submit {
    display: flex;
    justify-content: center;

    button {
      display: flex;
      justify-content: center;
      width: 100px;
      font-size: 16px;
      margin-left: 10px;

      @media (max-width: $sm-width) {
        width: 100%;
      }
    }
  }
}