$block: '.requisiteItem';

#{$block} {

  &__text {
    overflow: hidden;

    &-container {
      padding:0 35px;
      overflow: hidden;
    }
  }
}
