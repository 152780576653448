$block: '.documents';

#{$block} {
  &__copy {
    &-title {
      font-size: 16px;
      line-height: 20px;
      color: #1b3955;
    }
  }

  &__spinner {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__wrapper {
    width: 100%;
    max-width: 970px;
    margin-bottom: 45px;
  }

  &__titleGroup {
    display: flex;
    justify-content: space-between;
  }

  &__empty-table {
    margin-top: 40px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    margin-bottom: 32px;

    &--template {
      max-width: 100%;
    }

    &.trash {
      max-width: inherit;
    }

    & button {
      width: 196px;
      display: flex;
      justify-content: center;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1b3955;
    margin-bottom: 7px;

    &.trash {
      color: $red;
    }
  }

  &__subTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $grayText;

    &--template {
      max-width: 450px;
    }
  }

  &__hr {
    border: 1px solid #e4e9f1;
    margin-bottom: 26px;
  }

  &__apiStatus {
    border-radius: 50px;
    padding: 8px 14px;
  }

  &__documentStatus {
    display: flex;
    justify-content: center;
    border-radius: 50px;
    padding: 8px 14px;
    text-transform: uppercase;
    margin-left: 15px;
    height: min-content;
    align-self: center;

    &.mobile {
      margin-left: 0;
    }

    &-text {
      max-height: 8px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.05em;
      word-break: keep-all;

      &::before {
        content: '\A';
        display: inline-block;
        width: 5px;
        height: 5px;
        visibility: visible;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    &--draft {
      background-color: $grayBlueLight;

      #{$block}#{__documentStatus-text} {
        color: $grayBlue;

        &::before {
          background-color: $grayBlue;
        }
      }
    }

    &--api {
      background-color: $grayBlueLight;

      #{$block}#{__documentStatus-text} {
        color: $blue;

        &::before {
          content: none;
        }
      }
    }

    &--awaiting {
      background-color: $orangeLight;

      #{$block}#{__documentStatus-text} {
        color: $orange;

        &::before {
          background-color: $orange;
        }
      }
    }

    &--awaiting_you {
      background-color: $orangeLight;

      #{$block}#{__documentStatus-text} {
        color: $orange;

        &::before {
          background-color: $orange;
        }
      }
    }

    &--awaiting_others {
      background-color: $orangeLight;

      #{$block}#{__documentStatus-text} {
        color: $orange;

        &::before {
          background-color: $orange;
        }
      }
    }

    &--signed {
      background-color: #D8F1FF;

      #{$block}#{__documentStatus-text} {
        color: #00A3FA;

        &::before {
          background-color: #00A3FA;
        }
      }
    }

    &--completed {
      background-color: $greenLight;

      #{$block}#{__documentStatus-text} {
        color: $green;

        &::before {
          background-color: $green;
        }
      }
    }

    &--preparing {
      background-color: $grayLighter;

      #{$block}#{__documentStatus-text} {
        color: $gray;

        &::before {
          background-color: $gray;
        }
      }
    }

    &--disabled {
      background-color: $redLight;

      #{$block}#{__documentStatus-text} {
        color: $gray;

        &::before {
          background-color: $gray;
        }
      }
    }

    &--deleted {
      background-color: $redLighter;

      #{$block}#{__documentStatus-text} {
        color: $red;

        &::before {
          background-color: $red;
        }
      }
    }

    &--declined {
      background-color: transparent;
      border: 1px solid $red;

      #{$block}#{__documentStatus-text} {
        color: $red;

        &::before {
          display: none;
        }
      }
    }

    &--expired {
      background-color: transparent;
      border: 1px solid $orange;

      #{$block}#{__documentStatus-text} {
        color: $orange;

        &::before {
          display: none;
        }
      }
    }
  }

  &__templateOptionsWrapper {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    width: 100%;

    &-optionButton {
      background-color: rgba($color: #000000, $alpha: 0);
      color: #000;
      border: 0px solid #000;
      outline: none;
    }
  }

  &__optionsDropdown {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 22px;
    position: relative;
  }

  &__optionsDropdownTrigger {
    display: flex;
    align-items: center;
    background-color: inherit;
    padding: 0;

    &-text {
      color: $blueDeep;
      margin-right: 6px;
      font-weight: 400;

      &--open {
        color: $blue;
        font-weight: 500;
      }

      &.mobile {
        font-size: 20px;
      }
    }

    &-arrow {
      transition: all 0.2s ease;
      transform: rotate(0deg);

      & div {
        display: flex;
      }

      &--open {
        transform: rotate(-180deg);

        & svg path {
          stroke: $blue;
        }
      }
    }
  }

  &__dropdownList {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -35px;
    top: 30px;
    min-width: 210px;
    background: #ffffff;
    box-shadow: 0px 5px 26px rgba(53, 135, 182, 0.24);
    border-radius: 4px;
    padding: 10px 0px;
    z-index: 999;

    &.signers {
      max-height: 400px;
      overflow-y: scroll;
    }

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      right: 30px;
      display: inline;
      border: 10px solid white;
      border-top-width: 0;
      border-right-color: transparent;
      border-left-color: transparent;
      filter: drop-shadow(0 -10px 7px rgba(53, 135, 182, 0.24));
    }

    &--top {
      top: unset;
      bottom: 150%;

      &::before {
        top: auto;
        bottom: -10px;
        border-top-width: 10px;
        border-bottom-width: 0;
        filter: drop-shadow(0 10px 8px rgba(53, 135, 182, 0.24));
      }
    }
  }

  &__dropdownOptionWrapper {
    padding: 0px 22px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-evenly;

    &:hover {
      background-color: #e8f7ff;
    }

    &:not(:last-child) {
      & #{$block}#{__dropdownOption} {
        border-bottom: 1px solid #e3edf5;
      }
    }
  }

  &__dropdownOption {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 9px 0px;
    font-weight: normal;

    &:not(:last-child) {
      border-bottom: 1px solid #e3edf5;
    }

    &-icon {
      margin-right: 14px;

      & div {
        display: flex;

        & svg path {
          stroke: rgb(0, 163, 250);
        }
      }

      &.merged {
        margin-right: 32px;
      }

      &.separated {
        margin-right: 2px;
      }
    }

    &--red {
      color: $red;

      &-icon {
        & div {
          & svg path {
            stroke: none;
            fill: $red;
          }
        }
      }

      &.red {
        color: $red !important;
        svg {
          path {
            stroke: none;
            fill: red;
          }
        }
      }
    }

    &--decline {
      color: $red;

      &-icon {
        & div {  
          & svg path {
            stroke: $red;
          }
        }
      }
    }
  }

  &__dropdownOptionSigners {
    display: flex;
    flex: 1;
    padding: 9px 9px;
    font-weight: normal;
    align-items: flex-start;
    word-break: normal;

    &--name {
      margin-right: 10px;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--bold {
      font-weight: 700;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3edf5;
    }

    &-icon {
      margin-left: 14px;

      & div {
        display: flex;

        & svg {
          overflow: visible;
        }
        & svg path {
          stroke: rgb(0, 163, 250);
        }
      }
    }

    &-declinedIcon {
      margin-left: 14px;

      & div {
        display: flex;

        & svg {
          overflow: visible;
        }
        & svg path {
          stroke: $red;
        }
      }
    }
  }

  &__deleteModal{
    &--cancel{
      color: #7D8D98;
      cursor: pointer;
    }
  }

  &__deleteWrapper {
    max-width: 410px;
  }

  &__deleteHeader {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
  }

  &__deleteTitle {
    font-size: 16px;
    line-height: 20px;
    color: #1b3955;
    margin-bottom: 2px;
  }

  &__shareModal {
    max-width: 550px;
    width: 100%;
  }

  &__reminderModal {
    max-width: 445px;
    width: 100%;
  }

  &__revertModal {
    max-width: 445px;
    width: 100%;

    &.mobile {
      max-width: initial;
    }
  }

  &__create-btn {
    margin-bottom: 25px;
  }

  &__list {
    &-item {
      outline: none;

      &.combine {
        background-color: $blueLighter;
      }
    }

    &-container {
      display: inline;
    }
  }
}
