$block: '.sign-up-second-step';

#{$block} {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 171px;

  @media (max-width: $xl-width) {
    padding: 0 24px 24px 24px;
  }

  &__title {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: #091d30;
    max-width: 941px;
    text-align: center;
    margin-top: 30px;

    @media (max-width: $xl-width) {
      font-size: 20px;
      line-height: 28px;

      & br {
        display: none;
      }
    }

    @media (max-width: $xs-width) {
      margin-top: 71px;
    }
  }

  &__title-underline {
    position: relative;
    z-index: 2;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background: #00a3fa;
      opacity: 0.4;
      z-index: -1;
      left: 0;
      bottom: 5px;
    }
  }

  &__info-wrapper {
    display: grid;
    grid-template-columns: 1fr 460px 1fr;
    width: 1264px;
    background: #f6fcff;
    border: 1px solid #e4e7eb;
    border-radius: 24px;
    margin-top: 30px;

    @media (max-width: $xl-width) {
      display: flex;
      flex-direction: column;
      width: auto;
      margin-top: 20px;
      border: none;
      background: none;
      max-width: 470px;
    }

    @media (max-width: $sm-width) {
      display: flex;
      flex-direction: column;
      width: auto;
      margin-top: 20px;
      border: none;
      background: none;
      max-width: 345px;
    }
  }

  &__plan-section {
    border-radius: 24px 0 0 24px;
    padding: 30px;

    @media (max-width: $xl-width) {
      border-radius: 24px;
      background: #f6fcff;
      border: 1px solid #e4e7eb;
      margin-top: 15px;
    }

    @media (max-width: $xs-width) {
      padding: 20px 23px;
    }
  }

  &__trial-section {
    @media (max-width: $xs-width) {
      padding: 20px 10px;
    }
  }

  &__section-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #091d30;
    margin-bottom: 8px;

    @media (max-width: $xl-width) {
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      margin-bottom: 15px;
    }
  }

  &__section-title--m30 {
    margin-bottom: 30px;
  }

  &__section-wrapper {
    display: flex;
    margin-bottom: 24px;

    &--main-info {
      display: flex;
      flex-direction: row;

      > :last-child {
        margin-left: 5px;
      }
    }

    @media (max-width: $xl-width) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__section {
    &__subtitle {
      font-style: normal;
      font-size: 14px;
      line-height: 21px;
      color: #1B3955;
    }

    &__discount {
      color: $blue;
      margin-left: 2px;

      @media (max-width: $xl-width) {
        margin-left: 0px;
      }
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #091d30;
    margin-top: 17px;
  }

  &__check-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background: #00a2fa;
    border-radius: 50px;
    margin-right: 12px;

    &--next {
      background: #ebf7fe;
    }
  }

  &__billing-info {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.02), 0px 5px 22px rgba(1, 151, 178, 0.06);
    border-radius: 24px;
    padding: 20px;
    outline-width: 1px;
    outline-color: #e4e7eb;
    outline-style: solid;

    @media (max-width: $xl-width) {
      order: -1;
      outline: none;
      border: 1px solid #e4e7eb;
    }
  }

  &__step-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 59px;

    &:last-child {
      margin-bottom: 0;
    }

    &--active {
      margin-top: 30px;
      margin-bottom: 48px;
    }
  }

  &__step-icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: #ebf7fe;
    border-radius: 50%;
    margin-right: 20px;
    flex-shrink: 0;
    z-index: 2;
    line-height: 0;

    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 80px;
      border: 1px dashed #00a3fa;
      z-index: -1;
      top: calc(50% + 30px);
      left: 50%;
      transform: translateX(-50%);
    }

    &--lastStep::after {
      display: none;
    }

    &--active {
      background: #00a3fa;

      &::after {
        border: 1px solid #00a3fa;
      }
    }
  }

  &__step-text-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__step-title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #091d30;
    margin-bottom: 6px;
    text-align: left;
    text-transform: uppercase;

    &--first {
      color: $blue;
    }
  }

  &__step-description {
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    color: #5a7386;
    text-align: left;
  }

  &__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
  }

  &__tabs-wrapper {
    margin-top: 40px;

    @media (max-width: $xs-width) {
      position: absolute;
      margin-top: 25px;
    }
  }

  &__tab-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 185px;
    height: 100%;
  }

  &__tab-text-wrapper {
    display: flex;
    align-items: center;
  }

  &__tab-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-left: 11px;
    color: #556170;
    user-select: none;

    &--active {
      color: #00a3fa;
    }

    &--next {
      color: #a5b3bd;
    }
  }

  &__tab-number {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    user-select: none;

    color: #a5b3bd;

    &--active {
      color: #ffffff;
    }
  }

  &__tab-underline {
    width: 100%;
    height: 2px;
    margin-top: 4px;
    background: #e4e7eb;

    &--active {
      background: #00a3fa;
    }
  }

  &__buttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0px 20px 0px;

    &.openDropDown {
      margin-top: 23px;
    }
  }

  &__button {
    padding: 16px 71px;
    font-size: 18px;
    line-height: 27px;
    white-space: nowrap;
    width: 100%;

    @media (max-width: $xl-width) {
      padding: 16px 60.5px;
    }
  }

  &__field {
    & .form__label {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 6px;
      white-space: nowrap;
      color: #2d3c4a;

      @media (max-width: $xs-width) {
        font-size: 13px;
        line-height: 20px;
      }
    }

    & .card-form__field {
      @media (max-width: $xs-width) {
        margin-right: 10px;
      }
    }
  }

  &__coupon {
    &.wrapper{
      padding: 0px 16px 16px;

      @media (max-width: $xl-width) {
        padding: 0px 10px 16px 10px;
        min-width: 240px;
      }
    }

    &.input{
      border-radius: 3px;
      background-color: #ffffff
    }

    &.button{
      min-height: 24px;
      line-height: 2px;
      top: 13px;
    }
  }

  &__couponDropDown {
    &__wrapper {
      width: 100%;
      position: relative;
    }

    &__select {
      background-color: #f6fcff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #d7e3e7;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 0 10px 0 17px;
      height: 42px;

      &:hover {
        cursor: pointer;

        #{$block}#{__couponDropDown__select-arrow} {
          & svg path {
            stroke: $blueDark;
          }
        }
      }

      &--open {
        border-color: #E1EBEE;
        border-radius: 3px 3px 0px 0px;
        border-width: 1px 1px 0px 1px;

          #{$block}#{__buttonWrapper} {
            margin-top: 24px;
          }

          #{$block}#{__couponDropDown__select-arrow} {
            transition: all 0.2s ease;
            transform: rotate(-180deg);

            & svg path {
              stroke: $blue;
            }
          }
      }

      &--disabled {
        &:hover {
          cursor: not-allowed;
        }
      }

      &-placeholder {
        color: $blueDeep;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
      }

      &-inner {
        display: flex;
        align-items: center;
        width: calc(100% - 8px);

        &--isClear {
          width: calc(100% - 40px);
        }
      }

      &-arrow {
        display: flex;
        transition: all 0.2s ease;

        & > div {
          display: flex;
        }

        & svg path {
          stroke: #d7e3e7;
        }
      }
    }

    &__content {
      &-wrapper {
        max-width: 100%;
        max-height: 100px;
        background-color: #f6fcff;
        border: 1px solid #d7e3e7; 
        border-radius: 0px 0px 3px 3px;
        border-width: 0px 1px 1px 1px;
        border-color: #e1ebee;
        box-sizing: border-box;
        z-index: 99;
        width: 100%;
        left: 0;
        margin-bottom: 12px;
      }
    }
  }
}
