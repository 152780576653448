$block : ".login-layout";

#{$block}{
  height:100%;
  display: flex;
  flex-direction: column;
  &__header{
    display: flex;
    padding:24px;
    justify-content: center;
    border-bottom: 1px solid rgba(209, 219, 226, 0.5);

    &.mobile {
      padding: 15px 24px;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
  }
  &__footer{
    display: flex;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #7B94B1;
    justify-content: center;
    border-top: 1px solid rgba(209, 219, 226, 0.5);
    padding:16px 0;
  }
  &__link {
    text-decoration: underline;
    &:hover {
      opacity: 0.8;
    }
  }
}