$block: '.fieldColor';
$dateFieldInput: '.fieldDropDown__trigger-date-input';
$nameFieldInput: '.fieldDropDown__trigger-name-input';
$fieldTriangle: '.fieldDropDown__trigger-triangle';

@mixin createFieldColor($name, $color, $backgroundColor) {
  &__#{$name} {
    color: $color;
    background-color: $backgroundColor;
    border-color: $color;

    #{$fieldTriangle} {
      border-color: $color;
    }

    & #{$dateFieldInput}::-webkit-input-placeholder {
      color: $color;
    }

    & #{$dateFieldInput}::-moz-placeholder {
      color: $color;
    }

    & #{$dateFieldInput}:-ms-input-placeholder {
      color: $color;
    }

    & #{$dateFieldInput}:-moz-placeholder {
      color: $color;
    }

    & #{$nameFieldInput}::-webkit-input-placeholder {
      color: $color;
    }

    & #{$nameFieldInput}::-moz-placeholder {
      color: $color;
    }

    & #{$nameFieldInput}:-ms-input-placeholder {
      color: $color;
    }

    & #{$nameFieldInput}:-moz-placeholder {
      color: $color;
    }

    & svg path {
      stroke: $color !important;
    }

    &--preview {
      background: $color;
    }
  }
}

#{$block} {
  @include createFieldColor(empty, #8698a5, #f8f9fb);
  @include createFieldColor(user, #00a3fa, transparent);
  @include createFieldColor(orange, #fa9600, #fff9ee);
  @include createFieldColor(green, #00c23e, #fafffb);
  @include createFieldColor(turquoise, #00bfbb, #fafffe);
  @include createFieldColor(blue, #2d3ed3, #fafaff);
  @include createFieldColor(red, #f35c5c, #fffafa);
  @include createFieldColor(lightGreen, #bbe446, #fdfff1);
  @include createFieldColor(violet, #dd67d1, #fdfaff);
  @include createFieldColor(yellow, #f7c821, #fffefa);
  @include createFieldColor(darkBlue, #196a98, #fafdff);
  @include createFieldColor(darkViolet, #38238c, #fbfaff);
}
