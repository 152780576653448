$block: '.apiKeys';

#{$block} {
  &__header {
    display: flex;
    align-items: center;
  }

  &__table {
    margin-bottom: 70px;
  }

  &__delete-icon {
    & svg path {
      fill: $redDark;
      stroke: unset !important;
    }
  }

  &__column--large {
    width: 200px;
  }

  &__column--medium {
    width: 160px;
  }

  &__column--small {
    width: 100px;
  }

  &__back-button {
    display: flex;
    justify-content: center;
    width: 100px;
  }

  &__innerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__optionsButton {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10px;
  }

  &__mobileText {
    width: initial;

    &.title {
      color: $blueDeep;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
