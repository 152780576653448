$block: '.actionStepBar';

#{$block} {
  &__container {
    position: relative;
    display: flex;
    margin-bottom: 60px;

    &.mobile {
      width: 100%;
      justify-content: center;
      margin-bottom: 60px;
    }
  }

  &__item {
    position: relative;
    width: 252px;
    color: $blue;
    cursor: pointer;

    &.mobile {
      width: 35px;
      position: initial;

      &:not(:last-child) {
        margin-right: 31px;
      }

      img {
        position: absolute;
        transform: scaleX(0.27) translate(-400px, -8px);
      }
    }

    &:hover {
      color: white;
      path {
        fill: $blue;
      }
    }

    &--checkIcon {
      position: absolute;
      top: 9px;
      left: 200px;
    }

    &--stepNumber {
      position: absolute;
      top: 8px;
      left: 30px;
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;

      &.first {
        left: 16px;
      }
      &.selected {
        color: white;
      }

      &.mobile {
        z-index: 8;
        left: initial;
        top: initial;
      }
    }

    &--text {
      position: absolute;
      top: 8px;
      left: 50px;

      font-size: 11px;
      font-weight: 400;
      line-height: 14px;

      &.first {
        left: 32px;
      }
      &.selected {
        color: white;
      }
    }

    &.offset1 {
      transform: translateX(-10px);
    }
    &.offset2 {
      transform: translateX(-20px);
    }
    &.offset3 {
      transform: translateX(-30px);
    }
  }
}
