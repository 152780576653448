$block: '.upsellModal';

#{$block} {
  padding: 40px 52px;

  &__icon {
    display: flex;
    justify-content: center;
    width: 74px;
    height: 74px;
    background: $grayBackground;
    border-radius: 22px;

    div {
      margin: auto;
    }

    svg {
      width: 40px;
      height: 40px;
    }

    &-container {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
  }

  &__content {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $grayText;
    margin-bottom: 30px;

    p {
      margin-bottom: 10px;
    }

    .black-text {
      color: #000;
      font-weight: 500;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__button {
    margin-bottom: 10px;
    &-cancel {
      width: 68px;
      margin: 0 auto;
      padding: 10px 0;
      color: $grayText;
      cursor: pointer;
    }
  }
}