$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;

  &__wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    &--overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 10px;
      z-index: 1;

      & div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &--full-cover {
      margin: 0;
    }

    &--bg-gray {
      background: rgba(240, 244, 246, 0.7);
    }
  }

  &--main {
    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: auto;
      width: 100%;
      height: 100%;
    }

    &__text {
      margin-top: 15px;
      font-size: 16px;
    }
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
    colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: $blueLighter;
  }

  25% {
    stroke: $blueLight;
  }

  50% {
    stroke: $blue;
  }

  75% {
    stroke: $blueLight;
  }

  100% {
    stroke: $blueLighter;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
