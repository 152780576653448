$block : ".footer";

#{$block}{
  font-size: 14px;
  color: #7B94B1;
  border-top: 1px solid rgba(209, 219, 226, 0.5);
  box-sizing: border-box;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  & a{
    color: #7B94B1;
    text-decoration: underline;
    margin-left: 5px;
    &:hover{
      text-decoration: none;
    }
  }
}