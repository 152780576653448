$block: '.signup_confirm';

#{$block} {
  display: flex;
  padding: 40px 16px;

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__img {
    width: 294px;
    margin: 0 auto;
  }

  &__text {
    max-width: 505px;
    margin: 40px auto;

    &--major {
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      padding-bottom: 10px;

      @media (max-width: $sm-width) {
        font-size: 18px;
        padding-bottom: 8px;
      }
    }

    &--minor {
      font-size: 18px;
      text-align: center;
      color: #7d8d98;
      line-height: 27px;

      @media (max-width: $sm-width) {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  &__button {
    margin: 0 auto;

    button {
      width: 254px;
      height: 70px;
      font-size: 18px;
      text-align: center;

      @media (max-width: $sm-width) {
        font-size: 13px;
        height: 40px;
      }

      p {
        margin: auto;
      }
    }
  }
}
