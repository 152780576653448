$block: '.signingLinkModal';

#{$block} {
  width: 530px;
  padding: 50px 40px;

  &__wrapper {
    padding: 0 10px;
  }

  .modal__header {
    margin-bottom: 40px;
  }

  .modal__title {
    margin-bottom: 5px;
    font-size: 16px;
  }

  .modal__subtitle {
    font-size: 14px;
  }

  .signer__list {
    &.mobile {
      max-height: 50vh;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
    &-item {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      margin-bottom: -1px;

      border-bottom: 1px solid #d7e3e7;
      border-top: 1px solid #d7e3e7;
    }
  }
}