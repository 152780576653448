$block: '.table';

#{$block} {
  &__tableControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #cee0f0;
    box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.16);
    border-radius: 3px;
    width: calc(100vw - 243px);
    max-width: 970px;
    min-width: 740px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      min-width: 0;
    }

    &.document {
      flex-direction: row;
    }

    &--contracts {
      max-height: 350px;
      overflow: auto;
      min-width: 900px;

      &.mobile {
        min-width: 288px;
      }
    }

    @media (max-width: $sm-width) {
      width: 100%;
    }
  }

  &__innerContainer {
    max-width: 970px;

    &.document {
      width: 840px;
      overflow-x: auto;
      overflow-y: hidden;
      margin-bottom: 10px;

      &::-webkit-scrollbar-button:start:increment {
        width: 18px;
        display: block;
        background: transparent;
      }

      &::-webkit-scrollbar-button:end:increment {
        width: 18px;
        display: block;
        background: transparent;
      }

      &.trash {
        overflow-x: hidden;
      }
    }

    &.actions {
      width: 130px;
      background-color: rgba(215, 227, 231, 0.17);
      place-content: start;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 24px 23px 25px;
    color: $grayText;

    &.mobile {
      padding: 27px 23px;
      justify-content: space-between;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #cee0f0;
    }
  }

  &__rowFolderIcon {
    width: 14px;
    height: 12px;
  }

  &__rowFileIcon {
    width: 12px;
    height: 15px;
  }

  &__header {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: $blueDeep;

    padding: 20px 22px;

    &.document {
      width: max-content;
      padding-right: 24px;

      &.template {
        padding-right: 2px;
        margin-right: 0px;
      }
    }

    &.actions {
      place-content: center;
    }

    &.mobile {
      padding: 15px 22px;
      font-weight: 100;
    }
  }

  &__dataRow {
    position: relative;

    &.document {
      height: 32px;
      width: max-content;

      &.signature-request {
          padding-right: 1px;
      }

      &.template {
        padding-right: 1px;
      }

      &.actions {
        place-content: center;
        width: auto;
      }
    }

    &:hover {
      background-color: #f1faff;
    }

    &--delete {
      background: #fff9f9;
    }

    &--inactive {
      &::after {
        content: '\A';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.78);
      }
    }

    &--teammateItem {
      &.mobile {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }

  &__column {
    display: flex;
    width: 0px;
    margin-right: auto;
    position: relative;

    &--check {
      min-width: 20px;
      width: 20px;
      margin-right: 31px;
    }

    &--text {
      width: 350px;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
      word-break: break-word;
      cursor: default;
      display: flex;

      &.mobile {
        color: #000;
        font-weight: 500;
        font-size: 16px;
        width: 100%;
        text-overflow: hidden;
      }

      &.mobileTeamPage {
        flex-direction: column;
        align-items: initial;
      }

      &--document {
        min-width: 438px;
        width: 438px;
        margin-right: 0px;
        align-self: center;

        &.signature-request {
          min-width: 436px;
          width: 436px;
        }

        &.template {
          min-width: 436px;
          width: 436px;
        }

        &.truncated {
          margin-right: 0px;
          min-width: initial;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          width: max-content;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    &--created-by {
      min-width: 180px;
      max-width: 180px;
      align-items: center;
      margin-left: 14px;

      margin-right: initial;
      width: initial;

      max-height: 100%;
      overflow: hidden;

      &--span {
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 100%;
      }
    }

    &--date {
      width: 82px;

      &.document {
        min-width: 135px;
      }
    }

    &--template-text-container {
      width: 400px;
      display: flex;
      align-items: center;

      &.mobile {
        width: 70%;
        align-items: flex-start;
        flex-direction: column;
      }
    }

    &--template-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 10px;

      &.mobile {
        width: 100%;
      }
    }

    &--team-email {
      width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;

      &.mobile {
        color: #00a3fa;
        width: 100%;
      }
    }

    &--signature-request-date {
      width: 135px;
      &.mobile {
        color: $grayText;
        font-weight: normal;
      }
    }

    &--template-date {
      width: 135px;
      &.mobile {
        color: $grayText;
        font-weight: normal;
      }
    }

    &--status {
      width: 211px;
      display: flex;
      justify-content: space-between;

      &.mobile {
        width: initial;
        margin-top: 7px;
      }
    }

    &--action {
      width: 72px;

      &.document {
        align-items: center;
        place-content: center;
        margin-right: 0px;
      }

      &.mobile {
        width: auto;
        margin-right: 0;
      }
    }

    &--template-action {
      width: 70px;
      margin-right: 0;

      &.mobile {
        margin: auto 0;
      }
    }

    &--hidden {
      visibility: hidden;
    }

    &--signer {
      width: 80px;
    }

    &-item {
      &--template-action {
        margin-right: 95px;
        margin-left: -12px;

        &.mobile {
          margin-right: 0;
        }
      }
    }

    &-item {
      &--template-action {
        margin-right: 95px;
        margin-left: -12px;

        &.mobile {
          margin-right: 0;
        }
      }
    }

    &--title {
      &.mobile {
        width: 80%;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: center;

    &-item {
      cursor: pointer;
      margin-right: 6px;
      & svg path {
        stroke: rgb(134, 152, 165);
      }
    }
  }
}
