$block: '.successSendModal';

#{$block} {
  min-width: 570px;
  min-height: 319px;
  padding: 60px 40px 20px;
  font-family: Circular Std;
  font-style: normal;


  &.mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    min-width: 320px;
    padding: 30px 20px 20px;
  }

  &--template {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    margin-bottom: 24px;
    border-bottom: 1px solid $grayLight;

    > :not(:last-child) {
      margin-bottom: 17px;
    }

    &-icon {
      min-height: 62px;
      & div {
        margin-bottom: 10px;
        display: flex;
      }
    }

    &--template {
      border-bottom: none;
      margin: 0;
      padding: 0;
    }

    &--banBorderBottom {
      border-bottom: none;
    }
  }

  &__button {
    width: 200px;
    display: flex;
    justify-content: center;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: $caution;

    &-wrapper {
      text-align: center;

      > :not(:last-child) {
        margin-bottom: 9px;
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #1b3955;

    &.mobile {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-actions {
      display: flex;

      > :not(:last-child) {
        margin-right: 29px;
      }

      &-item {
        background: none;
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $grayText;
        transition: all 0.2s ease;

        &:hover {
          color: #596a78;
        }

        &:disabled {
          cursor: not-allowed;

          opacity: 0.5;
        }

        &--fill {
          & svg path {
            fill: $grayText;
          }

          &:hover {
            & svg path {
              fill: #596a78;
            }
          }
        }

        &--stroke {
          & svg path {
            stroke: $grayText;
          }

          &:hover {
            & svg path {
              stroke: #596a78;
            }
          }
        }

        &-icon {
          margin-right: 7px;

          & div {
            display: flex;
          }
        }
      }

      &-spinner {
        display: flex;
        flex: 1 1 auto;
        margin-left: 15px;
      }
    }
  }
}
