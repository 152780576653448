$block: '.confirmModal';

#{$block} {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &__content {
    min-width: 200px;
    padding: 30px 15px 0 15px;
    margin-bottom: 20px;

    &.mobile {
      padding-top: 40px;
    }
  }

  &__buttons {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.left {
      width: 100%;
      max-width: 440px;
      box-sizing: border-box;
      margin: 0 auto;
      padding: 28px 15px 20px 15px;
      justify-content: start;

      &.mobile {
        .confirmModal__button {
          width: 100%;
          margin-right: initial;
          padding: 0 15px;
          button {
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  &__button {
    margin-right: 15px;

    &--cancel {
      color: $gray;
      cursor: pointer;

      &:hover {
        cursor: pointer;
        color: $grayLight;
        transition: all 0.3s ease;
      }

      &:active {
        color: $grayDark;
      }
    }

    &--delete {
      display: flex;
      justify-content: center;
      min-width: 150px;
    }
  }

  &__footer {
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 0 30px;
    font-size: 12px;
    line-height: 22px;
    color: $grayText;

    a {
      color: $grayDarker;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
