$block: '.button';

#{$block} {
  border-radius: 110px;
  align-self: center;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 42px;
  vertical-align: baseline;
  line-height: 19px;
  padding: 0 22px 0 21px;
  transition: all 0.2s ease;
  display: flex;
  position: relative;
  color: #fff;
  border: 1px solid transparent;
  @include svgColorTransition(0, 0.2s);
  @include svgColorFill(#fff);

  &.centered-text {
    justify-content: center;
  }

  &:hover {
    cursor: pointer;
    transition: all 0.3s ease;
  }

  &--primary {
    background-color: $blue;
    border-color: $blue;
    &:hover {
      background-color: $blueLight;
      border-color: $blueLight;
      box-shadow: 0px 4px 10px rgba(0, 163, 250, 0.28);
    }
    &:active {
      background-color: $blueDark;
      border-color: $blueDark;
      box-shadow: 0px 4px 10px rgba(0, 163, 250, 0.28);
    }
  }

  &--primary.button--disabled {
    background-color: $grayDark;
    border-color: $grayDark;
    &:hover {
      box-shadow: none;
      cursor: no-drop;
    }
  }

  &--secondary {
    background-color: transparent;
    border-color: $blue;
    color: $blue;
    @include svgColorTransition(0, 0.2s);
    @include svgColorFill($blue);

    &:hover {
      color: #fff;
      @include svgColorFill(#fff);
      border-color: $blue;
      background-color: $blue;
    }

    &:active {
      box-shadow: 0px 4px 10px rgba(0, 163, 250, 0.28);
    }
  }

  &--secondary.button--disabled {
    color: #fff;
    background-color: $grayDark;
    border-color: $grayDark;

    &:hover {
      box-shadow: none;
      cursor: no-drop;
    }
  }

  &--red {
    background-color: $red;
    border-color: $red;
    color: #fff;
    &:hover {
      box-shadow: 0px 4px 10px rgba(255, 81, 81, 0.4);
    }
  }

  &--red.secondary {
    background-color: transparent;
    border-color: $red;
    color: $red;
    &:hover {
      box-shadow: 0px 4px 10px rgba(255, 81, 81, 0.4);
    }
  }

  &--red.button--disabled {
    background-color: $grayDark;
    border-color: $grayDark;
    &:hover {
      box-shadow: none;
      cursor: no-drop;
    }
  }

  &__spinner {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }

  &__text {
    &--loading {
      opacity: 0.6;
    }
  }

  &__ico {
    margin-right: 6px;
    align-items: center;
    align-self: center;

    &--after {
      margin-right: unset;
      margin-left: 6px;
    }

    & > div {
      display: flex;
      align-items: center;
    }
  }

  &.cancel {
    min-height: initial;
    padding: initial;
    width: 20px;
    height: 20px;
    justify-content: center;
    background-color: white;
  }
}
