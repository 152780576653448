$block: '.uiMultiSelect';

#{$block} {
  &__control {
    width: 100%;
    height: 43px;
    display: flex;
    padding: 0 10px 0 17px;
    font-size: 14px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.13);
    transition: all 0.2s ease;
    border: 1px solid #d7e3e7;
    border-radius: 3px;

    #{$block}#{__dropdownIcon} {
      transition: all 0.2s ease;
      svg > path {
        transition: all 0.2s ease;
        stroke: #d7e3e7;
      }
    }
    #{$block}#{__closeIcon} {
      transition: all 0.2s ease;
      svg > path {
        transition: all 0.2s ease;
        stroke: #d7e3e7;
      }
    }

    &--menu-is-open {
      border: 1px solid #00a3fa;

      #{$block}#{__dropdownIcon} {
        transition: all 0.2s ease;
        transform: rotate(-180deg);
        svg > path {
          transition: all 0.2s ease;
          stroke: #00a3fa;
        }
      }
      #{$block}#{__closeIcon} {
        svg > path {
          transition: all 0.2s ease;
          stroke: #00a3fa;
        }
      }
    }

    &:focus,
    &:hover {
      cursor: pointer;
      background-color: #f4fafc;

      #{$block}#{__dropdownIcon} {
        svg > path {
          transition: all 0.2s ease;
          stroke: #00a3fa;
        }
      }
      #{$block}#{__closeIcon} {
        svg > path {
          transition: all 0.2s ease;
          stroke: #00a3fa;
        }
      }
    }
  }

  &__control > &__indicators {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    gap: 10px;
    margin-left: 10px;

    #{$block}#{__dropdownIcon_wrapper} > div > div {
      display: flex;
      flex-direction: column;
    }
  }

  // &__control > &__indicators > &__dropdownIcon_wrapper > div > div {
  //   display: flex;
  //   flex-direction: column;
  // }

  &__control > &__value-container {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: nowrap !important;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    padding: 2px 8px 2px 0px;
    box-sizing: border-box;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      height: 0px;
    }

    #{$block}#{__input-container} {
      margin: 2px;
      box-shadow: none;
    }

    #{$block}#{__placeholder} {
      color: $gray;
    }
  }

  &__control > &__value-container > &__multi-value {
    display: flex;
    min-width: 0;
    box-sizing: border-box;
    flex: 0 0 auto;
    background-color: #00a3fa;
    font-family: Circular Std;
    color: #fff;
    border-radius: 15px;
    margin: 2px;

    #{$block}#{__multi-value__label} {
      color: #fff;
      padding: 3px 0px 3px 10px;
    }

    #{$block}#{__multi-value__remove} {
      transition: all 0.2s ease;
      border-radius: 15px;
      margin: 3px;
      padding-left: 1.5px;
      padding-right: 1.5px;
      svg > path {
        transition: all 0.2s ease;
        fill: #fff;
      }
      &:hover {
        svg > path {
          transition: all 0.2s ease;
          fill: #00a3fa;
        }
        transition: all 0.2s ease;
        background-color: #fff;
      }
    }
  }

  &__menu {
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 6px 21px rgba(84, 149, 208, 0.35);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
    overflow: hidden;

    #{$block}#{__menu-list} {
      max-height: 172px;
      overflow-y: auto;
      position: relative;
      padding-bottom: 0;
      padding-top: 0;
      box-sizing: border-box;

      #{$block}#{__option} {
        cursor: default;
        display: block;
        font-size: inherit;
        width: 100%;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;
        color: #596a78;
        background-color: #fff;
        padding: 12px 18px;

        &:not(:last-child) {
          border-bottom: 1px solid #cee0f0;
        }
        &:hover {
          cursor: pointer;
          color: #00a3fa;
          background-color: #f3f9fb;
        }
      }
    }

    &-notice--no-options {
      text-align: center;
      color: $gray;
      box-sizing: border-box;
      height: 43px;
      line-height: 43px;
    }
  }
}
