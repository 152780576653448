$block: '.overlayLoader';

#{$block} {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 2;
  background-color: #ebeef2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__icon {
    margin-bottom: 24.5px;

    &--preview {
      margin-bottom: 10px;

      & svg {
        width: 40px;
      }
    }
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: $gray;

    &--preview {
      font-size: 13px;
      line-height: 15px;
    }
  }
}
