$block: '.wizardSignForm';

#{$block} {
  &__mainForm {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    &.fullscreen {
      position: absolute;
      left: 0;
      width: 100%;
    }
  }

  &__uploadContainer {
    max-width: 468px;
    width: 100%;
    margin-bottom: 20px;
  }

  &__mainWrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    & .button {
      width: 210px;
      display: flex;

      & svg path {
        fill: none;
        stroke: #fff;
      }
    }
  }

  &__innerContainer {
    border-bottom: 1px solid #cddfef;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0 15px 0;
  }

  &__radioGroup {
    width: 100%;
    max-height: 129px;
    padding: 18px;
    border-radius: 7px;
    box-sizing: border-box;
    box-shadow: 0px 1px 10px rgba(0, 83, 168, 0.15);
    margin-bottom: 20px;

    & .radio-button__wrapper:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__first-tooltip {
    display: flex;
    align-items: center;
    width: 215px;
    height: 42px;
    top: 85px;
    left: 190px;
  }
  &__second-tooltip {
    display: flex;
    align-items: center;
    width: 215px;
    height: 62px;
    top: 110px;
    left: 230px;
  }
  &__third-tooltip {
    display: flex;
    align-items: center;
    width: 187px;
    height: 62px;
    top: 145px;
    left: 200px;
  }

  &__form {
    width: 100%;
    padding-bottom: 20px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__signersContainer {
    margin-bottom: 15px;

    & .signers__item--removable {
      width: 100%;
    }
  }

  &__emailField-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $blueDeep;
    margin-bottom: 15px;
  }

  &__emailField-isOrdered {
    margin-bottom: 30px;

    & .uiCheckbox__label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $grayDark;
    }
  }

  &__signerItem {
    width: 100%;

    & .form__field {
      margin-right: 0px !important;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__finishContainer {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--inner {
      max-width: 400px;
      width: 100%;

      & .form__field:last-child {
        margin-bottom: 40px;
      }

      &.blockSeparator{
        padding-top: 20px;
        border-top: 1px solid #e4e9f1;
      }
    }

    &--buttonWrap {
      max-width: 400px;
      width: 100%;
      margin-bottom: 20px;
    }

    
  }

  &__finishTitle {
    color: $blueDeep;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 40px;
  }

  &__smallTooltip {
    width: 200px;
    word-wrap: break-word;
  }

  &__tabPanel {
    display: flex;
    justify-content: center;
    width: 100%;

    &-list {
      display: flex;
      width: 100%;
    }

    &-tab {
      padding: 6px 0px;
      font-size: 16px;
      line-height: 20px;
      color: $blueDeep;
      margin-bottom: 28px;
      border-bottom: 1px solid transparent;
      cursor: pointer;
      transition: 0.2s;

      &:first-child {
        margin-right: 32px;
      }

      &.inactive {
        border-bottom: 1px solid $grayDark;
        
        &.disabled{
          pointer-events: none;
          color: $gray;
          border-bottom: 1px solid $grayDark;
        }
        
        &:hover {
          border-bottom: 1px solid $grayDark;
          transition: 0.2s ease;
        }
      }

      &.alone {
        font-weight: 500;
        padding: 0px;
        cursor: initial;
      }

      &.active {
        border-bottom: 1px solid $blue;
        font-weight: 500;
        transition: all 0.2s ease;
      }
    }
  }

  &-createButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & .button {
      width: 210px;
      display: flex;
      justify-content: center;
    }

    &.mobile {
      display: flex;
      justify-content: center;
    }
  }

  &__datePicker {
    &--disabled{
      opacity: 0.7;
      margin-top: 5px;
    }
  }
}
