$block: '.hoverTooltip';

#{$block} {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0; 
  left: 0;

  #{$block}__wrapper {
    display: none;
  }

  &:hover {
    #{$block}__wrapper {
      display: block;
    }
  }

  &__wrapper {
    position: absolute;
    width: 221px;

    background: #ffffff;
    border: 1px solid #e4e7eb;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.02), 0px 5px 22px rgba(1, 151, 178, 0.06);
    border-radius: 10px;
    padding: 15px 14px;
    z-index: 1000;

    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-align: left;

    color: #5a7386;

    &--top {
      transform: translateY(-100%);
      top: -15px;
      right: -15px; 

      &.right {
        @media (max-width: $xs-width) {
          transform: translateY(-100%) scale(-1,1) translateX(-200px);
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        right: 15px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid white;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: -10px;
        right: 15px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #e4e7eb;
      }
    }

    &--bottom {
      right: -15px;
      top: calc(100% + 15px);

      &::after {
        content: '';
        position: absolute;
        top: -8px;
        right: 15px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;
      }

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        right: 15px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #e4e7eb;
      }
    }
  }

  &--mirrorText {
    @media (max-width: $xs-width) {
      transform: scale(-1,1);
    }
  }
}
