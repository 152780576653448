$block: '.interactModal';

#{$block} {
  display: flex;
  width: 100%;
  max-width: 1260px;
  background: #ffffff;
  box-shadow: 0px 5px 24px rgba(53, 135, 182, 0.22);
  border-radius: 6px;
  height: 90vh;
  max-height: 1085px;
  min-width: 1100px;

  &.fullscreen {
    max-width: initial;
    height: 100%;
  }

  .fieldShape {
    .fieldDropDown {
      -ms-touch-action: none;
      touch-action: none;
    }
    &.invalid {
      .fieldDropDown::after {
        content: '\A';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(255, 81, 81, 0.7);
      }
    }
  }

  @media (max-width: $lg-width) {
    width: 100vw;
    min-width: 95vw;
    overflow: auto;
  }

  &__inner {
    overflow: hidden;
    min-width: 1260px;
    height: 100%;
    position: relative;

    &.fullscreen {
      width: 100%;
      min-width: max-content;
    }
  }

  &__header {
    background: #edf2f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 83px;
    padding: 0 40px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    &-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      color: $blueDeep;
    }

    &-bar {
      display: flex;

      &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 86px;
        height: 42px;
        background: transparent;
        border: 1px solid #5e869d;
        box-sizing: border-box;
        border-radius: 3px;
        color: #5e869d;
        transition: all 0.2s ease;

        &:first-child {
          margin-right: 12px;
        }

        & svg path {
          stroke: #5e869d;
        }

        &:hover {
          color: #596a78;
          transition: all 0.2s ease;
          border-color: #596a78;

          & svg path {
            stroke: #596a78;
          }
        }

        &-icon {
          margin: 0 6px;

          & > div {
            display: flex;
          }
        }
      }
    }

    &-navigate {
      display: flex;
      border-right: 1px solid #d2dde3;
      margin-right: 25px;
      padding-right: 25px;

      #{$block}#{__header-navigate-button--next} {
        & #{$block}#{__header-bar-button-icon} {
          margin-top: 2px;
        }
      }

      &-button {
        &--next {
          flex-direction: row-reverse;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }

    &-copyPast {
      display: flex;
    }

    &-send {
      display: flex;
      align-items: center;

      & .sendTooltip {
        width: 228px;
        text-align: center;
      }
    }

    &-cancelButton {
      line-height: 18px;
      background-color: transparent;
      color: #5e869d;
      padding: 14px;

      &:hover {
        color: #596a78;
        transition: all 0.2s ease;
      }
    }
  }

  &__bodyWrapper {
    display: flex;
    justify-content: center;
    height: inherit;
  }

  &__body {
    display: flex;
    height: calc(100% - 120px);
    padding: 5px 0 0 0;
    box-sizing: border-box;
    user-select: none;

    &.fullscreen {
      padding-top: 40px;
      width: max-content;
    }
  }

  &--cursor-pointer {
    cursor: pointer;
  }

  &__fieldBar {
    &-wrapper {
      min-width: 226px;
      max-width: 226px;
      padding: 0 8px 0 40px;
      height: 100%;
      overflow-y: auto;
    }

    &-fieldWrapper,
    &-progressWrapper {
      margin-bottom: 40px;
      padding: 0 16px;
    }

    &-fieldTitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $blueDeep;
      margin-bottom: 9px;
      margin-top: 1em;
    }

    &-fieldList {
      border: 1px solid #e4e9f1;
      box-shadow: 0px 2px 7px rgba(0, 74, 219, 0.11);
      border-radius: 4px;
      overflow: hidden;
    }

    &-progressInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    &-fieldItem {
      display: flex;
      color: $grayDark;
      background-color: #fff;
      transition: all 0.2s ease;
      line-height: 18px;
      padding: 0px 15px;
      height: 40px;
      align-items: center;
      box-sizing: border-box;

      &:not(:last-child) {
        border-bottom: 1px solid #e4e9f1;
      }

      &:hover {
        cursor: pointer;
        color: #fff;
        transition: all 0.2s ease;
        background-color: $blue;
        border-color: $blue;
      }

      &--stroke {
        & svg path {
          stroke: $grayDark;
        }

        &:hover {
          & svg path {
            stroke: #fff;
          }
        }
      }

      &--fill {
        & svg path {
          fill: $grayDark;
        }

        &:hover {
          & svg path {
            fill: #fff;
          }
        }
      }

      &--active {
        color: #fff;
        background-color: $blue;
        border-color: $blue !important;

        #{$block}#{__fieldBar-fieldItem--stroke} {
          & svg path {
            stroke: #fff;
          }
        }

        &--fill {
          & svg path {
            fill: #fff;
          }
        }
      }

      &--active {
        color: #fff;
        background-color: $blue;
        border-color: $blue;
      }

      &--active#{$block}#{__fieldBar-fieldItem--stroke} {
        & svg path {
          stroke: #fff;
        }
      }

      &--active#{$block}#{__fieldBar-fieldItem--fill} {
        & svg path {
          fill: #fff;
        }
      }

      &--disabled {
        opacity: 0.4;
        cursor: not-allowed !important;

        &#{$block}#{__fieldBar-fieldItem--stroke} {
          & svg path {
            stroke: $grayDark !important;
          }
        }

        &#{$block}#{__fieldBar-fieldItem--fill} {
          & svg path {
            fill: $grayDark !important;
          }
        }
      }

      &-icon {
        margin-right: 10px;

        & > div {
          display: flex;
        }
      }
    }

    &-inputField {
      margin-top: 32px;
      &-title {
        margin-bottom: 8px;
      }
    }

    &-selectField {
      &-list {
        height: calc(100% - 269px);
        padding: 0px 16px;
      }

      &-inputField {
        margin-top: 32px;
        &-title {
          margin-bottom: 8px;
        }

        &-hint {
          margin-top: 0.5em;
          font-size: 0.8em;
          color: red;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $blueDeep;
        border-bottom: 1px solid #d8e0ef;
        margin-bottom: 17px;
        padding-bottom: 15px;

        &--stroke {
          #{$block}#{__fieldBar-selectField-item-icon} {
            & svg path {
              stroke: $blueDeep;
            }
          }
        }

        &--fill {
          #{$block}#{__fieldBar-selectField-item-icon} {
            & svg path {
              fill: $blueDeep;
            }
          }
        }

        &-wrapper {
          margin-bottom: 14px;

          > :not(:last-child) {
            margin-bottom: 17px;
          }
        }

        &-inner {
          display: flex;
          align-items: center;
        }

        &-icon {
          margin-right: 7px;

          & > div {
            display: flex;
          }
        }

        &-label {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }

        &-removeIcon {
          & svg path {
            fill: $red;
          }

          &:hover {
            cursor: pointer;

            & svg path {
              fill: $redDark;
            }
          }
        }
      }

      &-selector {
        &-title {
          margin-top: 0.5em;
          margin-bottom: 12px;
        }

        &-apply {
          & button {
            width: 100%;
          }
        }
      }

      &-checkbox {
        & .uiCheckbox__label {
          color: $grayDark;
        }
      }
    }
  }

  &__documentView {
    min-width: 767px;
    max-width: 767px;
    margin-right: 12px;
    position: relative;
    overflow: auto;

    &-inner {
      padding: 30px;
      padding-bottom: 0px;
      position: relative;
    }
  }

  &__documentNavigation {
    height: calc(100% - 30px);
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 30px;

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $blueDeep;
      margin-bottom: 10px;

      & > span {
        color: #9bb4c2;
        font-weight: 400;
      }
    }

    &-wrapper {
      overflow: hidden;
      max-width: 285px;
    }

    &-item {
      min-width: 193px;
      min-height: 100px;
      position: relative;
      border: 1px solid #e4e9f1;
      box-sizing: border-box;
      box-shadow: 0px 2px 7px rgba(0, 74, 219, 0.11);
      border-radius: 4px;
      margin-bottom: 16px;
      width: max-content;

      &:hover {
        cursor: pointer;
        box-shadow: 0px 2px 7px rgba(0, 74, 219, 0.21);
      }
    }

    &-img {
      width: 193px;
      border-radius: inherit;
    }

    &-field {
      position: absolute;

      &--signature {
        background: #e6f0ff;
      }

      &--initial {
        background: #fffef1;
      }

      &--name {
        background: #e4ffe5;
      }

      &--date {
        background: #e4ffe5;
      }

      &--text {
        background: #faebff;
      }

      &--checkbox {
        background: #d2f8f8;
      }
    }
  }

  &__breadcrumbs {
    display: flex;
    width: 450px;
    margin: 20px 0 0 48px;

    > :last-child {
      color: $blue;

      &::after {
        display: none;
      }
    }
    &--item {
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      margin-right: 6px;
      display: flex;
      align-items: center;
      font-family: 'roboto';
      color: #677a94;

      &::after {
        display: block;
        content: ' ';
        background-image: url('../../../images/icons/arrow-right.svg');
        background-size: 25px 14px;
        height: 14px;
        width: 20px;
      }
    }
  }
}
