$block: ".grid";

#{$block} {
  &__dropDown {
    margin: 0 13px;
    &-wrapper {
      // position: absolute;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #d7e3e7;
      box-sizing: border-box;
      border-radius: 3px;
      height: 42px;
      box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.1);

      &--open {
        border-color: $blue;
      }
      &:hover {
        cursor: pointer;
        border-color: #cfdfed;
        background-color: #f4fafc;
        transition: all 0.2s ease;
        color: $blue;

        & svg path {
          stroke: $blue;
        }
      }
    }

    &-inner {
      position: relative;
      padding: 0 17px 0 17px;
    }

    &-trigger {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      cursor: pointer;
      color: #a5b3bd;

      &:hover {
        color: $blue;
      }

      & > span {
        width: 3px;
        height: 3px;
        border-radius: 100%;
        background-color: $blue;

        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }

      &--open {
        color: $blue;
      }

      &-arrow {
        margin-left: 15px;
        transition: all 0.2s ease;
        transform: rotate(0deg);

        & div {
          display: flex;
        }

        & svg path {
          stroke: #a5b3bd;
        }

        &--open {
          transform: rotate(-180deg);

          & svg path {
            stroke: $blue;
          }
        }
      }
    }

    &-list {
      position: absolute;
      margin-top: 15px;
      margin-left: -20px;
      width: 195px;
      padding: 10px 0;
      background: #ffffff;
      box-shadow: 0px 0px 26px rgba(53, 135, 182, 0.24);
      border-radius: 4px;
      z-index: 999;

      &:before {
        content: "";
        position: absolute;
        right: 40px;
        top: -15px;
        border: 7px solid transparent;
        border-bottom: 10px solid white;
      }

      @media (max-width: $sm-width) {
        z-index: 1;
      }
    }

    &-itemWrapper {
      &:hover {
        background-color: #f4fafc;
      }
      &:not(:last-child) {
        & #{$block}#{__dropDown-item} {
          border-bottom: 1px solid #e3edf5;
        }
      }
    }

    &-item {
      display: flex;
      padding: 10px;
      margin: 0 25px;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid #e3edf5;
      }

      &--move {
        color: $grayText;

        & svg path {
          stroke: $blue;
        }
      }

      &--delete {
        color: $red;

        & svg path {
          fill: $red;
        }
      }

      &-icon {
        margin-right: 14px;

        & > div {
          display: flex;
        }
      }
    }
  }
}
