$block: '.sign-up';

#{$block} {
  min-height: 100%;
  display: flex;

  @media (max-width: $sm-width) {
    justify-content: center;
  }

  &__left-side {
    flex: 0.4;
    display: flex;
    flex-direction: column;

    @media (max-width: $sm-width) {
      flex: 1;
    }
  }
  &__right-side {
    position: relative;
    flex: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      z-index: -2;
      background-color: #00a3fa;
    }
    &::after {
      content: '';
      width: 100%;
      height: 51%;
      position: absolute;
      z-index: 1;
      bottom: 0;
      background: url('../../images/bg/signUp.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom;
    }

    @media (max-width: $sm-width) {
      display: none;
    }
  }
  &--top-layer {
    z-index: 20;
  }

  &__title {
    margin-bottom: 32px;
    font-weight: bold;
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    color: #ffffff;

    @media (max-width: $sm-width) {
      margin: 16px 0;
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @media (max-width: $sm-width) {
      margin-top: 20px;
    }
  }
  &__content {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex: 1;

    @media (max-width: $sm-width) {
      max-width: 464px;
      margin: 30px auto;
      margin-bottom: 0;
    }
  }
  &__footer {
    display: flex;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #7b94b1;
    justify-content: center;
    border-top: 1px solid rgba(209, 219, 226, 0.5);
    padding: 16px 0;
  }
  &__link {
    text-decoration: underline;
    &:hover {
      opacity: 0.8;
    }
  }

  &__tabs-wrapper {
    margin-top: 40px;
  }
}
