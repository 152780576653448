$block: '.reminderModal';

#{$block} {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 40px 25px 35px;
  }

  &__header {
    margin-bottom: 25px;
  }

  &__options {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $gray;

    &.mobile {
      max-height: 50vh;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }

  &__text-item {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__option-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__optionItem {
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: space-between;
    padding: 21px 0px;

    border-bottom: 1px solid #d7e3e7;

    &--checked {
      color: $blueDeep;
    }

    &:first-child {
      border-top: 1px solid #d7e3e7;
    }

    &:disabled {
      cursor: not-allowed;

      & > * {
        cursor: not-allowed;
      }
    }
  }
}
