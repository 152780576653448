$block: ".tableControls";

#{$block} {
  &__pagingCounter {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: $gray;

    & span {
      color: black;
    }

    &--template {
      span {
        color: $gray;
      }
    }
  }

  &__control {
    background-color: unset;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0;

    &-icon {
      margin-right: 15px;

      & div {
        display: flex;
      }
    }

    &-spinner {
      display: flex;

      margin-right: 15px;
    }

    &--blue {
      color: $blue;

      &:hover {
        color: $blueDark;

        #{$block}#{__control-icon} {
          & svg path {
            fill: $blueDark;
          }
        }
      }

      &:disabled {
        &:hover {
          color: $blue;
        }
      }

      #{$block}#{__control-icon} {
        & svg path {
          fill: $blue;
        }
      }
    }

    &--red {
      color: $red;

      &:hover {
        color: $redDark;

        #{$block}#{__control-icon} {
          & svg path {
            fill: $redDark;
          }
        }
      }

      &:disabled {
        &:hover {
          color: $red;
        }
      }

      #{$block}#{__control-icon} {
        fill: $red;
      }
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  &__controlsGroup {
    display: flex;

    > :not(:last-child) {
      margin-right: 40px;
    }
  }

  &__headerControl {
    background-color: unset;
    padding: 0;
    display: flex;

    &:hover {
      color: #1d262e;
    }

    &.status {
      margin-left: 15px;
    }

    & span {
      margin-right: 5px;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__path {
    display: flex;
    width: 450px;
    margin-bottom: 13px;

    > :last-child {
      color: $blue;
      
      &::after{
        display: none;
      }
    }
    &--item {
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      margin-right: 10px;
      display: flex;
      align-items: center;

      &::after {
        display: block;
        content: " ";
        background-image: url("../../../images/icons/arrow-right.svg");
        background-size: 25px 14px;
        height: 14px;
        width: 20px;
      }

      p {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.hiddenFolder {
        p {
          max-width: 186px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &.mobile {
        p {
          max-width: 140px;
        }
      }
      
      &.skipDots {
        position: relative;
        overflow: visible;
      }
    }

    &-hiddenFolder {
      position: absolute;
      top: 20px;
      left: -50px;
      width: 256px;
      border: 1px solid #dfeefc;
      box-shadow: 0px 5px 26px #3587b6 8%;
      background-color: white;
      border-radius: 4px;
      padding: 22px;
      z-index: 10;

      &.mobile {
        left: -104px;
      }
  
      &--item {
        padding: 10px 0;
        border-bottom: 1px solid $grayLight;

        &:last-child {
          border-bottom: none;
        }

        :hover {
          cursor: pointer;
        }

        &-icon {
          margin-right: 20px;
        }
      }
    }
  }


  &__actions {
    display: flex;
    width: 440px;
    justify-content: flex-end;
    align-items: center;

    &.mobile {
      width: initial;
    }

    &--selectAction {
      display: flex;
      align-items: center;
      color: #a5b3bd;
      white-space: nowrap;

      &-count {
        color: $blue;
        margin-right: 5px;
      }
    }
  }

  &__create {
    &-btn {
      button {
        width: 152px;
        height: 44px;
        justify-content: center;
      }

      &.mobile {
        button {
          width: initial;
          height: initial;
        }
      }
    }
  }
}
