$block : '.tableFilters';

#{$block} {
  &__wrapper {
    margin-bottom: 23px;
  }

  &__search {
    .form__input-icon {
      top: 13px;
    }
  }

  &__item {
    position: relative;
    width: 286px;

    @media (max-width: $sm-width) {
      width: 100%;
      margin-bottom: 13px;
    }

    &--small {
      width: 138px;
    }

    input {
      width: 100%;
    }
  }

  &__itemGroup {
    display: flex;
    justify-content: space-between;

    #{$block}#{__item}:not(:last-child) {
      margin-right: 10px;
    }

    @media (min-width: $sm-width) {
      #{$block}#{__item}:not(:last-child) {
        margin-right: 22px;
      }
    }

    @media (max-width: $sm-width) {
      flex-wrap: nowrap;
      flex-direction: column;
    }

    &.mobile {
      margin-bottom: 5px;
    }
  }
}