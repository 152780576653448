$block: ".switch-block";

#{$block} {
  display: flex;
  position: relative;
  min-width: 150px;
  padding: 25px 0 25px 15px;

  border: 1px solid #d7e3e7;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.13);
  border-radius: 13px;
  align-items: center;

  &__discount {
    position: absolute;
    top: -14px;
    left: 5px;
  }

  &--active {
    border: 1.5px solid #00a3fa;
    box-shadow: 0px 5px 11px rgba(0, 163, 250, 0.06);
  }

  &__radio-button-wrapper {
    margin-right: 10px;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  &__radio-button {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: inset 0px -1.8px 3.55px rgba(6, 33, 57, 0.1);

    &--unchecked {
      background: linear-gradient(180deg, #ffffff 0%, #ebeff2 100%);
      border: 0.9px solid #adbfc9;
    }

    &--checked {
      background: #00a3fa;
      display: flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }
}
