$block: '.slider';

#{$block} {
  width: 100%;
  max-width: 720px;

  & .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
  }

  & .slick-dots {
    position: relative;
  }
  & .slick-dots li button:before {
    color: #ffffff;
    opacity: 0.4;
    font-size: 8px;
  }
  & .slick-dots li.slick-active button:before {
    color: #ffffff;
    opacity: 1;
  }
  & .slick-dots li {
    margin: 0px;
  }

  &__button {
    margin: 0 30px;
    top: 50%;
    z-index: 200;
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.4);
    transform: translate(0, -50%);
    border-radius: 50%;

    & svg {
      width: 20px;
      height: 20px;
      transform: rotate(-90deg) translate(-2px, 2px);
      path {
        stroke: white;
        stroke-width: 0.5px;
      }
    }
    &--next {
      right: 0;
      svg {
        transform: rotate(-90deg) translate(-2px, 2px);
      }
    }
    &--prev {
      svg {
        transform: rotate(90deg) translate(2px, 2px);
      }
    }
  }

  &__item {
    width: 364px;
    padding: 50px 25px 55px 25px;
    background-color: #ffffff;
    border: 1px solid #cee0f0;
    box-sizing: border-box;
    box-shadow: 0px 2px 24px rgba(0, 27, 81, 0.15);
    border-radius: 6px;
  }
  &__avatar-wrapper {
    display: flex;
    justify-content: center;

    &-buynow {
      display: flex;
      justify-content: center;
      width: auto;
    }
  }
  &__avatar {
    min-width: 86px;
    min-height: 86px;
    max-width: 87px;
    max-height: 87px;
    border-radius: 50%;

    &-buynow {
      min-width: 79px;
      min-height: 79px;
      max-width: 80px;
      max-height: 80px;
    }
  }

  &__name {
    margin-top: 5px;
    color: $blueDeep;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    &-buynow {
      font-size: 20px;
      text-align: start;
    }
  }
  &__description {
    margin-top: 12px;
    color: #00a3fa;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    &-buynow {
      margin-top: 7px;
      font-size: 18px;
      text-align: start;
    }
  }
  &__text {
    text-align: center;
    margin-top: 10px;
    color: $grayText;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 22px;
  }
  &__info-buynow {
    padding-left: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
