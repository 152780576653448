$block: '.buynow';

#{$block} {
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: $lg-width) {
    justify-content: normal;
  }

  &__wrapper {
    margin: auto;
    padding: 40px;

    &.mobile {
      padding: 20px;
    }
  }

  &__content {
    width: 100%;
    display: flex;

    &.mobile {
      display: initial;
    }

    &-left {
      flex: 1;
      margin-right: 80px;

      &.mobile {
        flex: initial;
        margin-right: initial;
        margin-bottom: 50px;
      }

      .label {
        width: 590px;
        margin-bottom: 40px;

        &.mobile {
          width: 280px;
        }

        &-title {
          font-weight: 900;
          font-size: 38px;
          line-height: 44px;
          margin-bottom: 14px;
          color: #0B2034;

          span {
            text-transform: capitalize;
          }
        }

        &-description {
          font-weight: normal;
          font-size: 18px;
          line-height: 27px;
          color: #526172;
        }
      }

      .credentials {
        width: 696px;
        padding: 53px 70px 50px 70px;
        border: 1px solid #CEE0F0;
        border-radius: 3px;
        box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.16);
        box-sizing: border-box;

        &.mobile {
          width: 280px;
          padding: initial;
          border: initial;
          border-radius: initial;
          box-shadow: initial;
        }

        &.business {
          padding-top: 20px;
        }

        &__label {
          padding-bottom: 10px;
          margin-bottom: 42px;
          font-size: 18px;
          font-weight: 500;
          line-height: 23px;
          border-bottom: 1px solid #DFE7F0;
        }

        &-personal {
          margin-bottom: 65px;

          &__items {
            display: flex;
            flex-wrap: wrap;
          }

          &-item {
            width: 265px;

            &:first-child {
              margin-right: 24px;
            }

            &.mobile {
              width: 100%;

              &:first-child {
                margin-right: initial;
              }
            }
          }
        }

        &-subscription {
          margin-bottom: 65px;

          &__items {
            display: flex;

            &.mobile {
              display: initial;
            }
          }

          &-item {
            position: relative;
            display: flex;
            width: 265px;
            height: 93px;
            padding: 16px;
            border: 1px solid #D7E3E7;
            border-radius: 13px;
            box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.13);
            box-sizing: border-box;

            &.mobile {
              width: 100%;

              &:first-child {
                margin-bottom: 40px;
              }
            }

            &:first-child {
              margin-right: 25px;
            }

            &.active {
              border: 1.5px solid #00A3FA;
              box-shadow: 0px 5px 11px rgba(0, 163, 250, 0.06);
            }

            .label {
              margin: auto;
            }

            .save {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: -14px;
              left: 16px;
              width: 78px;
              height: 28px;
              background: #F1FAFF;
              color: #00A3FA;
              font-size: 12px;
              border-radius: 50px;
            }
          }

          &--name {
            display: flex;
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            margin-bottom: 3px;
          }

          &--price {
            font-size: 12px;
            line-height: 20px;
            color: #7D8D98;
            padding-left: 21px;
          }
        }

        &-card {
          &__container {
            width: 418px;

            &.mobile {
              width: 280px;

              .creditCard {
                flex-wrap: wrap;
              }
            }

            &.filled {
              background-color: $greenLight;
              box-shadow: 0px 5px 11px rgba(0, 163, 250, 0.06);
              border-radius: 10px;
            }
          }

          &__form {
            margin-right: initial;
          }
        }
      }
    }

    &-right {
      flex: 1;

      .plan-badge {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 51px 0;
        width: 440px;
        margin-bottom: 34px;
        border: 1px solid #BAC9DB;
        box-sizing: border-box;
        border-radius: 32px;
        color: #526172;

        &.mobile {
          width: 280px;
        }

        &.business {
          border: 2px solid #00A3FA;
          box-shadow:
            0px 17.7209px 14.1767px rgba(77, 162, 226, 0.0328297),
            0px 8.6871px 6.94968px rgba(77, 162, 226, 0.0258431),
            0px 3.43489px 2.74791px rgba(77, 162, 226, 0.0195849);
        }

        &__suffix {
          position: absolute;
          top: -18px;
          width: 158px;
          padding: 7px;
          background: #00A3FA;
          color: white;
          border-radius: 50px;
          text-align: center;
        }

        &__label {
          text-align: center;
          margin-bottom: 27px;
        }

        &__name {
          font-size: 36px;
          line-height: 60px;
          text-transform: capitalize;
        }

        &__price {
          font-size: 58px;
          line-height: 60px;
          font-weight: 400;

          span {
            font-size: 18px;
            line-height: 22px;
            font-weight: normal;
          }

          &-suffix {
            font-size: 22px;
            line-height: 28px;
          }
        }

        &__defaultPrice {
          &-container {
            display: flex;
            justify-content: space-between;
            margin: 0px 65px;
            align-items: center;

            &.mobile {
              margin: 0px 30px;
            }
          }

          &-price-container {
            display: flex;
            position: relative;
            color: #000;
            text-decoration: none;

            ::before {
              content: "";
              border-bottom: 2px solid red;
              position: absolute;
              width: 100%;
              height: 50%;
              transform: rotate(-20deg);
            }
          }

          &-price {
            font-size: 28px;
            line-height: 28px;
            color: #BAC9DB;
          }

          &-discount {
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            width: 90px;
            padding: 5px 0;
            background: #f1faff;
            border-radius: 110px;
            font-size: 14px;
            line-height: 18px;
            color: #00a3fa;
          }
        }

        &__options {
          ul {
            list-style: disc;
          }

          &-item {
            margin-bottom: 20px;
            font-size: 18px;
            list-style: disc;

            &.mobile {
              font-size: 14px;

              span {
                font-size: 14px;
                margin-left: 10px;
              }
            }

            .option_check {
              display: inline-block;
              align-content: center;
              text-align: center;
              width: 20px;
              height: 20px;
              border-radius: 100%;
              border: 1px solid #00A3FA;

              path {
                stroke: #00A3FA;
              }

              &.business {
                background: #00A3FA;
                color: white;

                path {
                  stroke: white;
                }
              }
            }

            .option_value.business {
              color: #00A3FA;
            }

            span {
              font-size: 16px;
              margin-left: 23px;
            }
          }
        }
      }

      .eco-badge {
        display: flex;
        justify-content: center;
        width: 440px;
        height: 115px;
        background: #F2FAF8;
        border-radius: 8px;
        margin-bottom: 35px;

        &.mobile {
          width: 280px;
        }

        &__content {
          margin: auto;
          display: flex;
        }

        &__math {
          font-size: 18px;
          font-weight: bold;
          line-height: 20px;
          color: #1B3955;
          margin-bottom: 7px;

          &.mobile {
            font-size: 15px;
          }
        }

        &__promise {
          font-size: 16px;
          line-height: 20px;
          color: #919FAF;

          &.mobile {
            font-size: 13px;
          }
        }
      }

      .buynow__slider {
        width: 440px;

        &.mobile {
          width: 280px;

          .slider__item {
            width: 280px;
          }
        }

        .slick-dots {
          bottom: 0;
        }

        .slider__item {
          padding: 0;
          border: none;
          box-shadow: none;
        }

        .slider .slick-dots li button:before {
          color: #DFE7F0;
          opacity: 1;
        }

        .slider .slick-dots li.slick-active button:before {
          color: #00A3FA;
        }
      }
    }
  }

  &__footer {
    .buynow__button {
      width: 277px;
      height: 42px;
      margin-bottom: 10px;

      &.mobile {
        width: 100%;
      }

      p {
        margin: auto;
      }
    }

    &-policy {
      width: 312px;
      font-size: 14px;
      line-height: 18px;
      color: #BAC9DB;

      &.mobile {
        width: 100%;
      }
    }

    &-link {
      span {
        text-decoration: underline;
        color: #8796A8;
      }
    }
  }

  &__promocode {
    min-width: 277px;
    width: 277px;
    padding: initial;
    margin-bottom: 10px;
    color: #BAC9DB;

    &.mobile {
      width: 100%;
    }

    .billing__plan-modal-promocode {
      justify-content: left;
    }

    path {
      stroke: #BAC9DB;
    }
  }

  &__divisor {
    margin-bottom: 50px;
    height: 1px;
    box-sizing: border-box;
    border-bottom: 1px solid #DFE7F0;
  }
}