$block: '.billing';

#{$block} {
  &__switch-block {
    display: flex;
    position: relative;
    min-width: 150px;
    padding: 25px 0 25px 15px;

    border: 1px solid #d7e3e7;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.13);
    border-radius: 13px;

    &-discount {
      position: absolute;
      top: -14px;
    }

    &--active {
      border: 1.5px solid #00a3fa;
      box-shadow: 0px 5px 11px rgba(0, 163, 250, 0.06);
    }
  }

  &__details-section {
    width: 644px;
    &.mobile {
      width: 100%;
    }
  }

  &__spinner {
    display: flex;
    height: 600px;
    justify-content: center;
    align-items: center;
  }

  &__invoice-content {
    height: 350px;
    overflow-y: auto;
  }

  &__invoice-row {
    &:last-child {
      border-bottom: 1px solid #cee0f0 !important;
    }
  }

  &__invoice-spinner {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__invoice-empty {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    color: $grayText;
  }

  &__plans-slider-wrapper {
    position: relative;
    width: 280px;
    height: 220px;
    margin: 20px auto;
    overflow: hidden;
  }

  &__plans-slider {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &-item {
      position: relative;
      transition: all;
      transition-duration: 0.4s;
      width: 280px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #cee0f0;
      border-radius: 3px;
      box-sizing: border-box;
      padding: 40px 33px 57px 33px;
      box-shadow: 0px 4px 12px rgba(45, 60, 74, 0.06);
      transform: translate(-280px, 0);

      &-left {
        position: relative;
        transition: all;
        transition-duration: 0.4s;
        width: 280px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #cee0f0;
        border-radius: 3px;
        box-sizing: border-box;
        padding: 40px 33px 57px 33px;
        box-shadow: 0px 4px 12px rgba(45, 60, 74, 0.06);
      }

      &-right {
        position: relative;
        transition: all;
        transition-duration: 0.4s;
        width: 280px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #cee0f0;
        border-radius: 3px;
        box-sizing: border-box;
        padding: 40px 33px 57px 33px;
        box-shadow: 0px 4px 12px rgba(45, 60, 74, 0.06);
        transform: translate(-560px, 0);
      }
    }

    &-dots {
      position: relative;
      width: max-content;
      height: 30px;
      margin: 10px auto;
      display: flex;
      align-items: center;
    }
    &-dot {
      width: 10px;
      height: 10px;
      margin: 0 12px;
      border-radius: 100px;
      background-color: #a5b3bd;
      &--selected {
        width: 10px;
        height: 10px;
        margin: 0 12px;
        border-radius: 100px;
        background-color: #00a3fa;
      }
    }
  }

  &__plan-modal {
    max-width: 400px;
    padding: 0 25px;

    &.mobile {
      padding: 40px 16px;
    }

    &-title {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      text-align: center;
      color: #1B3955;
    }

    &-subtitle {
      margin-bottom: 27px;
      padding: 0 10px;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #7D8D98;
    }

    &-promocode {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      font-weight: 500;

      &-container {
        min-width: 300px;
        padding: 20px 10px;
      }
    }

    &-input {
      overflow: hidden;
      transition: 0.3s;
      height: 60px;
    }

    &-status {
      &.succeeded {
        color: $green;
      }

      &.failure {
        color: $red;
      }
    }
  }

  &__plan-info {
    background: #eff5f7;
    padding: 22px 22px 22px 27px;
    font-size: 18px;
  }

  &__plan-page {
    padding: 0px 0px 98px 0px;
  }

  &__plan-wrapper {
    margin-bottom: 25px;
  }

  &__plan-title {
    margin-bottom: 5px;
  }

  &__discount {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 130px;
    padding: 5px 0;
    background: #f1faff;
    border-radius: 110px;
    font-size: 14px;
    line-height: 18px;
    color: #00a3fa;
  }

  &__switch {
    margin-right: 14px;
    display: flex;
    align-items: center;

    &-item {
      &:not(:last-child) {
        margin-right: 18px;
      }
    }

    &-text {
      font-family: Circular Std;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: $blueDeep;
    }

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 33px;
      margin-bottom: 28px;
    }

    &-container.left {
      justify-content: start;
      margin-top: 36px;
    }
  }

  &__headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 22px;
    box-sizing: border-box;
  }

  &__appSumo {
    &-inputWrapper {
      position: relative;
      width: 100%;
    }

    &-inputContainer {
      height: 49px;
    }

    &-input {
      border: 1px solid #d7e3e7;
      box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.1);
      border-radius: 50px;
      padding: 9px;
      padding-left: 23px;
      color: #8698a5;
      height: 100%;

      &.failure {
        border: 1px solid $red;
        color: $red;
      }
    }

    &-button {
      position: absolute;
      right: 11px;
      top: 7px;
      padding: 7px 14px;
      min-height: 33px;
      font-size: 14px;
      line-height: 18px;
    }

    &--bold {
      font-weight: bold;
      color: #2d3c4a;
    }

    &-title {
      font-family: Circular Std;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      color: #2d3c4a;
      text-align: center;
    }

    &-slider_wrapper {
      position: relative;
      left: -5%;
      width: 120%;
      height: 200px;
      overflow: hidden;
    }

    &-slider {
      position: absolute;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-item {
        &--left {
          position: relative;
          transition: all;
          transition-duration: 1s;
          width: 350px;
          height: 100%;
          margin: 0 10px;
          box-sizing: border-box;
          border: 1px solid #cee0f0;
          box-shadow: 0px 4px 12px rgba(45, 60, 74, 0.06);
          border-radius: 3px;
          transform: translate(0, 0);
        }
        &--right {
          position: relative;
          transition: all;
          transition-duration: 1s;
          width: 350px;
          height: 100%;
          margin: 0 10px;
          box-sizing: border-box;
          border: 1px solid #cee0f0;
          box-shadow: 0px 4px 12px rgba(45, 60, 74, 0.06);
          border-radius: 3px;
          transform: translate(-105%, 0);
        }
      }

      &-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
      }
      &-dot {
        width: 10px;
        height: 10px;
        margin: 0 10px;
        border-radius: 50px;
        background-color: #a5b3bd;

        &--selected {
          width: 10px;
          height: 10px;
          margin: 0 10px;
          border-radius: 50px;
          background-color: #00a3fa;
        }
      }
    }
  }

  &__modal {
    width: 400px;
    padding: 35px 52px 41px 53px;

    &-title {
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #1b3955;
      margin-bottom: 30px;
    }

    &-description {
      font-weight: normal;
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: $caution;
    }

    &-button {
      display: flex;
      justify-content: center;
    }

    &-warning {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  &__modal-mobile {
    width: 170px;
    padding: 35px 32px 31px 29px;
  }

  &__cell {
    &--amount {
      margin-right: 11px;
    }

    &--download {
      cursor: pointer;
    }
  }

  &__column {
    margin-right: auto;
    &--date {
      width: 167px;
      margin-left: 12px;
    }

    &--amount {
      width: 120px;
    }
  }

  &__current-plan {
    width: 100%;
    padding: 11px 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #00a3fa;
    display: flex;
    justify-content: center;
    background: #f1faff;
    border-radius: 110px;

    &.renew {
      cursor: pointer;
    }
  }

  &__table {
    &-icon {
      width: 20px;
      height: 20px;
    }

    &-title {
      font-size: 24px;
      line-height: 22px;
      text-align: center;
      color: $blueDeep;
      margin-bottom: 8px;
    }

    &-row {
      padding: 0;
      align-items: stretch;

      &--borderless {
        border-bottom: none !important;
      }
    }

    &-column {
      display: flex;
      flex: 1;
      min-width: 179px;
      padding: 17px 0px 20px 20px;
      border-right: 1px solid #cee0f0;
      box-sizing: border-box;

      &.mobile {
        padding: 17px 0px 20px 10px;
        min-width: 140px;
      }

      &:last-child {
        border-right: none;
      }

      &--name {
        flex: 0 1 199px;
        min-width: 199px;
      }

      &--header {
        padding: 46px 20px 47px 20px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }

    &--description {
      text-align: center;
      margin-bottom: 23px;
    }

    &-button {
      width: 100%;
      display: flex;
      justify-content: center;

      & .button {
        max-width: 180px;
        width: 100%;
      }
    }
  }

  &__table-wrapper {
    min-width: max-content;
    margin-bottom: 20px;
  }

  &__table-container {
    min-width: 644px;
    width: 100%;

    @media (max-width: 768px) {
      min-width: 0;
    }
  }

  &__plan {
    &-header {
      margin-bottom: 3px;
      font-size: 14px;
      line-height: 18px;
    }

    &-button {
      .button {
        width: 140px;
        display: flex;
        justify-content: center;
      }

      &.mobile {
        margin-bottom: 23px;
        button {
          width: 100%;
        }
      }
    }

    &-total-price {
      margin-bottom: 0px;
    }

    &-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--head {
        margin-bottom: 23px;
      }

      &--price {
        margin: auto 0;
      }

      &.total {
        margin-bottom: 30px;
      }

      &--next-invoice {
        font-size: 16px;
      }
    }

    &-modal {
      &.buttons {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        padding: 0;

        &.left {
          width: 100%;
          max-width: 440px;
          box-sizing: border-box;
          justify-content: start;
        }
        &.mobile {
          justify-content: center;
        }

        & button {
          width: 140px;
          justify-content: center;

          &.mobile {
            width: 100%;
          }
        }
      }

      &.footer {
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 50px;
        padding: 35px 0;
        font-size: 14px;
        line-height: 22px;
        color: $grayText;
        border-top: none;

        a {
          color: $grayDarker;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__header {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 25px;
  }

  &--free-plan {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;

    &.mobile {
      flex-direction: column;
      align-items: initial;

      .billing__plan-description {
        margin-bottom: 20px;
      }
    }
  }

  &--no-discount {
    padding-bottom: 31px;
  }

  &__details {
    margin-top: 20px;
    &-header {
      margin-bottom: 13px;
    }

    &-field {
      min-width: 470px;
      width: 644px;

      &.mobile {
        width: 100%;
        min-width: 288px;
      }
    }

    &-description {
      margin-bottom: 18px;
      width: 344px;

      &.mobile {
        width: 100%;
      }
    }

    &-text {
      line-height: 18px;
    }

    &-block {
      width: 579px;
      max-height: 125px;
      box-sizing: border-box;
      overflow-y: auto;
      word-break: break-all;
      padding: 12px 44px 34px 16px;
      background: #ffffff;
      border: 1px solid #d7e3e7;
      box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.1);
      border-radius: 3px;
      margin-bottom: 33px;
    }

    &-button {
      &-group {
        display: flex;
        justify-content: flex-start;
      }

      &-update .button {
        width: 100px;
        display: flex;
        justify-content: center;
      }

      &--first-item {
        margin-right: 15px;
      }

      & .button {
        &.button--disabled {
          color: $grayDark;
          background-color: transparent;
        }

        width: 213px;
        display: flex;
        justify-content: center;
      }
    }
  }

  &__trial {
    &-row {
      display: flex;
      color: $grayText;
      padding: 0;
      align-items: stretch;
      margin-bottom: 20px;
    }

    &-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 179px;
      padding: 15px 5px 20px 15px;
      box-sizing: border-box;
      border: none;
      line-height: 1.5;

      &:first-child {
        border: 1px solid #cee0f0;
        border-radius: 5px;
      }
    }

    &-header {
      font-size: 18px;
      font-weight: 700;
      color: $blueDeep;

      &-subheader {
        font-size: 14px;
        font-weight: 450;
        color: $blueDeep;
      }
    }

    &-attached-card {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    &-attach-new-card-button {
      width: 100%;
      margin-bottom: 20px;
      border-radius: 7px;
    }

    &-continue-free-button {
      cursor: pointer;
      color: #5a7386;
      font-size: 16px;
      font-weight: 450;
      border-bottom: 2px solid #5a7386;
      line-height: 25px;
      margin-top: 15px;

      @media (max-width: $xl-width) {
        text-align: center;
        border-bottom: initial;
      }
    }
  }

  &__planCost {
    &__container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > :not(:first-child) {
        margin-left: 5px;
      }

      > :last-child {
        margin-left: 10px;
      }

      @media (max-width: $xl-width) {
        flex-direction: column;
        align-items: initial;

        > :not(:first-child) {
          margin-left: 0px;
          width: 85px;
        } 

        > :last-child {
          margin-left: 0px;
          width: fit-content;
        }
      }
    }

    &--default {
      color: #BAC9DB;
    }
    
    &--current {
      color: #0B2034;
    }
  }

  &__discountBillet {
    &__container {
      width: fit-content;
    }

    &__text {
      font-size: 12px;
      color: #02C39A;
      border-radius: 110px;
      width: 100%;
      padding: 3px 6px;
      text-align: center;
      background-color: #DFF2F2;
    }
  }

  &__tier {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: left;

      &.mobile {
        align-items: center;
      }
    }

    &-container {
      display: flex;
      max-width: 580px;
      width: 580px;
      height: 250px;
      border: 1px solid #CEE0F0;
      border-radius: 3px;
      box-shadow: 0px 4px 12px rgba(#2D3C4A, 0.06);

      &.mobile {
        flex-direction: column;
        width: 100%;
        height: 500px;
      }
    }

    &-label {
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 10px;
    }

    &-left {
      width: 50%;
      padding: 0px 30px;
      margin: 30px 0px;
      border-right: 1px solid #CEE0F0;

      &.mobile {
        width: initial;
        height: 50%;
        padding: 20px 0px;
        margin: 0px 20px;
        border-right: initial;
        border-bottom: 1px solid #CEE0F0;
      }

      &__title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #CEE0F0;

        &__title {
          font-size: 24px;
          font-weight: 500;
          line-height: 26px;
        }

        &__plan-billet {
          &.billing__current-plan {
            font-size: 14px;
            width: 100px;
            height: 10px;
            align-items: center;
          }
        }
      }

      &__plan-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;

        &__plan-name {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #7D8D98;

          b {
            color: $blueDeep;
          }
        }

        &__button {
          width: 100%;
        }
      }
    }

    &-right {
      width: 50%;
      margin: 30px;

      &.mobile {
        width: initial;
        height: 50%;
      }

      &__title {
        font-weight: 500;
        line-height: 16px;
        color: #526172;
        margin-bottom: 15px;
      }

      &__options {
        &-item {
          line-height: 16px;
          color: #7D8D98;
          margin-bottom: 10px;
        }
      }
    }

    &-table {
      &__header {
        &-container{
          background-color: #EFF5F7;
          border-bottom: 1px solid #CEE0F0;
        }
      }

      &__firstColumn {
        min-width: 210px;
        padding: 20px 10px;
      }

      &__column {
        min-width: 88px;
        justify-content: center;
        align-items: center;
        padding: 10px;

        &--header {
          padding: 20px 10px;
        }
      }
    }

    &-slider {
      &-item {
        position: relative;
        transition: all;
        transition-duration: 0.4s;
        width: 280px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #cee0f0;
        border-radius: 3px;
        box-sizing: border-box;
        padding: 40px 33px 57px 33px;
        box-shadow: 0px 4px 12px rgba(45, 60, 74, 0.06);

        &.selector {
          padding: 20px
        }

        &.tier1 {
          transform: translate(0, 0);
        }

        &.tier2 {
          transform: translate(-280px, 0);
        }

        &.tier3 {
          transform: translate(-560px, 0);
        }

        &.tier4 {
          transform: translate(-840px, 0);
        }

        &.tier5 {
          transform: translate(-1120px, 0);
        }

        &.tier6 {
          transform: translate(-1400px, 0);
        }
      }
    }
  }
}
