$block: '.sidebar';

#{$block} {
  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &--expanded {
      #{$block}#{__item-trigger} {
        color: $blue;
        &--fill {
          #{$block}#{__item-trigger-icon} {
            & svg path {
              fill: $blue;
            }
          }
        }
        &--stroke {
          #{$block}#{__item-trigger-icon} {
            & svg path {
              stroke: $blue;
            }
          }
        }
      }
      #{$block}#{__item-trigger-arrow} {
        transition: all 0.2s ease;
        transform: rotate(0deg);
        & svg path {
          stroke: $blue;
        }
      }
    }
    &-trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $caution;
      &:hover {
        cursor: pointer;
        color: $blue;
      }
      &--fill {
        &:hover {
          #{$block}#{__item-trigger-icon} {
            & svg path {
              fill: $blue;
            }
          }
        }
      }
      &--stroke {
        &:hover {
          #{$block}#{__item-trigger-icon} {
            & svg path {
              stroke: $blue;
            }
          }
        }
      }
      &-inner {
        display: flex;
        align-items: center;
      }
      &-icon {
        margin-right: 13px;
        & > div {
          display: flex;
        }

        &.gray {
          svg {
            width: 17px;
            height: 17px;
            path {
              stroke: $grayText;
            }
          }
        }
      }
      &-arrow {
        & > div {
          display: flex;
        }
        transition: all 0.2s ease;
        transform: rotate(-90deg);
      }
    }
    &-link {
      color: $caution;
      transition: all 0.2s ease;
      &:hover {
        color: $blue;
        transition: all 0.2s ease;
      }
      &:not(:last-child) {
        margin-bottom: 9px;
      }
      &--active {
        color: $blue;
      }
      &-status {
        display: flex;
        align-items: center;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -14px;
          width: 6px;
          height: 6px;
          background-color: transparent;
          border-radius: 100%;
        }
        &--completed {
          &:before {
            background-color: #6bd984;
          }
        }
        &--awaiting {
          &:before {
            background-color: $orange;
          }
        }
        &--draft {
          &:before {
            background-color: $grayBlue;
          }
        }
        &--received {
          &:before {
            background-color: $blue;
          }
        }
        &--trash {
          color: $red;
          &:before {
            background-color: $red;
          }
        }
        
        &--declined {
          &:before {
            background-color: $red;
          }
        }
      }
      &-list {
        display: flex;
        flex-direction: column;
        padding: 12px 0 0 30px;
        line-height: 23px;
      }
    }
  }
}
