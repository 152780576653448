$block: '.upgradeModal';

#{$block} {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 550px;
  min-height: 270px;

  &.mobile {
    min-width: 310px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 0em;

    font-family: Circular Std;
    text-align: center;

    margin-bottom: 20px;
  }

  &__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;

    margin-bottom: 10px;
  }

  &__text {
    font-size: 14px;
    line-height: 22px;
  }

  &__button {
    &--cancel {
      margin-top: 10px;
      color: $gray;
      cursor: pointer;

      &:hover {
        cursor: pointer;
        color: $grayLight;
        transition: all 0.3s ease;
      }

      &:active {
        color: $grayDark;
      }
    }
  }
}