$block: '.moveToFolderModal';

#{$block} {
  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;

  &--transparent {
    background-color: transparent;
  }

  &__close-button {
    position: absolute;
    z-index: 10;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  &__wrapper {
    margin: -20px;
    width: 500px;

    &.mobile {
      width: 100%;
      margin: initial;

      *::-webkit-scrollbar {
        width: 0;
      }
    }
  }

  &__header {
    padding: 18px 20px;
    text-align: left;
    background-color: #f7f9fc;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #1b3955;
    margin-bottom: 2px;
  }

  &__subTitle {
    margin-top: 18px;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 22px;
    color: $caution;

    &--center {
      text-align: center;
    }
  }
  &__content {
    height: 250px;
    overflow: auto;
  }

  &__itemWrapper {
    padding: 0 25px;
    &:hover {
      background-color: $blue;
      border-bottom: $blue;
      & div {
        color: white;
      }
      & svg path {
        stroke: white;
      }
    }
    &-hover {
      background-color: $blue;
      border-bottom: $blue;
      & div {
        color: white;
      }
      & div {
        & div {
          & svg path {
            stroke: white;
          }
        }
      }
    }
  }

  &__skipDots {
    position: relative;
    display: flex;
    width: 29px;
    height: 29px;
    background: $grayExtraLight;
    border-radius: 100%;

    img {
      margin: auto;
    }
  }

  &__hiddenFolder {
    position: absolute;
    top: 20px;
    left: -50px;
    width: 256px;
    border: 1px solid #dfeefc;
    box-shadow: 0px 5px 26px #3587b6 8%;
    background-color: white;
    border-radius: 4px;
    padding: 22px;

    &--item {
      :hover {
        cursor: pointer;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #cee0f0;
      }
    }
  }

  &__item {
    padding: 17px 0;
    border-bottom: 1px solid #cee0f0;
    color: $grayText;
    display: flex;
    cursor: default;

    &-icon {
      margin-right: 8px;

      & div {
        display: flex;

        & svg path {
          stroke: rgb(0, 163, 250);
        }
      }
    }

    p {
      max-width: 350px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.hiddenFolder {
      border-bottom: none;
      p {
        margin: initial;
        max-width: 214px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &::after {
      display: block;
      content: ' ';
      background-image: url('../../../images/icons/arrow-right.svg');
      background-size: 25px 12px;
      height: 12px;
      width: 20px;
      margin: auto 0;
    }
  }

  &__controls {
    padding: 20px 25px 25px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--icon {
      cursor: pointer;
    }
  }

  &__breadcrumbsWrapper {
    padding: 0 25px;
  }

  &__breadcrumbsInner {
    display: flex;
    padding: 17px 0;
    border-bottom: 1px solid #cee0f0;
    > :last-child {
      color: $blue;

      &::after {
        display: none;
      }
    }
  }

  &__breadcrumbsItem {
    margin-right: 5px;
    display: flex;
    &:not(:last-child) {
      color: $blue;
      cursor: pointer;
    }
    &:last-child {
      color: $blueDeep;
      cursor: pointer;
    }
    &::after {
      display: block;
      content: ' ';
      background-image: url('../../../images/icons/arrow-right.svg');
      background-size: 25px 14px;
      height: 14px;
      width: 20px;
      margin: auto 0;
    }
    p {
      margin: auto;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
