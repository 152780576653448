$block: '.downloadModal';

#{$block} {
  width: 600px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 38px 50px;
  }

  &__header {
    text-align: left;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 20px;
    margin-bottom: 12px;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 22px;
    color: $grayText;
  }

  &__zip-wrapper {
    margin: 0 -20px;
    padding: 0 20px 40px;
    max-height: 230px;
    overflow-y: auto;
  }

  &__buttons-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__cancel {
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    color: $grayDark;
  }

  &__list {
    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:not(:last-child) {
        padding-bottom: 10px;
        border-bottom: 1px dashed #d7e3e7;
        margin-bottom: 30px;
      }

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }

  &__label {
    min-width: 120px;
    margin-right: 10px;
  }
}
