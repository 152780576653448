$block: '.profile';

#{$block} {
  &__header {
    margin-bottom: 10px;
  }

  &__two-factor-block {
    padding-bottom: 25px !important;
  }

  &__google-recaptcha {
    margin-bottom: 5px;
  }

  &__modal {
    padding: 36px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.mobile {
      width: initial;
    }

    &-title {
      color: #1b3955;
      width: 288px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 14px;
    }

    &-description {
      width: 288px;
      color: $caution;
      text-align: center;
      margin-bottom: 36px;

      &--downgrade {
        width: 360px;

        &.mobile {
          width: 288px;
        }
      }

      &--single {
        margin-bottom: 14px;
      }

      &--email {
        margin-bottom: 20px;
      }

      &--appsumo {
        width: inherit;
        margin-bottom: inherit;
      }

      a {
        color: $blueLight;
      }

      span {
        font-weight: bold;
      }
    }

    &-button {
      margin-right: 11px;

      &:last-child {
        margin-right: unset;
      }

      &-wrapper {
        margin-bottom: 15px;

        &.mobile {
          padding: 0 10px;
        }

        &--group {
          display: flex;
          justify-content: space-between;
        }

        &.delete button {
          margin: 10px auto;
        }
      }

      &--delete {
        cursor: pointer;
        color: #b5bdc6;
      }
    }

    &-recaptcha-wrapper {
      margin-bottom: 20px;
    }

    &.appsumo {
      padding: 18px 20px;
    }
  }

  &__sms {
    &-wrapper {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 5px;
    }

    &-recaptcha-wrapper {
      margin-bottom: 5px;
    }

    &-disable {
      margin-bottom: 17px;

      & .button--disabled {
        color: $grayDark;
        background-color: transparent;
      }
    }

    &-change {
      cursor: pointer;
      font-size: 14px;
      line-height: 23px;
      background-color: transparent;
      text-decoration-line: underline;
      color: #00a3fa;
    }

    &-field {
      width: 310px;

      &:not(:last-child) {
        margin-right: 12px;
      }

      & .form__field {
        margin-bottom: 0;
      }

      &--small {
        width: 70px;
      }
    }
  }

  &__auth {
    margin-bottom: 25px;

    &-description {
      margin-bottom: 19px;
    }
  }

  &__button {
    & .button {
      display: flex;
      justify-content: center;
    }

    &--import {
      margin-bottom: 8px;
      & .button {
        width: 210px;
      }
    }

    &--delete {
      margin-bottom: 8px;
      & .button {
        width: 210px;
        color: $red;
        border-color: $red;

        &:hover {
          background-color: $red;
          color: #fff;
        }
      }
    }

    &--setup {
      & .button {
        width: 160px;

        &.button--disabled {
          color: $grayDark;
          background-color: transparent;
        }
      }
    }

    &--verify .button {
      width: 150px;
      &.button--disabled {
        color: $grayDark;
        background-color: transparent;
      }
    }

    &--save .button {
      width: 180px;
    }

    &.mobile {
      margin-top: 10px;
    }
  }

  & .uiCheckbox__wrapper {
    align-items: flex-start;
    & .uiCheckbox__inner {
      margin-top: 2px;
    }
  }

  &__verify-form {
    border: none !important;
    box-shadow: none !important;
  }

  &__google-form {
    border: none !important;
    box-shadow: none !important;
    padding: 10px;
  }
  
  &__email {
    color: #2d3c4a;
    font-weight: 500;
  }
  
  &__email-modal {
    width: 440px;

    &.mobile {
      width: initial;
      margin: auto;
    }
  }

  &__email-button {
    margin-bottom: 20px;
  }

  &__email-modal-button {
    display: flex;
    justify-content: center;
    min-width: 165px;
  }

  &__email-lock {
    &-icon {
      position: absolute;
      left: 375px;
      top: 39px;

      &.mobile {
        left: 259px;
      }

      & svg path {
        stroke: #505F6B;
        stroke-width: 1.8;
      }
    }
  }
}
