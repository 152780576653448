$block: '.bulkSendModal';

#{$block} {
  min-width: 440px;
  padding: 22px 7px 0 7px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .form__label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: $blueDeep;
  }

  &__select {
    width: 100%;
  }

  &__button {
    width: 177px;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__bottom-select {
    margin-bottom: 4px;
  }

  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 5px;
    color: #1b3955;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 400;
    width: 330px;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 18px;
    text-align: center;
    color: $caution;
  }

  &__dropdown {
    max-height: 215px;
    overflow-y: auto;
  }
}
