$block: '.sign-up-landing';

#{$block} {
  &__wrapper {
    width: 100%;
    height: 100%;
    align-content: center;
  }

  &__upload {
    &-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 745px;
      border: 1px solid $grayLight;
      border-radius: 16px;
      box-shadow: 0px 4px 24px rgba(#0B2034, 0.04), 0px 7px 14px rgba(#00A3FA, 0.06);
      margin: auto;
    }

    &-wrapper {
      padding: 64px;
    }

    &-header {
      font-size: 30px;
      font-weight: 900;
      line-height: 40px;
      color: $blueDeep;
      margin-bottom: 20px;
    }
  }

  &__signUp {
    &-form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;
      height: 840px;
      margin: auto;

      &.mobile {
        flex-direction: column;
      }
    }

    &-info {
      &-container {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        margin-right: 20px;
      }

      &-header {
        font-size: 30px;
        font-weight: 900;
        line-height: 40px;
        color: $blueDeep;
        margin-bottom: 20px;
      }

      &-text {
        font-size: 16px;
        line-height: 27px;
        color: #526172;
      }
    }

    &-content {
      &-container {
        max-width: 480px;
        border: 1px solid $grayLight;
        border-radius: 16px;
        box-shadow: 0px 4px 24px rgba(#0B2034, 0.04), 0px 7px 14px rgba(#00A3FA, 0.06);
      }

      &-label {
        font-size: 14px;
        font-weight: 500;
        color: #2D3C4A;
      }

      &-coupon-container {
        margin-bottom: 97px;

        &.open {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__confirm {
    &-form {
      display: flex;
      justify-content: center;
      width: 80%;
      height: 840px;
      margin: auto;

      &.mobile {
        flex-direction: column;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 745px;
      border: 1px solid $grayLight;
      border-radius: 16px;
      box-shadow: 0px 4px 24px rgba(#0B2034, 0.04), 0px 7px 14px rgba(#00A3FA, 0.06);
      margin: auto 120px;
      padding: 84px 64px;
      text-align: center;
      align-items: center;
    }

    &-header {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      color: $blueDeep;
      margin-bottom: 40px;
    }

    &-text {
      font-size: 16px;
      line-height: 20px;
      color: $grayText;
      margin-bottom: 15px;

      b {
        color: $blueDeep;
      }
    }

    &-button {
      &--download {
        width: 170px;
        margin: 20px 0px;
      }

      &--cancel {
        cursor: pointer;
        color: #b5bdc6;
      }
    }
  }
}