$block: '.team';

#{$block} {
  &__member {
    display: flex;
    align-items: center;
  
    &-name {
      width:100px;
      word-break: break-word;

      &.mobile {
        width: 100%;
        font-size: 16px;
        color: $blueDeep;
      }
    }

    &-title {
      max-width: 170px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    &.mobile {
      height: 65px;
      padding-right: 15px;
    }
  }

  &__header {
    &-container {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #1b3955;
      margin-bottom: 7px;
      margin-right: 8px;
    }

    &-description {
      width: 450px;
      font-size: 14px;
      line-height: 22px;
      color: $grayText;

      &.mobile {
        width: 100%;
      }
    }
  }

  &__button {
    &--hide {
      display: none !important;
    }

    & .button {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &.mobile {
      margin: 20px 0;
    }
  }

  &__controlsGroup {
    justify-content: flex-end;
  }

  &__column {
    &-member {
      width: 150px;

      &.mobile {
        width: 58px;
      }
    }

    &-actions {
      width: 30px;
    }
  }

  &__accept {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    border: 1px solid #cee0f0;
    box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.16);
    border-radius: 3px;
    padding: 30px 25px;

    &-title {
      text-align: center;
      font-size: 16px;
      font-weight: 450;
      margin-bottom: 20px;
    }

    &-button {
      display: flex;
      justify-content: center;
      & .button {
        justify-content: center;
        display: flex;
        min-width: 240px;
      }
    }

    &-wrapper {
      flex-direction: column;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 970px;
    }
  }
}
