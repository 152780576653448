$block: '.common';

#{$block} {
  &__or {
    text-align: center;
    margin: 25px 0;
    color: #8792a6;
    display: flex;
    align-items: center;
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      margin-left: 30px;
      background: #e4e9f1;
    }
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      margin-right: 30px;
      background: #e4e9f1;
    }
  }
}
