$block: '.document-sign';

#{$block} {
  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #f1f4f6;
    padding-top: 40px;

    @media (max-width: $lg-width) {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 12px;
    }

    @media (max-width: $sm-width) {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 8px;
    }

    .next-btn-mobile {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 44px;
      background: #fff;
      z-index: 10;
      border-top: 1px solid rgba(17, 84, 122, 0.16);
      border-bottom: 1px solid rgba(17, 84, 122, 0.16);
      justify-content: center;

      p {
        margin: auto;
        color: #00a3fa;
      }

      @media (min-width: $sm-width) {
        display: none;
      }
    }

    .decline-btn-mobile {
      display: flex;
      position: absolute;
      bottom: 44px;
      left: 0;
      width: 100%;
      height: 44px;
      background: #fff;
      z-index: 10;
      box-shadow: 0px -2px 34px rgba(17, 84, 122, 0.16);
      justify-content: center;

      p {
        margin: auto;
        color: $red;
      }

      @media (min-width: $sm-width) {
        display: none;
      }
    }
  }

  &__dots {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 23px;

    & > li {
      background-color: #8aa2af;
      border-radius: 50%;
      width: 4px;
      height: 4px;

      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }

  &__content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 83px;
    background-color: #edf2f5;
    padding: 0 45px;

    @media (max-width: $lg-width) {
      height: 74px;
      padding-right: 20px;
      padding-left: 30px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    @media (max-width: $sm-width) {
      height: 60px;
      padding: 0 18px;
    }

    &-item {
      display: flex;
      align-items: center;
      font-family: Circular Std;
      font-style: normal;

      &-text {
        font-weight: 450;
        font-size: 16px;
        line-height: 22px;
        color: $grayText;
        margin-right: 3px;
      }

      button {
        p {
          margin: 0 auto;
        }

        @media (max-width: $lg-width) {
          width: 160px;
        }

        @media (max-width: $sm-width) {
          display: none;
        }
      }
    }

    &-scale {
      margin-left: auto;
      margin-right: 20px;
    }

    &-counter {
      display: flex;
      align-items: center;
      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: $grayText;
    }

    &-count {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;

      &-wrapper {
        display: flex;
        margin-left: 6px;
        background-color: $blue;
        border-radius: 12px;
        padding: 1px 8px 1px 7px;
      }
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    border-radius: 12px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    box-shadow: 0 4px 34px rgba(17, 84, 122, 0.16);
    max-width: 100%;

    @media (max-width: $lg-width) {
      // margin-left: 0;
      // margin-right: 0;
      flex: 0;
    }
  }

  &__content {
    overflow: auto;
    scroll-behavior: smooth;
    max-height: calc(100vh - 245px);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    flex-grow: 1;
  }

  &__button {
    &--decline {
      margin-right: 10px;
    }
  }
}
