$block: '.auth';

#{$block} {
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 970px;

  &__form {
    padding: 45px 40px;
    width: 386px;
    box-sizing: border-box;
    &--signup {
      width: 480px;

      @media (max-width: $sm-width) {
        width: 100%;
        padding: 20px 16px;
      }
    }
    &--border {
      border: 1px solid #cee0f0;
      box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.16);
      border-radius: 3px;

      &.mobile {
        border: none;
        box-shadow: none;
      }
    }
    &--inbox {
      padding: 73px 70px;
    }

    &.mobile {
      padding: 10px 16px 45px;
      width: 320px;
    }
  }

  &__submitButton {
    display: flex;
    justify-content: center;

    & .button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__googleButton-wrapper {
    margin-top: 36px;
    display: flex;
    justify-content: center;
  }

  &__googleButton {
    background-color: #fff;
    color: $blueDeep;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 110px;
    align-self: center;
    text-align: center;
    align-items: center;
    min-height: 40px;
    height: 40px;
    vertical-align: baseline;
    line-height: 19px;
    padding: 0 22px 0 21px;
    transition: all 0.2s ease;
    position: relative;
    border: 1px solid;
    border-color: #a5b3bd;
    &:hover {
      color: $blue;
      border-color: $blue;
      box-shadow: 0px 4px 10px rgba(0, 163, 250, 0.28);
    }

    &:active {
      box-shadow: 0px 4px 10px rgba(0, 163, 250, 0.28);
    }
  }

  &__separator {
    margin: 40px 0;
  }
  &__check-account {
    margin-top: 21px;
    display: flex;
    justify-content: center;
    color: $grayDark;
  }
  &__link {
    cursor: pointer;
    color: #00a3fa;
    &--center {
      display: flex;
      justify-content: center;
    }
  }

  &__hr {
    border: 1px solid #e4e9f1;
    margin-top: 26px;
  }

  &__title {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1b3955;
    margin-bottom: 14px;

    &--bold {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }

    &--subtitle {
      font-weight: inherit;
      font-size: 14px;
      color: $grayText;
      margin-bottom: -10px;
    }

    &.mobile {
      margin: 30px 0 20px;
    }
  }

  &__description {
    width: 250px;
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: $grayDark;
  }

  & .form__label {
    color: $blueDeep;
  }

  &__field {
    margin-bottom: 20px;
    & .form__field {
      margin-bottom: 3px;
    }
  }

  & .recaptcha__wrapper {
    margin-bottom: 20px;
  }

  &__forgot {
    display: flex;
    justify-content: flex-end;
  }

  &__acception {
    margin-top: 15px;
    margin-bottom: 16px;
    text-align: center;
    font-size: 11px;
    color: $grayDark;
  }
}
