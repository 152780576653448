$block: '.apply-modal';

#{$block} {
  max-width: 550px;
  padding: 50px 85px 75px;
  border-radius: 4px;

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 21px;
    color: #1b3955;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 33px;
    color: #7d8d98;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    button {
      width: 140px;
    }
  }
}
