$block: '.form-request';

#{$block} {
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 970px;
  
    &__thanksMessage{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0.2;
    }

    &__content{
      width: 100%;
      display: flex;
      flex: 1 1;
      justify-content: center;
      align-items: center;
    }

    &__form {
      padding: 45px 40px;
      width: 560px;
      box-sizing: border-box;

      &.mobile {
        width: 288px;
      }

      &--signup {
        width: 480px;
  
        @media (max-width: $sm-width) {
          width: 100%;
          padding: 20px 16px;
        }
      }
      &--border {
        border: 1px solid #cee0f0;
        box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.16);
        border-radius: 3px;
      }
      &--inbox {
        padding: 73px 70px;
      }
    }
  
    &__submitButton {
      display: flex;
      justify-content: center;
  
      & .button {
        width: 180px;
        display: flex;
        justify-content: center;
      }
    }
  
    &__separator {
      margin: 40px 0;
    }

    &__link {
      cursor: pointer;
      color: #00a3fa;
      text-align: center;
    }
  
    &__hr {
      border: 1px solid #e4e9f1;
      margin-top: 26px;
    }
  
    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #1b3955;
      margin-bottom: 14px;
      text-align: center;
      word-break: break-all;
      &--bold {
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
      }
    }

    &__subtitle {
      margin-bottom: 16px;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: $grayDark;
      word-break: break-all;
    }

    &__documentTitle {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #1b3955;
      margin-bottom: 14px;
      text-align: center;
      word-break: break-all;
      &--bold {
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
      }
    }
  
    &__documentMessage {
      margin-bottom: 16px;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: $grayDark;
      text-align: center;
      word-break: break-word;
    }
  
    & .form__label {
      color: $blueDeep;
    }
  
    &__field {
      margin-bottom: 20px;
      & .form__field {
        margin-bottom: 3px;
      }
    }
  
    &__footer{
      display: flex;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #7B94B1;
      justify-content: center;
      border-top: 1px solid rgba(209, 219, 226, 0.5);
      padding:20px 0;
      background-color: #F0F6FA;
      &--signaturely{
        color: #66778e;
        padding-left: 3px;
      }
    }

    &__logo{
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
    }
  }
  