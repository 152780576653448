$block: '.dropDownUser';

#{$block} {
  &__wrapper {
    position: relative;
  }

  &__trigger {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #00a3fa;
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      border-radius: 100%;
      margin-right: 8px;
      box-shadow: 0px 2px 6px rgba(5, 37, 94, 0.17);

      & span {
        text-align: center;
        color: white;
        user-select: none;
      }
    }

    &--send-reminder {
      flex-direction: column;
      align-items: baseline;
    }

    &-arrow {
      transition: all 0.2s ease;
      margin-left: 5px;
      transform: rotate(-90deg);

      & > div {
        display: flex;
      }

      &--open {
        transform: rotate(0deg);
      }
    }
  }
  &__list {
    position: absolute;
    width: 100%;
    top: 40px;
    right: -30px;
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(62, 157, 210, 0.25);
    border-radius: 4px;
    padding: 25px 22px 25px;
    z-index: 9;
    min-width: 185px;
    box-sizing: border-box;

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      right: 25px;
      display: inline;
      border: 10px solid white;
      border-top-width: 0;
      border-right-color: transparent;
      border-left-color: transparent;
      filter: drop-shadow(0 -10px 7px rgba(53, 135, 182, 0.24));
    }
  }
  &__item {
    display: flex;
    color: $caution;
    &:hover {
      cursor: pointer;
      color: $blue;
    }
    &--stroke {
      &:hover {
        & svg path {
          stroke: $blue;
        }
      }
    }
    &--fill {
      &:hover {
        & svg path {
          fill: $blue;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      margin-right: 12px;
      & > div {
        display: flex;
      }
    }
  }
}
