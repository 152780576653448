$block: '.apiKeyModal';

#{$block} {
  max-height: 720px;
  padding: 50px 80px;

  &__wrapper {
    display: flex;
    min-width: 390px;
    flex-direction: column;
  }

  &__description {
    max-width: 390px;
  }

  &__gap {
    margin-bottom: 30px;
  }

  &__small-gap {
    margin-bottom: 18px;
  }

  &__value {
    background: #F3F7F8;
    border-radius: 3px;
    padding: 12px 0 12px 18px;
    margin-bottom: 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $grayDark;
  }
}