$block: '.guest-layout';

#{$block} {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  flex: 1;

  &__header {
    display: flex;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
    min-height: 81.5px;
    max-height: 81.5px;
    padding: 0 84px;
    background-color: #ffffff;

    @media (max-width: $lg-width) {
      padding: 0 24px;
      max-height: 64px;
      min-height: 64px;
    }

    @media (max-width: $sm-width) {
      padding: 0 16px;
      max-height: 43px;
      min-height: 43px;
    }

    &--border {
      border-bottom: 1px solid rgba(209, 219, 226, 0.5);
    }

    &-item {
      display: flex;
      align-items: center;

      > #{$block}#{__header}#{-item} {
        &:not(:last-child) {
          margin-right: 23px;
        }
      }

      &-text {
        font-family: Circular Std;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $grayText;

        @media (max-width: $lg-width) {
          display: none;
        }
      }

      .button {
        @media (max-width: $lg-width) {
          width: 220px;
          min-height: 42px;
          font-size: 14px;
        }

        @media (max-width: $sm-width) {
          width: 177px;
          min-height: 31px;
          font-size: 12px;
        }

        p {
          margin: 0 auto;
        }
      }

      &.logo {
        @media (max-width: $sm-width) {
          display: none;
        }
      }

      &.logo--mobile {
        img {
          width: 41px;
          height: 31px;
        }

        @media (min-width: $sm-width) {
          display: none;
        }
      }
    }
  }

  & .footer {
    min-height: 50px;
  }
}
