$block: '.redeemCodeModal';

#{$block} {
  min-width: 460px;
  min-height: 320px;
  padding: 40px 56px;
  font-family: Circular Std;
  font-style: normal;
  border: 1px solid #CEE0F0;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(#0E6494, 0.09);

  &.mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    min-width: 310px;
    padding: 30px 20px 20px;
    border: inherit;
    border-radius: inherit;
    box-shadow: inherit;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
  }

  &__header {
    &-container {
      text-align: center;
      margin-bottom: 20px;

      > :not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 34px;
      color: $blueDeep;

      &.mobile {
        font-size: 12px;
        line-height: 14px;
      }
    }

    &-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $grayText;
    }
  }

  &__tierInfo {
    &-wrapper {
      width: 100%;
      background-color: #F5FAFC;
      margin-bottom: 20px;
    }

    &-container {
      padding: 28px 31px;
    }

    &-header {
      &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #D7E3E7;
      }

      &__title {
        font-size: 20px;
        line-height: 22px;
        font-weight: 500;
        color: $blueDeep;
      }

      &__price {
        font-weight: 500;
        color: #00A3FA;
        background-color: rgba(#00A3FA, 0.1);
        border-radius: 46px;
        padding: 5px 12px;
      }
    }

    &-info {
      &__wrapper {
        display: flex;
        flex-direction: column;
      }

      &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
      }

      &__item {
        &-name {
          font-size: 16px;
          line-height: 22px;
          color: $grayText;
        }

        &-value {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          color: $blueDeep;

          &.disabled {
            color: $grayText;
            font-weight: inherit;
          }
        }
      }
    }
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: center;

    &.successing {
      margin-bottom: 10px;
    }

    &.cancelling {
      line-height: 18px;
      color: #6E7378;
      background-color: inherit;
      border: 1px solid #DFE7F0;
    }
  }
}