$block: '.boxPickerModal';

#{$block} {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 5px 24px rgba(53, 135, 182, 0.22);
  border-radius: 4px;
  height: 80vh;
  width: 85vw;
  position: relative;

  &__picker {
    flex: 1;
  }

  &__logoutButton {
    position: absolute;
    z-index: 1;
    top: 14px;
    left: 13px;
  }
}
