$block: '.settingsSignature';

#{$block} {
  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: $blueDeep;
    margin-bottom: 17px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__text {
    overflow: hidden;

    &-container {
      overflow: hidden;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 296px;
    padding: 10px;
    height: 138px;
    background: #ffffff;
    border: 1px solid #cee0f0;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(14, 100, 148, 0.09);
    border-radius: 9px;
    margin-bottom: 26px;

    @media (max-width: $lg-width) {
      width: 225px;
      height: 100px;
    }

    @media (max-width: $sm-width) {
      width: 100%;
      height: 100px;
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &--loading {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 9px;
        background-color: white;
      }
    }

    &:not(:last-child) {
      margin-right: 26px;

      @media (max-width: $sm-width) {
        margin-right: 0;
      }
    }

    &-img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }

  &__dropDown {
    &-wrapper {
      position: absolute;
      top: 0;
      right: 0;
    }

    &-inner {
      position: relative;
    }

    &-trigger {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding: 16px;

      & > span {
        width: 3px;
        height: 3px;
        border-radius: 100%;
        background-color: $blue;

        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }

    &-list {
      position: absolute;
      right: -12px;
      width: 97px;
      padding: 10px 25px;
      background: #ffffff;
      box-shadow: 0px 0px 26px rgba(53, 135, 182, 0.24);
      border-radius: 4px;
      z-index: 200;

      &:before {
        content: '';
        position: absolute;
        right: 22px;
        top: -17px;
        border: 7px solid transparent;
        border-bottom: 10px solid white;
      }

      @media (max-width: $sm-width) {
        z-index: 1;
      }
    }

    &-item {
      display: flex;
      padding: 10px 0;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid #e3edf5;
      }

      &--edit {
        color: $grayText;

        & svg path {
          stroke: $grayText;
        }

        &:hover {
          color: $blue;

          & svg path {
            stroke: $blue;
          }
        }
      }

      &--delete {
        color: $red;

        & svg path {
          fill: $red;
        }

        &:hover {
          color: $redDark;

          & svg path {
            fill: $redDark;
          }
        }
      }

      &-icon {
        margin-right: 14px;

        & > div {
          display: flex;
        }
      }
    }
  }

  &__removeTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: $blueDeep;
    text-align: center;
    margin: 30px 0 5px 0px;
  }

  &__createButton {
    margin-bottom: 40px;

    & .button {
      width: 210px;
      display: flex;
      justify-content: center;
    }
  }
}
