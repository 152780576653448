$block: '.header';

#{$block} {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1170px;
  margin: 0 auto;
  padding: 10px 0;

  @media (max-width: $lg-width) {
    padding: 10px 10px;
  }

  &__wrapper {
    box-sizing: border-box;
    border-bottom: 1px solid rgba(209, 219, 226, 0.5);
  }

  &__month-wrapper {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
  }

  &__counter-wrapper {
    display: flex;
    justify-content: center;
    min-width: 135px;
  }

  &__month-counter {
    margin: 0 19px 0 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $blueDeep;
  }

  &__left-month-text {
    display: block;
    color: $grayText;
  }

  &__action-wrapper {
    display: flex;
    align-items: center;
  }

  &__help {
    margin-left: 8px;

    &--stroke {
      &:hover {
        & svg path {
          stroke: $blue;
        }
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      & > div {
        display: flex;
      }
    }
  }

  &__banner {
    height: 54px;
    background: $blue;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    min-height: 40px;

    &.mobile {
      height: 44px;
    }

    .banner {
      &__container {
        display: flex;
        justify-content: center;
        padding: 0 10px;

        &.mobile {
          font-size: 10px;
        }
      }

      &__button {
        margin: auto 21px;
        padding: 9px 28px;
        text-decoration: underline;
        border-radius: 40px;
        text-wrap: nowrap;

        &:hover {
          color: $blue;
          background-color: #fff;
          text-decoration: none;
          cursor: pointer;
        }
      }

      &__title {
        display: flex;
        justify-content: center;
        margin: auto 0;

        .title {
          &__light {
            font-weight: 100;
            margin-left: 8px;
          }
        }
      }
    }
  }

  &__team-button-wrapper {
    padding-right: 25px;
    border-right: 1px solid rgba(209, 219, 226, 0.5);
    margin-right: 18px;
  }

  &__divider {
    height: 42px;
    margin-right: 18px;
    padding-left: 25px;
    border-right: 1px solid rgba(209, 219, 226, 0.5);
  }
}
