$block: '.about-signaturely';

#{$block} {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__wrapper {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    max-width: 1170px;
    margin: 0 auto;
    padding: 65px 0 90px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: #1b3955;
      margin-bottom: 5px;
    }
    &-text {
      font-family: Circular Std;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $grayText;
      margin-bottom: 20px;
    }
  }
  &__dashboard {
    display: grid;
    grid-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 45px;
    @media (max-width: $md-width) {
      grid-template-columns: repeat(2, 1fr);
    }
    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 284px;
      height: 258px;
      padding: 40px 20px 5px;
      background: #fff;
      border: 1px solid #cee0f0;
      box-sizing: border-box;
      box-shadow: 0 2px 6px rgba(14, 100, 148, 0.09);
      border-radius: 9px;
      &-label {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $blueDeep;
        margin-bottom: 4px;
      }
      &-desc {
        line-height: 22px;
        text-align: center;
        color: $caution;
      }
    }
    &-icon {
      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 54px;
        height: 54px;
        background: #ffffff;
        border: 1px solid #e0e9ed;
        box-sizing: border-box;
        border-radius: 18px;
        margin-bottom: 13px;
      }
    }
  }
}
