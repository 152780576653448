$block: '.paginator';

#{$block} {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__break {
    margin-right: 17px;
    margin-left: 3px;
    cursor: pointer;
    outline: none;
  }

  &__page {
    font-weight: 450;
    font-size: 14px;
    line-height: 22px;
    color: $caution;
    cursor: pointer;

    & a {
      outline: none;
    }

    &:not(:last-child) {
      margin-right: 14px;
    }

    &--active {
      padding: 0px 4px 0 3px;
      border-bottom: 1px solid#00A3FA;
      line-height: 16px;
      color: $blue;
    }
  }
}
