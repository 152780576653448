$block: '.downgradePlanModal';

#{$block} {
  padding: 20px;

  &__container {
    padding: 20px;
    max-width: 400px;
  }

  &__title {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
  }

  &__text {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    color: $grayText;
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}