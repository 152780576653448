$block: '.underMaintenance';

#{$block} {
  width: 592px;
  height: 474px;
  border: 1px solid #CEE0F0;
  border-radius: 3px;
  box-shadow: 0px 4px 12px rgba(45, 60, 74, 0.06);

  &__wrapper {
    padding: 65px 0 90px;
  }

  &__icon {
    width: 105px;
    height: 105px;
    margin: 65px auto 21px;
    background-color: #EDF8Fe;
    border-radius: 100%;
    display: flex;
    justify-content: center;

    div {
      height: max-content;
      margin: auto;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
    &-title {
      max-width: 366px;
      margin-bottom: 11px;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      color: #1b3955;
    }
    &-subtitle {
      max-width: 444px;
      margin-bottom: 31px;
      font-family: Circular Std;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $grayText;
    }
    &-button {
      button {
        width: 202px;

        p {
          margin: auto;
        }
      }
    }
  }
}