$block: '.documentPage';

#{$block} {
  margin: auto;
  width: max-content;
  border-radius: 8px;
  box-shadow: 0px 2.8545px 27.26px rgba(7, 103, 157, 0.19);
  margin-bottom: 7.5px;

  &__inner {
    width: max-content;
    position: relative;
    user-select: none;

    &-pdf_page {
      width: max-content;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  &__preview {
    &-content {
      position: absolute;
      top: 0;
      left: 0;
    }

    &-item {
      &-wrapper {
        position: relative;

        @media print {
          margin-bottom: 0 !important;
          height: auto !important;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      @media print {
        height: auto !important;
      }

      box-shadow: none;
      & img {
        border-radius: 0;
      }

      & > div {
        box-shadow: none;
      }
    }
  }
}
