$block: '.settings';

#{$block} {
  &__form {
    margin-bottom: 40px;
  }

  &__block {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #e4e9f1;

    &:last-child {
      border: none;
    }

    &--small {
      width: 404px;

      &.mobile {
        width: 100%;
      }
    }

    &--middle {
      width: 480px;

      &.mobile {
        width: 228px;
      }
    }

    &--full-width {
      width: 100%;
    }

    &--borderless {
      border: none;
    }
  }

  &__button {
    &-cancel {
      &.button--disabled {
        color: $grayDark;
        background-color: transparent;
      }
    }
  }

  &__text {
    color: $blueDeep;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;

    &--grey {
      color: $grayText;
    }

    &--greyLight {
      color: #a5b3bd;
    }

    &--black {
      color: $blueDeep;
    }

    &--green {
      color: #4bc95f;
    }

    &--red {
      color: $red;
    }

    &--justify {
      text-align: justify;
    }

    &--pre {
      white-space: pre;
    }

    &--medium {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }

    &--bold {
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
    }

    &--bolder {
      font-weight: bold;
      font-size: 30px;
      line-height: 38px;
    }

    &--default-size {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 13px;

    &--small {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 25px;
  }

  &__two-fields-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;

    &.mobile {
      display: initial;
    }
  }

  &__field {
    flex: 1;

    &--half {
      width: calc(50% - 24px);
    }

    &-wrapper {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    & .form__label {
      color: $blueDeep;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }

    & .uiSelect__select-value {
      font-size: 14px;
    }

    &--middle {
      width: 330px;
    }

    &--gap {
      margin-bottom: 64px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--pdf-checkboxes {
      width: 465px;
    }

    &--small {
      width: 230px;
    }

    &.lock {
      position: relative;
    }

    &.mobile {
      width: 288px;
    }
  }

  &__form {
    &-checkbox {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      &--no-margin {
        margin-top: 0;
      }

      & .uiCheckbox__label {
        color: $grayDark;
      }
    }

    &-group {
      display: flex;

      &.mobile {
        flex-direction: column;
      }

      &-item {
        margin-right: 24px;

        &:last-child {
          margin-right: 0px;
        }

        &.mobile {
          margin-right: 0;
        }
      }
    }
  }
}
