$blueLighter: #f3f9fb;
$blueLight: #3abbff;
$blue: #00a3fa;
$blueDark: #008ad3;
$blueDeep: #2d3c4a;

$redLight: #fff3f3;
$redLighter: #ffd4d4;
$red: #ff5151;
$redDark: #ef2f2f;

$grayLight: #d7e3e7;
$gray: #a5b3bd;
$graySemiDark: #cfdfed;
$grayDark: #8698a5;
$grayDarker: #596a78;
$grayText: #7d8d98;
$grayBackground: #E8F7FF;
$grayLighter: #f2f2f2;
$grayExtraLight: #f4fafc;

$grayBlueLight: #e6f4fc;
$grayBlue: #60869a;

$greenLight: #e3ffea;
$greenSemiLight: #d2eed9;
$green: #3a974e;

$orangeLight: #fff2e6;
$orange: #f18649;

$caution: #627790;

$green: #4BC95F;
