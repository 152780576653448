$block: '.requisiteModal';

#{$block} {
  &.mobile {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &-select {
    display: flex;
    flex: 1;
    height: 640px;

    @media (max-width: $sm-width) {
      height: 100%;
    }
  }

  &__spinner {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  &__wrapper {
    max-width: 640px;
    width: 100%;
    padding: 0;
    overflow: visible;
    border: 1px solid #b2c6d9;
    border-radius: 9px;

    &.mobile {
      position: absolute;
      top: 0;
      bottom: 0;
      overflow-y: auto;
      border: 0;
      border-radius: 0;
    }

    &.responsive {
      @media (max-width: $lg-width) {
        position: absolute;
        top: 0;
        bottom: 0;
        overflow-y: auto;
        max-width: 768px;
        border: 0;
        border-radius: 0;
      }

      @media (max-width: $sm-width) {
        max-width: 425px;
        border: 0;
        border-radius: 0;
      }
    }

    &-select {
      max-width: 714px;

      @media (max-width: $lg-width) {
        overflow-y: auto;
        max-width: 768px;
      }

      @media (max-width: $sm-width) {
        max-width: 425px;
      }
    }

    &::-webkit-scrollbar {
      background: none;
      width: 0;
    }
  }

  &__inner {
    padding: 50px 44px 0;

    &-select {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 50px 24px 24px;

      @media (max-width: $lg-width) {
        padding-top: 32px;
      }

      @media (max-width: $sm-width) {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;
      }
    }
    @media (max-width: $lg-width) {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 32px;
    }

    @media (max-width: $sm-width) {
      padding: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 35px;

    @media (max-width: $sm-width) {
      margin-bottom: 15px;
      padding: 16px 16px 0 16px;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;

        > svg {
          width: 10.4px;
          height: 10.4px;
        }
      }
    }
  }

  &__content {
    @media (max-width: $lg-width) {
      padding: 0 61px;
    }

    @media (max-width: $sm-width) {
      padding: 16px 16px 0;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    &-select {
      display: flex;
      flex: 1;
      flex-direction: column;

      @media (max-width: $lg-width) {
        padding: 0;
      }

      @media (max-width: $sm-width) {
        display: block;
        padding-left: 16px;
        padding-right: 16px;
      }

      &--inner {
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        max-height: 349px;
        overflow: auto;
        padding-left: 16px;
        box-sizing: border-box;

        @media (max-width: $lg-width) {
          padding-left: 0px;
          margin-bottom: 40px;
        }

        @media (max-width: $sm-width) {
          margin-bottom: 16px;
          padding-right: 0;
          overflow: visible;
          max-height: none;
        }

        & .settingsSignature__item--loading {
          &:after {
            transition: 0.2s;
          }

          &:hover {
            &:after {
              background-color: $blueLighter;
            }
          }
        }

        & .settingsSignature__item {
          transition-property: background-color;
          transition-duration: 0.2s;
          cursor: pointer;
          margin-bottom: 32px;

          @media (max-width: $lg-width) {
            margin-bottom: 20px;
          }

          @media (max-width: $sm-width) {
            margin-top: 16px;

            &:not(:first-child) {
              margin-top: 12px;
            }

            margin-bottom: 0;
          }

          &:hover {
            background-color: $blueLighter;
          }

          &:not(:last-child) {
            margin-right: 32px;

            @media (max-width: $lg-width) {
              margin-right: 16px;
            }

            @media (max-width: $sm-width) {
              margin-right: 0;
            }
          }

          @media (min-width: $lg-width) {
            &:nth-child(2n) {
              margin-right: 0px;
            }
          }

          @media (max-width: $lg-width) {
            &:nth-child(3n) {
              margin-right: 0px;
            }
          }

          &--selected {
            background-color: $blueLighter;

            & .settingsSignature__item--loading {
              &:after {
                background-color: $blueLighter;
              }
            }
          }
        }
      }

      &--empty {
        align-items: center;
        justify-content: center;
        align-content: unset;

        @media (max-width: $sm-width) {
          margin-top: 40px;
        }
      }
    }
  }

  &__field {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 40px;

    @media (max-width: $sm-width) {
      margin-bottom: 16px;
    }

    &-label {
      font-weight: 500;
      line-height: 18px;
      color: $blueDeep;
      margin-bottom: 7px;
      &--required {
        color: $red;
      }
    }

    &-wrapper {
      display: flex;

      @media (max-width: $sm-width) {
        flex-direction: column;
      }
    }

    @media (min-width: $sm-width) {
      &:nth-child(1) {
        width: 230px;
      }

      &:nth-child(2) {
        width: 106px;
      }

      &:last-child:not(:first-child) {
        margin-left: 16px;
      }
    }

    @media (min-width: $lg-width) {
      &:nth-child(1) {
        width: 238px;
      }

      &:nth-child(2) {
        width: 83px;
      }

      &:last-child:not(:first-child) {
        margin-left: 24px;
      }
    }
  }

  &__addButton {
    &-wrapper {
      margin-bottom: 32px;
      margin-left: auto;
      justify-content: flex-end;

      @media (max-width: $sm-width) {
        justify-content: center;
        margin-left: 0;
      }

      &:hover {
        & #{$block}#{__addButton-icon} {
          & svg path {
            stroke: $blueLight;
          }
        }

        & #{$block}#{__addButton-label} {
          color: $blueLight;
        }
      }
    }

    &-icon {
      & svg path {
        stroke: $blue;
      }
    }

    &-label {
      color: $blue;
    }

    &--mobile {
      background: none;
      color: $blue;
      border: 1px solid $blue;

      &:hover {
        background: $blue;
        color: #fff;
      }

      @media (max-width: $lg-width) {
        align-self: flex-start !important;
      }

      @media (min-width: $lg-width) {
        display: none !important;
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: $blueDeep;
  }

  &__nav {
    &-list {
      display: flex;
      border-bottom: 1px solid #e4e9f1;
      justify-content: center;
      margin-bottom: 25px;

      &-select {
        margin-bottom: 32px;

        @media (max-width: $lg-width) {
          margin-bottom: 0;
        }

        @media (max-width: $sm-width) {
          &-wrapper {
            padding: 0 16px;
            z-index: 1;

            &.shadowed {
              box-shadow: 0 16px 24px -10px rgba(53, 135, 182, 0.22);
            }
          }
        }
      }

      @media (max-width: $lg-width) {
        margin-bottom: 40px;
      }

      @media (max-width: $sm-width) {
        margin: 0;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      color: $grayText;
      transition: all 0.2s ease;
      border-bottom: 2px solid transparent;
      margin-bottom: -1px;
      padding-bottom: 12px;
      width: 100%;
      max-width: 100px;

      @media (max-width: $lg-width) {
        margin-left: 16px;
        margin-right: 16px;
      }

      @media (max-width: $sm-width) {
        margin-left: 0;
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
        transition: all 0.2s ease;
        color: $blue;
        border-color: $blue;
      }

      &--active {
        color: $blue;
        border-color: $blue;
      }
    }
  }

  &__area {
    background: $grayExtraLight;
    border: 1px solid #cfdfed;
    position: relative;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;

    &-wrapper {
      display: flex;
      justify-content: space-between;

      @media (max-width: $lg-width) {
        flex-direction: column;
      }

      &.tablet-wrapper {
        @media (max-width: $lg-width) {
          flex-direction: row;
        }

        @media (max-width: $sm-width) {
          flex-direction: column;
        }

        @media (min-width: $sm-width) and (max-width: $lg-width) {
          .requisiteModal__area-container {
            &:nth-child(1) {
              width: 352px;
            }

            &:nth-child(2) {
              width: 230px;
            }

            &:last-child:not(:first-child) {
              margin-left: 16px;
            }
          }
        }
      }
    }

    &-clear {
      &-img {
        position: absolute;
        cursor: pointer;
        left: 30px;
        bottom: 40px;

        @media (max-width: $lg-width) {
          left: 26px;
          bottom: 55px;
        }

        @media (max-width: $sm-width) {
          left: 10px;
          bottom: 47px;
        }
      }

      &-text {
        position: absolute;
        color: $grayText;
        line-height: 22px;
        user-select: none;
        position: absolute;
        left: 30px;
        bottom: 10px;
        cursor: pointer;

        @media (max-width: $lg-width) {
          left: 24px;
          bottom: 12px;
        }

        @media (max-width: $sm-width) {
          left: 8px;
          bottom: 8px;
        }
      }
    }

    &-img-close {
      position: absolute;
      left: 30px;
      bottom: 45px;
      cursor: pointer;
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;

      @media (min-width: $lg-width) {
        width: max-content;
        margin-bottom: 24px;

        &:nth-child(1) {
          flex: 2;
        }

        &:nth-child(2) {
          flex: 1;
          min-width: 181px;
        }

        &:last-child:not(:first-child) {
          margin-left: 24px;
        }
      }

      @media (max-width: $lg-width) {
        margin-bottom: 22px;

        &:last-child:not(:first-child) {
          margin-bottom: 30px;
        }
      }
    }

    &-subtitle {
      color: $grayText;
      line-height: 22px;

      @media (max-width: $lg-width) {
        margin-bottom: 12px;
      }

      @media (max-width: $sm-width) {
        margin-bottom: 8px;
      }
    }

    &--clear {
      margin-bottom: 10px;
    }

    &--type {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;
      margin-bottom: 12px;
      padding: 2px;
      overflow: hidden;

      @media (max-width: $lg-width) {
        height: 125px;
        margin-bottom: 0;
      }

      div {
        position: absolute;
      }
    }

    &--file {
      display: flex;
      justify-content: center;
      height: 183px;
      margin-bottom: 10px;

      @media (max-width: $lg-width) {
        min-height: 280px;
      }

      @media (max-width: $sm-width) {
        min-height: 164px;
      }
    }
  }

  &__canvas {
    width: calc(100% - 60px);
    border: 1px solid #cfdfed;
    border-radius: 3px;
    height: 110px;
    box-sizing: border-box;
    margin: 25px 30px 30px;

    @media (max-width: $lg-width) {
      width: calc(100% - 48px);
      height: 206px;
      margin: 25px 24px 43px 24px;
    }

    @media (max-width: $sm-width) {
      width: calc(100% - 16px);
      height: 139px;
      margin: 32px 8px 35px 8px;
    }

    &-clear {
      color: $red;
      display: flex;
      align-items: center;

      &-container {
        display: flex;
      }

      & svg path {
        fill: $red;
      }

      &:hover {
        cursor: pointer;
        color: $redDark;

        & svg path {
          fill: $redDark;
        }
      }

      &-icon {
        margin-right: 10px;

        & > div {
          display: flex;
        }
      }
    }
  }

  &__type {
    &-input {
      height: 145px;
      font-size: 46px;
      line-height: 109px;
      text-align: center;
      color: #343d4a;
      background: $grayExtraLight;
      margin: 0;
      padding: 0;
      border: none;
      width: inherit;

      @media (max-width: $lg-width) {
        height: 123px;
        -webkit-text-fill-color: #343d4a;
        opacity: 1;
      }
    }

    &-select {
      max-width: 236px;
      margin-bottom: 24px;

      @media (max-width: $sm-width) {
        max-width: none;
      }

      &-title {
        font-weight: 500;
        line-height: 18px;
        color: $blueDeep;
        margin-bottom: 13px;

        @media (max-width: $lg-width) {
          margin-bottom: 8px;
        }
      }

      &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-clear {
        cursor: pointer;
      }

      & .uiSelect__search-list {
        max-height: 128px;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }

  &__file {
    &-img {
      height: auto;
      max-height: 124px;
      padding: 5px;
      margin: auto;
      box-sizing: border-box;

      &-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0 30px;
        box-sizing: border-box;
        &--marginBottom {
          margin-bottom: 15px;
        }
      }

      &-close {
        position: absolute;
        left: 0;
        bottom: 15px;
        cursor: pointer;

        & > div {
          display: flex;
        }
      }
    }

    &-input {
      &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &--exist {
          justify-content: flex-end;
        }
      }

      &-label {
        font-size: 12px;
        line-height: 16px;
        color: $blueDeep;
        margin-bottom: 24px;
      }

      &-button {
        max-width: 194px;
        width: 100%;
        display: flex;
        justify-content: center;

        & input[type='file'] {
          display: none;
        }

        & .button {
          min-width: 140px;
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          padding: 0 21px 0 20px;
        }
      }
    }
  }

  &__footer {
    border-top: 1px solid #e4e9f1;
    padding: 27px 40px 27px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: $lg-width) {
      padding: 20px 85px 40px 85px;
    }

    @media (max-width: $sm-width) {
      padding: 20px 16px 40px 16px;
      padding-bottom: 20px;
    }

    & > {
      :not(:last-child) {
        margin-bottom: 24px;

        @media (max-width: $lg-width) and (min-width: $sm-width) {
          margin-bottom: 0;
        }
      }
    }

    &-select {
      padding: 0;
      border: none;
      flex-direction: column;

      @media (max-width: $lg-width) and (min-width: $sm-width) {
        flex-direction: row;
        justify-content: flex-end;
      }

      @media (max-width: $lg-width) and (min-height: 628px) and (min-width: $sm-width) {
        width: 720px;
        position: absolute;
        bottom: 0;
        padding-bottom: 40px;
      }
    }

    .requisiteModal__addButton-wrapper {
      @media (max-width: $lg-width) {
        display: none;
      }
    }

    & .uiCheckbox__label {
      color: $grayText;
      line-height: 22px;
      margin-top: -4px;
    }

    & .uiCheckbox__wrapper {
      align-items: flex-start;
      flex: 1;
    }

    & .button {
      width: 194px;
      display: flex;
      justify-content: center;
      align-self: flex-end;

      @media (max-width: $lg-width) and (min-width: $sm-width) {
        width: 230px;
        margin-left: 16px;
      }

      @media (max-width: $sm-width) {
        width: 100%;
      }
    }

    &__link {
      color: $blue;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 39.5px 86.5px;
    background-color: $grayExtraLight;
    border: 1px solid $graySemiDark;
    border-radius: 2px;

    @media (max-width: $sm-width) {
      padding: 39.5px 29.5px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 15.5px;
      font-family: Circular Std;
      font-style: normal;
      text-align: center;

      &-header {
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 2px;
      }

      &-text {
        color: $gray;
        font-size: 14px;
        line-height: 22px;
      }
    }

    &-button {
      padding: 0 40px;
      font-weight: 700;
      line-height: 18px;
    }
  }

  &__custom-signature {
    font-size: 14px;
    line-height: 18px;
    color: $grayDark;
    text-align: center;
    margin-bottom: 22px;
    & > span {
      color: $blue;
      cursor: pointer;
    }
  }
}
