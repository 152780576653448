$block: '.billingBanner';

#{$block} {
  width: 604px;
  height: 132px;
  margin: 0 auto;
  padding: 25px 42px;
  border-radius: 16px;
  border: 1px solid #DEE8EB;
  box-sizing: border-box;

  &.mobile {
    width: 300px;
    height: initial;
    padding: 10px 16px;
  }

  &__container {
    display: flex;
    margin: 0 auto;
  }

  &__logo {
    display: flex;
    width: 82px;
    height: 82px;
    margin-right: 18px;
    border-radius: 24px;
    background: #E8F7FF;

    &.mobile {
      width: 55px;
      height: 55px;
      margin: auto 0;
      margin-right: 8px;
      border-radius: 8px;

      svg {
        width: 35px;
      }
    }

    div {
      margin: auto;
    }
  }

  &__text {
    width: 418px;
    font-size: 16px;

    &.mobile {
      width: 200px;
      font-size: 12px;
    }
  }

  &__title {
    margin-bottom: 8px;
    font-weight: bold;

    &.mobile {
      margin-bottom: 2px;
    }
  }

  &__description {
    color: #616E7A;

    &-bold {
      font-weight: 500;
    }
  }
}