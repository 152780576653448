@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap');
$font-Circular: 'Circular Std', sans-serif;

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/CircularStd-Book.ttf');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/CircularStd-Medium.ttf');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/CircularStd-Bold.ttf');
}

@font-face {
  font-family: 'Circular Stdo';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/CircularStd-Black.ttf');
}

@font-face {
  font-family: 'Sacramento';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Sacramento-Regular.ttf') format('truetype'),
    url('../../fonts/Sacramento-Regular.woff') format('woff'),
    url('../../fonts/Sacramento-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Kalam';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Kalam-Regular.ttf') format('truetype'),
    url('../../fonts/Kalam-Regular.woff') format('woff'),
    url('../../fonts/Kalam-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Caveat-Regular.ttf') format('truetype'),
    url('../../fonts/Caveat-Regular.woff') format('woff'),
    url('../../fonts/Caveat-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Bad Script';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/BadScript-Regular.ttf') format('truetype'),
    url('../../fonts/BadScript-Regular.woff') format('woff'),
    url('../../fonts/BadScript-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/DancingScript-Regular.ttf') format('truetype'),
    url('../../fonts/DancingScript-Regular.woff') format('woff'),
    url('../../fonts/DancingScript-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'La Belle Aurore';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/LaBelleAurore-Regular.ttf') format('truetype'),
    url('../../fonts/LaBelleAurore-Regular.woff') format('woff'),
    url('../../fonts/LaBelleAurore-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Bilbo Swash Caps';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/BilboSwashCaps-Regular.ttf') format('truetype'),
    url('../../fonts/BilboSwashCaps-Regular.woff') format('woff'),
    url('../../fonts/BilboSwashCaps-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Yellowtail';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Yellowtail-Regular.ttf') format('truetype'),
    url('../../fonts/Yellowtail-Regular.woff') format('woff'),
    url('../../fonts/Yellowtail-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Great Vibes';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/GreatVibes-Regular.ttf') format('truetype'),
    url('../../fonts/GreatVibes-Regular.woff') format('woff'),
    url('../../fonts/GreatVibes-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Qwigley';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Qwigley-Regular.ttf') format('truetype'),
    url('../../fonts/Qwigley-Regular.woff') format('woff'),
    url('../../fonts/Qwigley-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Marck Script';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/MarckScript-Regular.ttf') format('truetype'),
    url('../../fonts/MarckScript-Regular.woff') format('woff'),
    url('../../fonts/MarckScript-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Covered By Your Grace';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/CoveredByYourGrace-Regular.ttf') format('truetype'),
    url('../../fonts/CoveredByYourGrace.woff') format('woff'),
    url('../../fonts/CoveredByYourGrace-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Gochi Hand';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/GochiHand-Regular.ttf') format('truetype'),
    url('../../fonts/GochiHand-Regular.woff') format('woff'),
    url('../../fonts/GochiHand-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Handlee';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Handlee-Regular.ttf') format('truetype'),
    url('../../fonts/Handlee-Regular.woff') format('woff'),
    url('../../fonts/Handlee-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Just Another Hand';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/JustAnotherHand-Regular.ttf') format('truetype'),
    url('../../fonts/JustAnotherHand-Regular.woff') format('woff'),
    url('../../fonts/JustAnotherHand-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Nothing You Could Do';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/NothingYouCouldDo-Regular.ttf') format('truetype'),
    url('../../fonts/NothingYouCouldDo.woff') format('woff'),
    url('../../fonts/NothingYouCouldDo-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Shadows Into Light';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/ShadowsIntoLight-Regular.ttf') format('truetype'),
    url('../../fonts/ShadowsIntoLight.woff') format('woff'),
    url('../../fonts/ShadowsIntoLight-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Stalemate';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Stalemate-Regular.ttf') format('truetype'),
    url('../../fonts/Stalemate-Regular.woff') format('woff'),
    url('../../fonts/Stalemate-Regular.woff2') format('woff2');
}
