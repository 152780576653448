$block: '.addButton';

#{$block} {
  &__wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__icon {
    & div {
      display: flex;
    }
  }

  &__label {
    font-family: Circular Std;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    padding-left: 8px;
  }
}