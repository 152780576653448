$block: '.twoFactor';

#{$block} {
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 970px;

  &__form {
    padding: 45px 40px;
    width: 545px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.mobile {
      width: 310px;
    }

    &--border {
      border: 1px solid #cee0f0;
      box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.16);
      border-radius: 3px;
    }

    & .form__field {
      width: 100%;
    }

    & .title {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: #1b3955;
      margin-bottom: 10px;
    }

    & .subtitle {
      text-align: center;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $caution;
      width: 70%;
      margin-bottom: 16px;
    }
  }

  &__submitButton {
    display: flex;
    justify-content: center;
    width: 170px;

    & .button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__title {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1b3955;
    margin-bottom: 14px;
    &--bold {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }
  }
}
