$block: '.upcomingInvoice';

#{$block} {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  min-height: 433px;

  &__container {
    width: 400px;
  }

  &__switch-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }

  &__switch {
    margin-right: 12px;
    display: flex;
    align-items: center;

    &-item {
      &:not(:last-child) {
        margin-right: 14px;
      }
    }

    &-text {
      font-size: 14px;
      line-height: 22px;
      color: #1B3955;
    }

    &-toggler {
      & .react-toggle {
        &-track {
          width: 46px !important;
          height: 23px !important;
          background-color: $blue !important;
        }
        &-thumb {
          width: 21px !important;
          height: 21px !important;
          top: 1px !important;
          background: #ffffff !important;
          border: 0.5px solid rgba(228, 233, 241, 0.5) !important;
          box-sizing: border-box !important;
          box-shadow: 2px 1px 3px rgba(0, 112, 171, 0.2) !important;
        }
      }
      &.react-toggle {
        &--checked {
          .react-toggle-thumb {
            left: 24px !important;
          }
        }
      }
    }
  }

  &__discount {
    display: flex;
    justify-content: center;
    width: 73px;
    padding: 5px 0;
    background: #f1faff;
    border-radius: 110px;
    font-size: 12px;
    line-height: 15px;
    align-items: center;
    color: $blue;

    &-text {
      font-size: 14px;
    }
  }

  &__tax {
    margin-bottom: 25px;
    padding-bottom: 40px;
    border-bottom: 1px solid $grayLight;

    &-price {
      display: flex;
      justify-content: space-between;
    }

    &-coupon {
      width: max-content;
      margin-bottom: 20px;
      font-size: 12px;
      font-weight: lighter;
      color: $blue;
      cursor: pointer;

      span {
        margin-right: 5px;
      }
    }

    &-quantity {
      display: flex;
      justify-content: space-between;
      margin-bottom: 17px;

      &.mobile div:first-child {
        max-width: 165px;
      }
    }

    &-discount {
      display: flex;
      justify-content: space-between;
      margin-bottom: 17px;
    }

    &-credit {
      display: flex;
      justify-content: space-between;
    }
  }

  &__plan {
    &-type {
      display: block;
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
    }

    &-duration {
      margin-bottom: 7px;
      display: block;
      font-size: 14px;
      font-weight: lighter;
      line-height: 18px;
      color: $grayText;
    }

    &-amount {
      margin: auto 0;
      font-weight: initial;
      color: $grayText;
    }

    &-assigned {
      color: $grayText;
    }
  }

  &__discount {
    &-amount {
      font-weight: initial;
      color: $green;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 25px;

    &-title {
      margin: auto 0;
      margin-right: 20px;
    }

    &-amount {
      font-size: 30px;
      line-height: 38px;
      font-weight: bold;
    }

    &-warning {
      margin-bottom: 20px;
      font-size: 12px;
      font-weight: lighter;
      color: $grayText;
    }
  }

  &__footer {
    &.card-form {
      &.card-form__group {
        margin-bottom: 0;
      }
    }
  }

  &__card {
    font-size: 14px;

    &-title {
      margin-bottom: 10px;
    }

    &-credentials {
      div:first-child {
        margin-bottom: 6px;
      }

      color: $grayText;
    }

    &-details {
      margin-bottom: 32px;

      &.title {
        margin-bottom: 20px;
      }

      &.card_number_text {
        font-weight: 450;
        font-size: 14px;
        color: $grayText;
        margin-bottom: 8px;
      }

      &.card_expires_text {
        font-weight: 450;
        font-size: 14px;
        color: $grayText;
      }
    }
  }
}
