$block: '.signerOptionModal';

#{$block} {
  &__item {
    display:flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__list {
    max-height: 200px;
    overflow-y: auto;
  }

  &__checkbox {
    width: 30px;
  }

  &__subtitle {
    margin-bottom:20px;
  }
  
  &__confirm {
    width: 110px;
    display: flex;
    justify-content: center;
  }
}
