$block: '.uiSelect';

#{$block} {
  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__empty {
    color: $blueDeep;
    padding: 12px 20px;
  }

  &__clear {
    margin: 0 10px;

    & svg path {
      stroke: $blueDeep;
    }

    &:hover {
      & svg path {
        stroke: $blueDark;
      }
    }
  }

  &__select {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d7e3e7;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 10px 0 17px;
    height: 42px;
    box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.1);

    &:hover {
      cursor: pointer;
      border-color: $graySemiDark;
      background-color: $grayExtraLight;
      transition: all 0.2s ease;
      color: $blue;

      #{$block}#{__select-value} {
        color: $blue;
      }

      #{$block}#{__select-value} {
        color: $blue;
      }

      #{$block}#{__select-arrow} {
        & svg path {
          stroke: $blueDark;
        }
      }
    }

    &--open {
      border-color: $blueLight;

      #{$block}#{__select-value} {
        color: $blue;
      }

      #{$block}#{__select-arrow} {
        transition: all 0.2s ease;
        transform: rotate(-180deg);

        & svg path {
          stroke: $blue;
        }
      }
    }

    &--disabled {
      &:hover {
        background-color: #fff;
        color: $grayDarker;

        #{$block}#{__select-value} {
          color: $grayDarker;
        }

        #{$block}#{__select-arrow} {
          & svg path {
            stroke: #d7e3e7;
          }
        }
        cursor: not-allowed;
      }
    }

    &-placeholder {
      color: $gray;
    }

    &-inner {
      display: flex;
      align-items: center;
      width: calc(100% - 8px);

      &--isClear {
        width: calc(100% - 40px);
      }
    }

    &-row {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-value {
      font-size: 13px;
      line-height: 19px;
      color: #596a78;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &-wrapper {
        width: 100%;
      }
    }

    &-arrow {
      display: flex;
      transition: all 0.2s ease;

      & > div {
        display: flex;
      }

      & svg path {
        stroke: #d7e3e7;
      }
    }
  }

  &__content {
    &-wrapper {
      max-width: 100%;
      max-height: 172px;
      background: #ffffff;
      box-shadow: 0px 6px 21px rgba(84, 149, 208, 0.35);
      border-radius: 6px;
      position: absolute;
      z-index: 99;
      width: 100%;
      background-color: #fff;
      top: 50px;
      left: 0;
      margin-bottom: 12px;
      overflow-y: auto;
    }
  }

  &__search {
    &-item {
      color: #596a78;
      padding: 12px 18px;

      &:not(:last-child) {
        border-bottom: 1px solid #cee0f0;
      }

      &:hover {
        cursor: pointer;
        color: $blue;
        background-color: $blueLighter;
      }
    }
  }
}
