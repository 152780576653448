$block: '.documentReportModal';

#{$block} {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &__content {
    min-width: 200px;
    padding: 30px 15px 0 15px;
    margin-bottom: 20px;

    &.mobile {
      padding-top: 40px;
      align-self: center;
    }
  }

  &__buttons {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.left {
      width: 100%;
      max-width: 440px;
      box-sizing: border-box;
      margin: 0 auto;
      padding: 28px 15px 20px 15px;
      justify-content: start;

      &.mobile {
        .confirmModal__button {
          width: 100%;
          margin-right: initial;
          padding: 0 15px;
          button {
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  &__button {
    margin-right: 15px;

    &--cancel {
      color: $gray;
      cursor: pointer;

      &:hover {
        cursor: pointer;
        color: $grayLight;
        transition: all 0.3s ease;
      }

      &:active {
        color: $grayDark;
      }
    }

    &--delete {
      display: flex;
      justify-content: center;
      min-width: 150px;
    }
  }

  &__header {
    width: 400px;

    @media (max-width: $lg-width) {
      width: 300px;
    }

    &-title {
      text-align: center;
    }

    &-subTitle {
      text-align: center;
      font-weight: initial;
    }

    &-confirm {
      display: flex;
      justify-content: center;
      width: 90px;
    }
  }

  &__selectYear {
    margin-bottom: 15px;
    width: 386px;
    align-self: center;

    @media (max-width: $lg-width) {
      width: 300px;
    }

    &-contentWrapper {
      position: fixed;
      top: auto;
      left: auto;
      min-width: 200px;
      max-width: 386px;

      @media (max-width: $lg-width) {
        width: 300px;
      }
    }
  }

  &__successModal {
    max-width: 550px;
    width: 100%;

    &-button {
      width: 180px;
    }

    &.mobile {
      max-width: initial;
    }
  }
}
