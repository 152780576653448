$block: '.emailRecipients';

#{$block} {
  &__label {
    margin-bottom: 10px;
  }

  &__add {
    &-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      background: #ffffff;
      height: 42px;
      border: 1px dashed #d7e3e7;
      box-sizing: border-box;
      border-radius: 3px;
      color: $grayDark;

      &:hover {
        cursor: pointer;
        border-color: $grayDark;
        color: $grayDark;

        & svg path {
          stroke: $grayDarker;
        }
      }
    }

    &-icon {
      margin-left: 12px;

      & > div {
        display: flex;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &-inner {
      display: flex;
      align-items: center;
    }

    & .form__input,
    .form__error-wrapper {
      width: 100%;
    }
  }

  &__remove {
    margin-left: 15px;

    &:hover {
      cursor: pointer;

      & svg path {
        stroke: $grayDarker;
      }
    }

    & > div {
      display: flex;
    }
  }
}
