$block: '.teamModal';

#{$block} {
  width: 600px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 38px 50px;
  }

  &__header {
    text-align: left;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 20px;
    margin-bottom: 12px;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 22px;
    color: $grayText;
  }

  &__label {
    font-weight: bold;
    color: $blueDeep;
    margin-bottom: 14px;
  }

  &__email-wrapper {
    margin: 0 -20px;
    padding: 0 20px 40px;
    max-height: 230px;
    overflow-y: auto;
  }

  &__buttons-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__cancel {
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    color: $grayDark;
  }

  &__submit-button .button {
    display: flex;
    width: 216px;
    justify-content: center;
    margin-right: 13px;
  }

  &__text {
    font-size: 14px;
    line-height: 22px;
    color: $grayText;

    &--bold {
      font-weight: bold;
    }

    &--blue {
      color: $blueLight;
    }

    &--black {
      color: $blueDeep;
    }
  }

  &__price {
    margin-bottom: 9px;
    padding-top: 30px;
    border-top: 1px solid #d7e3e7;

    &-description {
      margin-bottom: 21px;
    }
  }
}
