$block: '.uiCheckbox';

#{$block} {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;

    &.uiCheckbox--disabled {
      cursor: no-drop;
    }

    #{$block }--unChecked {
      border: 1px solid $blue;

      &.uiCheckbox--disabled {
        border: 1px solid $gray;
      }
    }

    #{$block }#{__label} {
      cursor: pointer;
      color: $blue;

      &.uiCheckbox--disabled {
        cursor: no-drop;
        color: #a5b3bd;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__inner {
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    width: 16px;
    height: 16px;

    & svg {
      width: 8px;
    }
  }

  &__label {
    color: $blueDeep;
    margin-left: 8px;

    &.uiCheckbox--disabled {
      &:hover {
        cursor: no-drop;
        color: #a5b3bd;
      }
    }
  }

  &__icon {
    margin-top: -1px;
  }

  &--checked {
    min-width: 16px;
    background: $blue;
    display: flex;
    align-items: center;
    justify-content: center;

    &.uiCheckbox--disabled {
      background: #a5b3bd;
    }
  }

  &--unChecked {
    min-width: 16px;
    background: #ffffff;
    border: 1px solid $gray;
    background: linear-gradient(180deg, #ffffff 0%, #ebeff2 100%);
    box-shadow: inset 0px -1.77778px 3.55556px rgba(6, 33, 57, 0.1);
  }
}
