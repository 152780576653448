$block: '.fieldDropDown';

#{$block} {
  z-index: 1;
  display: flex;
  position: absolute;
  -ms-touch-action: none;
  touch-action: none;

  .disabled_pointer_event {
    pointer-events: none;
  }

  &--opened {
    z-index: 2;
  }

  &--invalid {
    &::after {
      content: '\A';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 81, 81, 0.7);
    }
  }

  &--tapable {
    cursor: pointer;
  }

  &--required {
    &::before {
      content: '*';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      color: $red;
      position: absolute;
      left: -12px;
      z-index: 1;
    }
  }

  &__requisite-blank {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    align-items: center;
    padding-left: 5px;
    overflow: hidden;
    box-sizing: border-box;

    &-center {
      justify-content: center;
    }

    &-icon {
      margin-right: 7px;

      & div {
        display: flex;
      }
    }

    &-title {
      user-select: none;
      font-family: Circular Std;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      white-space: nowrap;
    }

    &-content {
      font: inherit;
      cursor: auto;
      padding: 0px;
      margin: 0;
      cursor: pointer !important;
    }

    &-shadow {
      position: absolute;
      pointer-events: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      visibility: hidden;
    }
  }

  &__trigger {
    position: relative;
    box-sizing: border-box;

    &--disabled {
      pointer-events: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    &--inFocus {
      &:only-child {
        background-color: #e5f0ff;
        box-shadow: 0px 5px 16px rgba(53, 135, 182, 0.35);
      }
    }

    &--sign {
      width: 100%;
      height: 100%;
      border: 1px solid #00a3fa;
      color: #1b3955;
      background: #e6f0ff;

      #{$block}#{__requisite-blank-icon} {
        & svg path {
          stroke: #1b3955;
        }
      }
    }

    &--initial {
      border: 1px dashed #f18649;
      color: #f18649;
      background: #fffef1;
      width: 100%;
      height: 100%;

      #{$block}#{__requisite-blank-icon} {
        & svg path {
          fill: #f18649;
        }
      }
    }

    &--name {
      display: flex;
      border: 1px solid #4bbe4f;
      background: #e4ffe5;
      width: 100%;
    }

    &--date {
      display: flex;
      border: 1px solid #4bbe4f;
      background: #e4ffe5;
      width: 100%;
    }

    &--text {
      border: 1px dashed #b862fb;
      color: #b862fb !important;
      background: #faebff;
      flex: 1;
      max-width: 100%;
      max-height: 100%;
    }

    &--checkbox {
      border: 1px solid #00baba;
      background: #d2f8f8;
      width: 100%;
      height: 100%;

      &-mark {
        width: 100%;
        height: 100%;

        & div {
          width: 100%;
          height: 100%;
          display: flex;

          & svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    & img {
      vertical-align: unset;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    &-checkbox {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      & img {
        width: 100%;
        min-width: 16px;
      }
    }

    &-name {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      &-content {
        padding: 0;
        padding-left: 23px;
        cursor: pointer !important;
      }

      &-shadow {
        pointer-events: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        visibility: hidden;
        position: absolute;
        box-sizing: border-box;
        white-space: nowrap;
      }

      &-input {
        width: calc(100% + 1px);
        box-sizing: border-box;
        background: none;
        border: none;
        transition: font-size 0.2s ease;

        &::-webkit-input-placeholder {
          color: #4bbe4f;
        }

        &::-moz-placeholder {
          color: #4bbe4f;
        }

        &:-ms-input-placeholder {
          color: #4bbe4f;
        }

        &:-moz-placeholder {
          color: #4bbe4f;
        }
      }

      &-empty {
        display: flex;
      }

      & svg path {
        stroke: #4bbe4f;
      }

      &-icon {
        position: absolute;
        display: flex;
        margin-right: 5px;
        margin-left: 3px;

        & > div {
          display: flex;
          align-items: center;
        }
      }
    }

    &-date {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      &-content {
        padding: 0;
        padding-left: 23px;
        cursor: pointer !important;
      }

      &-shadow {
        pointer-events: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        visibility: hidden;
        position: absolute;
        box-sizing: border-box;
      }

      &-input {
        width: calc(100% + 1px);
        box-sizing: border-box;
        background: none;
        border: none;
        transition: font-size 0.2s ease;

        &::-webkit-input-placeholder {
          color: #4bbe4f;
        }

        &::-moz-placeholder {
          color: #4bbe4f;
        }

        &:-ms-input-placeholder {
          color: #4bbe4f;
        }

        &:-moz-placeholder {
          color: #4bbe4f;
        }
      }

      &-empty {
        display: flex;
      }

      & svg path {
        stroke: #4bbe4f;
      }

      &-icon {
        position: absolute;
        display: flex;
        margin-right: 5px;
        margin-left: 3px;

        & > div {
          display: flex;
          align-items: center;
        }
      }
    }

    &-text {
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;

      &-content {
        font: inherit;
        cursor: auto;
        padding: 0px 3px;
        margin: 0;
        flex: 1;
        cursor: pointer !important;

        &--insertable {
          word-break: break-all;
          white-space: pre-wrap;
        }
      }

      &-shadow {
        pointer-events: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        visibility: hidden;
      }

      &-input {
        position: absolute;
        border: none !important;
        resize: none;
        width: calc(100% - 3px);
        overflow: hidden;
        max-height: 100%;
        padding: 0;
        padding-left: 3px;
        left: 0;
        top: 0;
        background: transparent !important;
        word-break: break-all;
        transition: font-size 0.2s ease;

        &::placeholder {
          font: inherit;
          color: inherit;
        }

        &--sign {
          box-shadow: none;
          color: inherit !important;
        }
      }

      &-empty {
        display: flex;
      }

      & svg path {
        stroke: #b862fb;
      }

      &-icon {
        position: absolute;
        display: flex;
        margin-right: 5px;

        & > div {
          display: flex;
          align-items: center;
        }
      }
    }

    &-triangle {
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: #fff;
      border: 1px solid;
      box-sizing: border-box;

      &--left {
        left: -3px;
        top: 50%;
        transform: translateY(-50%);
      }

      &--right {
        right: -3px;
        top: 50%;
        transform: translateY(-50%);
      }

      &--bottom {
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
      }

      &--top {
        top: -3px;
        left: 50%;
        transform: translateX(-50%);
      }

      &--topLeft {
        top: -3px;
        left: -3px;
      }

      &--topRight {
        top: -3px;
        right: -3px;
      }

      &--bottomRight {
        bottom: -3px;
        right: -3px;
      }

      &--bottomLeft {
        bottom: -3px;
        left: -3px;
      }
    }

    &--filled {
      background: none;
    }
  }

  &__content {
    &-body {
      > :not(:last-child) {
        margin-bottom: 15px;
      }
    }

    &-wrapper {
      position: absolute;
      width: 230px;
      background: #ffffff;
      box-shadow: 0px 6px 21px rgba(84, 149, 208, 0.35);
      border-radius: 6px;
      padding: 20px 0;
      z-index: 9;
      max-height: 300px;
      overflow-y: scroll;

      &:before {
        content: '';
        position: absolute;
        border: 7px solid transparent;
      }

      &--bottom {
        top: 100%;
        margin-top: 20px;

        &:before {
          left: 16px;
          top: -16px;
          border-bottom: 10px solid white;
        }
      }

      &--left {
        right: 0;
        top: 100%;
        margin-top: 20px;

        &:before {
          right: 16px;
          top: -16px;
          border-bottom: 10px solid white;
        }
      }

      &--top {
        bottom: 100%;
        margin-bottom: 20px;

        &:before {
          left: 16px;
          bottom: -16px;
          border-top: 10px solid white;
        }
      }

      &--top-left {
        bottom: 100%;
        right: 0;
        margin-bottom: 20px;

        &:before {
          right: 16px;
          bottom: -16px;
          border-top: 10px solid white;
        }
      }
    }
  }

  &__title {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #2d374a;
    border-bottom: 1px solid #e0e9f1;
    padding: 0 18px 10px;
  }

  &__item {
    line-height: 18px;
    color: #7f8898;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 13px 50px 13px 18px;
    background-color: #fff;
    position: relative;
    white-space: nowrap;

    &--checkable {
      transition: all 0.2s ease;

      &:hover {
        cursor: pointer;
        color: $blue;
        background-color: #e8f7ff;
        transition: all 0.2s ease;
      }
    }

    &--checkable#{$block}#{__item--active} {
      color: $blue;
      background-color: #e8f7ff;

      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        top: 12px;
        right: 18px;
        background-image: url('../images/icons/arrow-circle.svg');
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e0e9f1;
    }
  }

  &__checkbox {
    padding: 0 18px;

    & .uiCheckbox__label {
      color: $grayDark;
    }
  }

  &__styles {
    margin-bottom: 15px;
    padding: 0 18px;

    &-title {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #2d374a;
      margin-bottom: 10px;
    }

    &-select {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      & .uiSelect__search-list {
        max-height: 128px;
        overflow-x: hidden;
        overflow-y: scroll;
      }
    }

    &-label {
      line-height: 18px;
      color: #7f8898;
      margin-bottom: 4px;
    }
  }
}
