$block : ".progress-bar";

#{$block} {
  position: relative;
  width: 100%;
  height: 6px;
  background: #E3EBF0;
  border-radius: 30px;

  &__filter {
    background-color: $blue;
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
  }
}