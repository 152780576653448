$block: '.form';

#{$block} {
  display: flex;
  flex-direction: column;

  &__input {
    width: 100%;
    padding: 12px 16px 11px 16px;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid $grayLight;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.13);
    border-radius: 3px;
    color: $grayDarker;
    transition: all 0.2s ease;

    &-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &:focus,
    &:hover {
      border-color: $graySemiDark;
      background-color: $grayExtraLight;
    }

    &:disabled {
      cursor: not-allowed;
      color: #BAC9DB;
    }

    &--textArea {
      resize: none;
    }

    &-wrapper {
      &--password {
        & .form__input {
          padding-right: 50px;
        }

        position: relative;
        display: flex;
        flex-direction: column;
      }
    }

    &--hidden {
      padding: 0;
      border: none;
      box-shadow: none;
      background-color: transparent;
    }

    &--withIcon {
      padding: 12px 16px 11px 38px;
    }

    &-icon {
      position: absolute;
      z-index: 9;
      top: 13px;
      left: 16px;

      &--password {
        & div {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        left: unset;
        right: 16px;

        &:hover {
          cursor: pointer;
        }

        svg {
          stroke: #a5b3bd;
          width: 20px;
          height: 20px;
        }
      }
    }

    &--error {
      border-color: $red;
      background-color: $redLight;
      color: $red;

      &:focus,
      &:active,
      &:hover {
        border-color: $red;
        background-color: $redLight;
      }
    }

    &--disabled {
      background-color: $grayLight;
      color: $gray;
      border-color: $grayLight;
    }

    &::-webkit-input-placeholder {
      color: $gray;
    }

    &::-moz-placeholder {
      color: $gray;
    }

    &:-ms-input-placeholder {
      color: $gray;
    }

    &:-moz-placeholder {
      color: $gray;
    }
  }

  &__input--fsBig {
    font-size: 15px;
    color: #000;
    font-weight: 500;
    height: auto;
  }

  &__field {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
  }

  &__field--m24 {
    margin-bottom: 24px;
  }


  &__label {
    color: $caution;
    margin-bottom: 8px;
    display: inline-block;
  }

  &__error {
    color: $red;
    margin-top: 6px;
    white-space: pre-wrap;
  }

  &__tooltip {
    margin-top: 2px !important;

    &:after {
      left: 26px !important;
    }
  }
}