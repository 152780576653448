$block: '.successLtdPayment';

#{$block} {
  width: 500px;
  min-width: 500px;
  min-height: 319px;
  padding: 60px 40px 20px;
  font-family: Circular Std;
  font-style: normal;

  &.mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    min-width: 320px;
    padding: 30px 20px 20px;
    width: inherit;
  }

  &--template {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;

    &.mobile {
      padding: 30px 20px 20px;
    }

    > :not(:last-child) {
      margin-bottom: 20px;
    }

    &-icon {
      min-height: 62px;
      & div {
        margin-bottom: 10px;
        display: flex;
      }
    }

    &--template {
      border-bottom: none;
      margin: 0;
      padding: 0;
    }

    &--banBorderBottom {
      border-bottom: none;
    }
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: $caution;

    &-wrapper {
      text-align: center;

      > :not(:last-child) {
        margin-bottom: 9px;
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #1b3955;

    &.mobile {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
