$block: '.readyToSignModal';

#{$block} {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 30px 30px 0px 30px;
  }

  &__header {
    margin-bottom: 20px;
    text-align: center;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #1b3955;
    margin-bottom: 2px;
  }

  &__subTitle {
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: $grayText;
    text-align: center;
    margin-bottom: 20px;
  }

  &__buttons {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__button {
    margin-right: 15px;

    &--sameSize {
      min-width: 135px;
    }
  }
}
