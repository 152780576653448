$block: '.modal';

#{$block} {
  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  
  &.create-signature {
    @media screen and (max-height: 750px) {
      align-items: flex-start;
    }
  }

  &--transparent {
    background-color: transparent;
  }

  &__dialog {
    position: relative;
    min-width: 300px;
    box-sizing: border-box;
    padding: 20px;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0px 5px 24px rgba(53, 135, 182, 0.22);
    overflow: auto;
    outline: none;

    &--overflow-initial {
      overflow: initial;
    }

    &.mobile {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.promocode {
      &.mobile {
        top: initial;
        bottom: initial;
        height: 200px;
      }
    }

    &.changePlanModal {
      max-height: 100%;
      overflow-y: auto;
      padding: 50px;

      &::-webkit-scrollbar {
        width: 0;
      }

      &.mobile {
        position: relative;
        display: initial;
        width: 100%;
        max-width: 355px;
        padding: 0;
      }

      .confirmModal__content {
        margin-bottom: initial;
      }
    }
  }

  &__close-button {
    position: absolute;
    z-index: 10;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  &__header {
    margin-bottom: 18px;
    text-align: center;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #1b3955;
    margin-bottom: 2px;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #627790;
  }

  &__subTitle {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 22px;
    color: $caution;

    &--center {
      text-align: center;
    }
  }
}
