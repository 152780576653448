$block: '.text-tooltip';

#{$block} {
  position: relative;
  display: inline-flex;
  justify-content: center;

  &__container {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 6px 21px rgba(84, 149, 208, 0.35);
    border-radius: 6px;
    box-sizing: border-box;
    z-index: 10;
    padding: 20px;
    white-space: break-spaces;

    &--bottom {
      bottom: 100%;
      margin-bottom: 10px;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;

        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 9px solid #fff;

        transform: translateX(-50%);
        left: 50%;
        top: 100%;
      }
    }

    &--top {
      width: max-content;
      max-width: 300px;
      top: 100%;
      margin-top: 10px;

      &.mobile {
        max-width: 180px;
        transform: translate(-40%);
      }

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;

        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 9px solid #fff;

        transform: translate(-50%, -100%);
        left: 50%;
        top: 0;

        @media (max-width: $sm-width) {
          left: 90%;
        }
      }
    }

    &--left {
      top: -100%;
      right: 100%;
      margin-right: 10px;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;

        border-top: 8px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 9px solid transparent;

        transform: translate(100%, -50%);
        top: 50%;
        right: 0;
      }
    }

    &--right {
      top: -100%;
      left: 100%;
      margin-left: 10px;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;

        border-top: 8px solid transparent;
        border-right: 8px solid white;
        border-bottom: 9px solid transparent;

        transform: translate(-100%, -50%);
        top: 50%;
        left: 0;
      }
    }
  }

  &__trigger {
    cursor: pointer;
    width: 17px;
    height: 17px;
  }

  &__button {
    width: 100%;
  }

  &__independent {
    position: fixed;
    left: 36%;
    top: 31%;
    z-index: 999;

    display: flex;
    flex-direction: column;

    width: 231px;
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid $blue;
    border-radius: 6px;
    background-color: #fff;

    &--text {

      text-align: center;
      white-space: break-spaces;
      margin-bottom: 16px;
    }

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;

      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 9px solid $blue;

      left: 50%;
      transform: translate(-50%);
      top: 100%;
    }
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;

      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid white;
      z-index: 999;

      left: 50%;
      transform: translate(-50%);
      top: 99%;
    }
  }
}
