$block: '.dashboard';

#{$block} {
  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1b3955;
    margin-bottom: 20px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 268px;
    padding-top: 40px;
    position: relative;
    background: #ffffff;
    border: 1px solid #cee0f0;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(14, 100, 148, 0.09);
    border-radius: 9px;
    margin-bottom: 30px;
    &:not(:last-child) {
      margin-right: 30px;
    }
    &:last-child {
      @media (max-width: 870px) and (min-width: 768px) {
        margin-right: 30px;
      }
    }

    &.mobile {
      width: 100%;
      height: 206px;
      padding: 40px 17px;
      &:not(:last-child) {
        margin-right: 0;
      }
    }

    &-wrapper {
      display: flex;
      max-width: 280px;
    }

    &-icon {
      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 54px;
        height: 54px;
        background: #ffffff;
        border: 1px solid #e0e9ed;
        box-sizing: border-box;
        border-radius: 18px;
        margin-bottom: 13px;

        &.mobile {
          margin: auto 0;
          margin-right: 10px;
        }
      }
    }
    &-label {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $blueDeep;
      margin-bottom: 4px;

      &.mobile {
        text-align: initial;
      }
    }
    &-desc {
      max-width: 208px;
      line-height: 22px;
      text-align: center;
      color: $caution;

      &.mobile {
        text-align: initial;
      }
    }
    &-link {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 40px;
      width: 100%;
      max-width: 196px;
      margin: 0 auto;
      & .button {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      &.mobile {
        position: initial;
        width: 100%;
        max-width: 280px;
        margin-top: 15px;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 72%;
    }
  }
}
