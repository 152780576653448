$block: '.api-billing';

#{$block} {
  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
    &--mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 28px;
    }
  }

  &__select-duration {
    display: flex;

    &--mobile {
      display: flex;
      margin: 20px 0;
    }

    &-item:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__plan-cards-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
  }

  &__plans-container {
    padding-bottom: 70px;
    margin-bottom: 50px;
    border-bottom: 1px solid #e4e9f1;
  }

  &__extra-plan {
    display: flex;
    justify-content: space-between;
    border: 1px solid #cee0f0;
    box-sizing: border-box;
    padding: 44px;
    box-shadow: 0px 4px 12px rgba(45, 60, 74, 0.06);
    border-radius: 3px;

    &.mobile {
      flex-direction: column;
    }
  }

  &__extra-plan-text {
    max-width: 500px;
    
    &.mobile {
      margin-bottom: 15px;
    }
  }

  &__extra-plan-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 10px;
  }

  &__extra-plan-button {
    display: flex;
    justify-content: center;
    width: 210px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 70px;
    margin-bottom: 50px;
    border-bottom: 1px solid #e4e9f1;
  }
}
