@mixin svgColorTransition($delay, $duration) {
  & svg path {
    transition-delay: $delay;
    transition-duration: $duration;
    transition-property: fill;
    transition-timing-function: ease;
  }

  & svg rect {
    transition-delay: $delay;
    transition-duration: $duration;
    transition-property: fill;
    transition-timing-function: ease;
  }
}

@mixin svgColorFill($fill) {
  & svg path {
    fill: $fill;
  }

  & svg rect {
    stroke: $fill;
  }
}
