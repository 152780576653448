$block: '.upload';

#{$block} {
  width: 100%;

  &__body {
    display: flex;

    &.mobile {
      display: initial;
    }
  }

  &--disabled {
    cursor: not-allowed !important;
  }

  &__block {
    display: flex;
    min-width: 225px;
    flex-direction: column;

    &.mobile {
      margin-top: 20px;
    }

    &-items {
      width: 240px;
      display: flex;
      flex-wrap: wrap;

      &.mobile {
        justify-content: center;
        width: initial;
      }
    }

    &-item {
      position: relative;

      width: 106px;
      height: 72px;

      > .text-tooltip__container {
        visibility: hidden;
      }

      &:nth-child(odd) {
        margin-right: 12px;
      }

      &:nth-child(-n + 2) {
        margin-bottom: 12px;
      }

      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      border: 1px solid #cee0f0;
      box-sizing: border-box;
      box-shadow: 0px 2px 6px rgba(14, 100, 148, 0.09);
      border-radius: 6px;

      &.mobile {
        width: 64px;
        margin: 0 3px;

        &:nth-child(odd) {
          margin: 0 3px;
        }
  
        &:nth-child(-n + 2) {
          margin: 0 3px;
        }
      }

      &:hover {
        border-color: #00a3fa;

        .text-tooltip__container {
          visibility: visible;
        }
      }

      &:disabled {
        ::after {
          content: '\A';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: rgba(255, 255, 255, 0.4);
          border-radius: 6px;
        }

        &:hover {
          border-color: #cee0f0;
        }
      }
    }

    &--fill {
      flex: 1;
    }

    &--with-external {
      margin-right: 15px;

      &.mobile {
        margin-right: 0;
      }
    }
  }

  &__dropzone {
    background: #f3f9fb;
    border: 1px dashed #b0b3b7;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;

    &--error {
      background-color: #fff3f3;
    }

    &--success {
      background-color: #e3ffea;
    }
  }

  &__content {
    width: 100%;
    height: 156px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-item {
      margin-bottom: 11px;
    }

    &-button {
      width: 100%;
      display: flex;
      justify-content: center;

      button {
        display: flex;
        justify-content: center;
        max-width: 200px;
        width: 70%;
      }
    }
  }

  &__external-uploaders {
    width: 400px;
  }

  &__title {
    line-height: 18px;
    font-weight: 500;

    &-header {
      margin-bottom: 9px;
    }

    &--small {
      font-size: 12px;
    }
  }

  &__text {
    font-weight: 400;

    &--gray {
      color: $grayDark;
    }
  }

  &__status {
    margin-top: 25px;

    &-header {
      display: flex;
      align-items: center;
    }

    &-item {
      margin-right: 8px;
    }

    &-cancel {
      background-color: transparent !important;
      cursor: pointer;
      padding-top: 3px;
    }

    &-file svg {
      width: 20px;
      height: 24px;
    }
  }

  &__error {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    color: $red;
  }

  &__progress-bar {
    margin-top: 18px;
  }

  &__button {
    margin-top: 32px;
  }

  &__error-text {
    color: $red;
  }
}
