$block: '.shareModal';

#{$block} {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 38px 24px;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-weight: bold;
    color: $blueDeep;
    margin-bottom: 14px;
  }

  &__recipientsWrapper {
    margin-bottom: 34px;
  }

  &__copy {
    display: flex;
    position: relative;
    margin-bottom: 32px;
    padding: 0;
    cursor: pointer;
    z-index: 1;

    &:hover {
      #{$block}#{__copyInner} {
        background: #e2f5ff;

        & #{$block}#{__copyText} {
          color: $blueDark;
        }

        & #{$block}#{__copyIcon} {
          & svg path {
            stroke: $blueDark;
          }
        }
      }
    }

    & .form__input {
      width: 100%;
      z-index: -1;
      padding-right: 115px;
      text-overflow: ellipsis;
    }
  }

  &__copyInner {
    position: absolute;
    display: flex;
    align-items: center;
    right: 12px;
    padding: 7px 18px;
    align-self: center;
    background: #ebf8ff;
    border-radius: 30px;
  }

  &__copyIcon {
    margin-right: 5px;

    & div {
      display: flex;

      & svg path {
        stroke: $blue;
      }
    }
  }

  &__copyText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: $blue;
  }
}
