$block: '.successSignModal';

#{$block} {
  min-width: 567px;
  min-height: 305px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__overlay {
    align-items: flex-start !important;
    padding: 50px 0;
    overflow: auto;
  }

  &__subtitle {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: $blueDeep;

    &--margin {
      margin-bottom: 5px;
    }
  }

  &__text {
    width: 427px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: $caution;
    text-align: center;
  }

  &__header {
    &-wrapper {
      width: 485px;
      padding: 5px 0;
      margin-bottom: 33px;
      border-bottom: 1px solid #d7e3e7;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  &__auth {
    width: 445px !important;
    border: none !important;
    box-shadow: none !important;
  }
}
