$block: '.submittedContractsModal';

#{$block} {
  width: 530px;
  padding: 50px 40px;
  height: 520px;
  min-width: 950px;

  &.mobile {
    min-width: initial;
    height: initial;
    padding: 0 17px;
  }

  .modal__header {
    margin-bottom: 40px;
  }

  .modal__title {
    margin-bottom: 5px;
    font-size: 16px;
    text-align: left;
  }

  .modal__subtitle {
    font-size: 14px;
  }

  .signer__list {
    &-item {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      margin-bottom: -1px;

      border-bottom: 1px solid #d7e3e7;
      border-top: 1px solid #d7e3e7;
    }
  }

  &__tableAction {
    &-icon {
      & div {
        & svg path {
          stroke: rgb(0, 163, 250);
        }
      }

      &.disable {
        & div {
          & svg path {
            stroke: $grayDark;
          }
        }
      }
    }
  }
}