$block: ".api-plan";

#{$block} {
  border: 1px solid #cee0f0;
  box-shadow: 0px 2px 6px rgba(14, 100, 148, 0.09);
  border-radius: 3px;
  padding: 42px 16px 30px 16px;
  margin: 0 10px;

  &__wrapper {
    min-width: 275px;
  }

  &__header {
    padding-bottom: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dbe1e5;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #627790;
  }

  &__price {
    &-number {
      font-weight: 450;
      font-size: 40px;
      line-height: 22px;
    }

    &-duration {
      font-size: 25px;
    }
  }

  &__subtitle {
    margin-bottom: 12px;
  }

  &__content-section {
    margin-bottom: 20px;
  }

  &__content-row {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    color: #7d8d98;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__content-icon {
    margin-right: 5px;
    display: flex;

    & > div {
      display: flex;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }

  &__button-wrapper {
     margin-bottom: 20px;
  }

  &__upgrade-button {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__current-plan {
    width:100%;
    &.renew {
      cursor: pointer;
    }
  }

  &__usage-text {
    color: #A7B3BC;
  }

}
