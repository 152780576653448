$block: '.signTemplate';

#{$block} {
  &__block {
    padding-bottom: 40px;
    margin-bottom: 35px;
    border-bottom: 1px solid #e4e9f1;
  }

  &__wrapper {
    width: 100%;
    max-width: 465px;
    margin: 0 auto;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #1b3955;
    margin-bottom: 16px;
  }

  &__upload {
    &-description {
      margin-bottom: 35px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $caution;
    }
    
    &-notice {
      margin-top: 10px;
      display: flex;

      &.symbol {
        color: red;
        margin-right: 3px;
      }

      &.text {
        color: #a5b3bd;
        text-align: justify;
      }
    }
  }

  &__form {
    width: 100%;
    margin-bottom: 40px;

    &-mainGroupField {
      padding-bottom: 20px;
      margin-bottom: 45px;
      border-bottom: 1px solid #e4e9f1;
    }

    &-submitButton {
      & .button {
        width: 185px;
        display: flex;
        justify-content: center;
      }

      & svg {
        width: 6px;
        path {
          stroke: #ffffff;
          fill: none;
        }
      }
    }

    & .form__label span {
      color: $grayDark;
    }

    &--error {
      margin: 20px 0;
      padding: 10px;
      border: 1px solid red;
      border-radius: 5px;
      color: red;
      background: rgba(255, 0, 0, 0.1);
    }
  }

  &__templateField {
    padding-bottom: 40px;
    margin-bottom: 35px;

    &--bulk {
      margin-bottom: unset !important;
    }

    &-select {
      &-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $blueDeep;
        margin-bottom: 10px;
      }

      &-subtitle {
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $caution;
      }
    }

    &-upload {
      &-wrapper {
        margin-bottom: 30px;
      }

      &-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $blueDeep;
        margin-bottom: 27px;
      }

      &-createButton {
        display: flex;
        flex-direction: row;
        & .button {
          width: 210px;
          display: flex;
          justify-content: center;
          margin: 1rem;
        }

        & svg {
          width: 6px;
          path {
            stroke: #ffffff;
            fill: none;
          }
        }

        &.mobile {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  &__emailField {
    margin-bottom: 40px;
    margin-top: 35px;

    &--template {
      padding-bottom: 40px;
      border-bottom: 1px solid #d4dbe7;
    }

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $blueDeep;
      margin-bottom: 28px;
    }

    &-isOrdered {
      margin-top: 18px;

      & .uiCheckbox__label {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $grayDark;
      }
    }

    &--signers {
      padding-bottom: 40px;
      border-bottom: 1px solid #d4dbe7;
    }
  }

  &__validation-modal {
    width: 400px;

    &-title {
      text-align: center;
    }

    &-subTitle {
      margin-bottom: 10px;
    }

    &-confirm {
      display: flex;
      justify-content: center;
      width: 90px;
    }
  }

  &__error-list {
    max-height: 200px;
    overflow-y: auto;
  }

  &__error-item {
    margin-bottom: 5px;
  }
}
