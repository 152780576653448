$block: '.creditCard';

#{$block} {
  display: flex;

  &__initial {
    display: flex;
    justify-content: space-between;
    border-radius: 7px;
    color: $grayDarker;
    
    &.filled {
      padding: 15px;
      background: $greenSemiLight;
    }
  }

  &__data {
    margin-right: 40px;

    &-title {
      margin-bottom: 10px;
      font-weight: bold;
    }

    &-requisite {
      span {
        margin-right: 12px;
      }
    }
  }

  &__item {
    margin: 0 0 20px;
    padding: 10px;
    border: 1px solid $graySemiDark;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(14, 79, 206, 0.13);
    background-color: white;
  }

  &__number {
    width: 180px;
    margin-right: 20px;
  }

  &__cvv {
    width: 29px;
  }

  &__date {
    width: 50px;
    margin-right: 10px;
  }

  &__title {
    margin-bottom: 5px;
  }
}