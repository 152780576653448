$block: '.successSignUpModal';

#{$block} {
  min-width: 923px;
  min-height: 800px;
  padding: 0 !important;

  @media (max-width: $lg-width) {
    min-width: 756px;
    min-height: 0;
  }

  @media (max-width: $sm-width) {
    position: absolute;
    top: 0;
    bottom: 0;
    min-width: 308px;
    min-height: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;

    &.mobile {
      overflow-y: scroll;
    }

    @media (max-width: $sm-width) {
      flex-direction: column;
    }
  }

  &__overlay {
    align-items: flex-start !important;
    padding: 25px 0;
    overflow: auto;
  }

  &__subtitle {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;

    &--margin {
      margin-bottom: 5px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    padding: 5px 30px 5px;

    &-wrapper {
      margin-bottom: 16px;
    }
  }

  &__spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    width: 100%;
    height: 100%;
    padding-top: 15px;
  }

  &__side {
    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #1b3955;
    }
    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 27px;
      color: $caution;
      &-wrapper {
        text-align: center;
        > :not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }
  }

  &__documents {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  &__main-icon {
    & div {
      margin-bottom: 10px;
      display: flex;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    & > {
      :not(:last-child) {
        margin-right: 16px;
      }
    }
    & > button {
      padding: 0 30px;
    }
  }

  &__button--secondary {
    & svg path {
      fill: none;
    }
    & svg rect {
      stroke: none;
    }
    &:hover {
      color: #fff;
      @include svgColorFill(#fff);
      border-color: $blue;
      background-color: $blue;
    }
  }

  &__button--submit {
    background-color: #fff;
    border-color: $blue;
    color: $blue;

    &:hover {
      color: $blue;
      border-color: $blue;
      background-color: #fff;
    }

    &:disabled {
      color: #fff;
      &:hover {
        color: #fff;
        background-color: $grayDark;
        border-color: $grayDark;
      }
    }

    &:active {
      box-shadow: 0 4px 10px rgba(0, 163, 250, 0.28);
    }
  }

  &__left-side {
    display: flex;
    flex-direction: column;

    &-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: $sm-width) {
        min-height: 300px;
      }
    }
  }

  &__right-side {
    position: relative;
    display: flex;
    width: 489px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @media (max-width: $lg-width) {
      width: 400px;
    }

    @media (max-width: $sm-width) {
      width: 100%;
    }

    &-wrapper {
      width: 489px;
      background: $blue;

      @media (max-width: $lg-width) {
        width: 400px;
      }

      @media (max-width: $sm-width) {
        width: 100%;
      }
    }

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      z-index: -2;
      background-color: $blue;
    }

    &::after {
      content: '';
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0;
      background: url('../../../images/bg/signUpModal.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom;
      pointer-events: none;
    }
  }

  &__logo {
    min-height: 30px;
    padding: 50px 0 20px;
  }

  &__auth {
    width: 445px !important;
    border: none !important;
    box-shadow: none !important;

    @media (max-width: $lg-width) {
      width: 400px !important;
    }

    @media (max-width: $sm-width) {
      width: 100% !important;
    }
  }

  &__document {
    display: flex;
  }

  & .modal__close-button {
    & svg path {
      stroke: #fff;

      @media (max-width: $sm-width) {
        stroke: #7e8aa0;
      }
    }
  }

  & .common__or {
    color: #fff;
  }

  & .auth {
    &__acception {
      color: #fff;
    }
    &__link {
      color: #fff;
    }
    &__form {
      padding: 40px;
    }
  }

  &__field {
    & .form {
      &__label {
        color: #fff;
      }
      &__error {
        color: #000;
      }
      &__input--error {
        border-color: #000;
        background-color: #fff3f3;
        color: #000;
        &:focus,
        &:hover,
        &:active {
          border-color: #000;
        }
      }
    }
  }
}
