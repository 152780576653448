$block: '.folders';

#{$block} {
  &__deleteModal{
    &--cancel{
      color: #7D8D98;
      cursor: pointer;
    }
  }

  &__deleteWrapper{
    max-width: 410px;
  }

  &__deleteHeader{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20;
  }

  &__deleteTitle{
    font-size: 16px;
    line-height: 20px;
    color: #1b3955;
    margin-bottom: 2px;
    font-weight: 700;
  }

  &__deleteSubTitle{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $caution;
    margin: 5px 10px 0 10px;
  }
}