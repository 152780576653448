$block: '.DayPickerContainer';

#{$block} {
  position: relative;
  max-width: 651px;
  width: 100%;
  height: 42px;

  &__icon {
    margin-right: 15px;

    & > div {
      display: flex;
    }
  }

  &__wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 40px 8px 16px;
    background: #ffffff;
    border: 1px solid #d7e3e7;
    box-shadow: 0px 2px 4px rgba(14, 79, 206, 0.1);
    border-radius: 3px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #a5b3bd;
    transition: all 0.2s ease;

    &:hover {
      border-color: $graySemiDark;
      background-color: $grayExtraLight;

      #{$block}#{__close-button} {
        svg path {
          stroke: $blue;
        }
      }
    }

    &--disabled {
      pointer-events: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      opacity: 0.6;
    }
  }

  &__input-field {
    width: 0;
    border: none;
  }

  &-Overlay {
    border-radius: 9px;
    background-color: #ffffff;
    margin-top: 10px;
    position: absolute;
    z-index: 1;
    left: unset !important;
    display: flex;
    justify-content: center;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__close-button {
    position: absolute;
    right: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    svg path {
      stroke: #d7e3e7;
    }
    & > div {
      display: flex;
    }

    &--open {
      transform: rotate(180deg);
    }
  }
}
