@import '../../../../../node_modules/react-day-picker/lib/style';

$block: '.DayPicker';

#{$block} {
  font-size: 14px;
  line-height: 18px;

  &__wrapper {
    border: 1px solid #b2c6d9;
    border-radius: 9px;
    box-shadow: 0px 5px 24px rgba(53, 135, 182, 0.22) !important;
    position: relative;

    &.mobile {
      .DayPicker-wrapper {
        width: 100%;
      }
    }
  }

  &__footer {
    display: flex;
    margin: 15px 0;
    justify-content: center;
    align-items: center;
  }

  &__close-button {
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  &__button {
    &--cancel {
      cursor: pointer;
      margin-right: 12px;
      font-size: 14px;
      line-height: 18px;
      color: $grayDark;
    }

    &--select .button {
      width: 110px;
      display: flex;
      justify-content: center;
    }
  }

  &-wrapper {
    width: 400px;
    max-height: 372px;
    padding-top: 33px;
    border-bottom: 1px solid #e4e9f1;
  }

  &-Day {
    width: 50px;
    height: 30px;
    padding: 0;
    border-radius: 0;
    &:hover {
      background-color: transparent !important;
    }
    &--selected {
      background-color: #ffffff !important;
    }
    &--today {
      color: $blue;
      font-weight: normal;
    }
    &--sunday {
      color: inherit !important;
      background-color: #ffffff;
    }
    &--disabled {
      color: #dce0e0 !important;
      background-color: #ffffff;
    }
  }

  &__day-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    &--in-range {
      background-color: #dff4ff;
    }
    &--left-side {
      width: 40px;
      margin-left: 10px;
      justify-content: flex-start;
      background-color: #dff4ff;
    }
    &--right-side {
      justify-content: flex-end;
      width: 40px;
      background-color: #dff4ff;
    }
    &--both-sides {
      justify-content: flex-end;
      width: 40px;
      background-color: #dff4ff;
    }
    &--top-right {
      border-top-right-radius: 45%;
    }
    &--bottom-right {
      border-bottom-right-radius: 45%;
    }
    &--top-left {
      border-top-left-radius: 45%;
    }
    &--bottom-left {
      border-bottom-left-radius: 45%;
    }
  }

  &__custom-day {
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: 20px;
    min-height: 23px;
    align-items: center;
    &--in-range {
      background-color: #dff4ff;
      border-radius: 50%;
    }
    &--highlighted {
      color: #ffffff;
      background-color: $blue;
      border-radius: 50%;
      font-weight: 500;
    }
    &--is-box {
      width: 30px;
      height: 30px;
    }
    &--disabled {
      cursor: inherit;
    }
  }
  &-NavBar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__nav-title {
    text-align: center;
    width: 195px;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;

    &.mobile {
      width: 160px;
    }
  }
  &__nav {
    cursor: pointer;
    &--left {
      transform: scale(-1, 1);
    }
  }
  &-Caption {
    display: none;
  }
}
